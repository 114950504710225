import React, { useContext, useEffect, useState } from 'react'
// import './Badge.css'
import Sidebar from '../../../Includes/Sidebar'
import Footer from '../../../Includes/Footer'
import { Link } from 'react-router-dom'
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { StudentContext } from '../../../context/StudentContext'
import { customStyles } from '../../Data/Utility'
import Loader from '../../../Includes/loader/loader'
import DataTable from 'react-data-table-component'
import { AdminContext } from '../../../context/AdminContext'
// import axios from 'axios';

// const baseUrl = 'https://lnfcl.pythonanywhere.com/api/students/';
// const baseUrl = 'http://127.0.0.1:8000/api/students/';

const Badge = () => {
  const { getClasses, getStudents } = useContext(StudentContext);
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [loading, setLoading] = useState(true);
  const [originalRecords, setOriginalRecords] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [records1, setRecords1] = useState([]);
  const { getAnneeAc } = useContext(AdminContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getStudents();
        setOriginalRecords(data);
        const data1 = await getAnneeAc();
        setRecords1(data1);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getStudents, getAnneeAc]);

  const record = records1.length > 0 ? records1[0] : null;
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const classesData = await getClasses();
        if (Array.isArray(classesData)) {
          setClasses(classesData);
        } else {
          console.error('Classes data is not an array');
        }
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };
    fetchClasses();
  }, [getClasses]);

  useEffect(() => {
    if (selectedLevel) {
      const filtered = classes.filter((classe) => classe.niveau === selectedLevel);
      setFilteredClasses(filtered);
    } else {
      setFilteredClasses([]);
    }
  }, [selectedLevel, classes]);

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
    setSelectedClass('');
    setStudentData([]);
  };

  const handleClassChange = async (event) => {
    const newClass = event.target.value;
    setSelectedClass(newClass);

    if (newClass) {
      setLoading(true);
      try {
        const filteredStudents = originalRecords.filter(
          (student) => student.niveau === selectedLevel && student.classe_nom === newClass
        );
        setStudentData(filteredStudents);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching student bulletins:', error);
      }
    }
  };

  // const generateStudentBadge = async (studentData, date) => {
  //   const doc = new jsPDF('l', 'pt', [242, 153]);
  
  //   const img = new Image();
  //   img.crossOrigin = "anonymous";
  //   img.src = './img/badge_background.png';
  //   await new Promise((resolve) => {
  //     img.onload = resolve;
  //   });
  
  //   const canvas = document.createElement('canvas');
  //   canvas.width = 2420;
  //   canvas.height = 1530;
  //   const context = canvas.getContext('2d');
  //   context.drawImage(img, 0, 0, canvas.width, canvas.height);
  //   const imgData = canvas.toDataURL('image/png');
  
  //   doc.addImage(imgData, 'PNG', 0, 0, 242, 153);
  
  //   const photoCanvas = document.createElement('canvas');
  //   const photoSize = 60;
  //   photoCanvas.width = photoSize;
  //   photoCanvas.height = photoSize;
  //   const photoContext = photoCanvas.getContext('2d');
  
  //   const photo = new Image();
  //   photo.crossOrigin = "anonymous";
  //   photo.src = studentData.photo;
  //   await new Promise((resolve) => {
  //     photo.onload = resolve;
  //   });
  
  //   // Dessiner l'image sur le canvas photo
  //   photoContext.drawImage(photo, 0, 0, photoSize, photoSize);
  //   const photoData = photoCanvas.toDataURL('image/png');
    
  //   // Ajout de la photo au PDF
  //   const photoX = 30;
  //   const photoY = (153 - photoSize) / 2;
  //   doc.addImage(photoData, 'PNG', photoX, photoY, photoSize, photoSize);
  
  //   doc.setFontSize(9);
  //   doc.setFont('times', 'bold');
  //   doc.text(`Matricule : ${studentData.matricule}`, 100, 65);
  //   doc.text(`Nom : ${studentData.nom}`, 100, 75);
  //   doc.text(`Prenom : ${studentData.prenom}`, 100, 85);
  //   doc.text(`Classe : ${studentData.niveau} ${studentData.classe_nom}`, 100, 95);
  
  //   doc.setFontSize(10);
  //   doc.setFont('times', 'bold');
  //   doc.text(`${date}`, photoX, photoY + photoSize + 25);
  
  //   const signature = new Image();
  //   signature.crossOrigin = "anonymous";
  //   signature.src = './img/signature.png';
  //   await new Promise((resolve) => {
  //     signature.onload = resolve;
  //   });
  //   const signatureWidth = 50;
  //   const signatureHeight = 20;
  //   const signatureX = 242 - signatureWidth - 10;
  //   const signatureY = 115;
  //   doc.addImage(signature, 'PNG', signatureX, signatureY, signatureWidth, signatureHeight);
  
  //   const pdfBlob = doc.output('blob');
  //   const formData = new FormData();
  //   formData.append('pdf', pdfBlob, `${studentData.nom}_${studentData.prenom}_badge.pdf`);
  //   formData.append('classe_nom', studentData.classe_nom);
  
  //   try {
  //     const response = await axios.post(`${baseUrl}save-pdf/`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     });
  //     if (response.status === 200) {
  //       // Ouvrir le PDF dans une nouvelle fenêtre sans le télécharger à nouveau
  //       const url = URL.createObjectURL(pdfBlob);
  //       window.open(url, '_blank');
        
  //       // Cleanup
  //       setTimeout(() => {
  //         URL.revokeObjectURL(url);
  //       }, 100);
  //     }
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error('Erreur lors de l\'envoi du PDF:', error);
  //   }
  // };
  
  // const generateStudentBadge = async (studentData, date) => {
  //   const doc = new jsPDF('l', 'pt', [242, 153]);
  
  //   const img = new Image();
  //   img.crossOrigin = "anonymous";
  //   img.src = './img/badge_background.png';
  //   await new Promise((resolve) => {
  //     img.onload = resolve;
  //   });
  
  //   const canvas = document.createElement('canvas');
  //   canvas.width = 2420;
  //   canvas.height = 1530;
  //   const context = canvas.getContext('2d');
  //   context.drawImage(img, 0, 0, canvas.width, canvas.height);
  //   const imgData = canvas.toDataURL('image/png');
  
  //   doc.addImage(imgData, 'PNG', 0, 0, 242, 153);
  
  //   const photoCanvas = document.createElement('canvas');
  //   const photoSize = 60;
  //   photoCanvas.width = photoSize;
  //   photoCanvas.height = photoSize;
  //   const photoContext = photoCanvas.getContext('2d');
  
  //   const photo = new Image();
  //   photo.crossOrigin = "anonymous";
  //   photo.src = studentData.photo;
  //   await new Promise((resolve) => {
  //     photo.onload = resolve;
  //   });
  
  //   photoContext.drawImage(photo, 0, 0, photoSize, photoSize);
  //   const photoData = photoCanvas.toDataURL('image/png');
  
  //   const photoX = 30;
  //   const photoY = (153 - photoSize) / 2;
  //   doc.addImage(photoData, 'PNG', photoX, photoY, photoSize, photoSize);
  
  //   doc.setFontSize(9);
  //   doc.setFont('times', 'bold');
  //   doc.text(`Matricule : ${studentData.matricule}`, 100, 65);
  //   doc.text(`Nom : ${studentData.nom}`, 100, 75);
  //   doc.text(`Prenom : ${studentData.prenom}`, 100, 85);
  //   doc.text(`Classe : ${studentData.niveau} ${studentData.classe_nom}`, 100, 95);
  
  //   doc.setFontSize(10);
  //   doc.setFont('times', 'bold');
  //   doc.text(`${date}`, photoX, photoY + photoSize + 25);
  
  //   const signature = new Image();
  //   signature.crossOrigin = "anonymous";
  //   signature.src = './img/signature.png';
  //   await new Promise((resolve) => {
  //     signature.onload = resolve;
  //   });
  //   const signatureWidth = 50;
  //   const signatureHeight = 20;
  //   const signatureX = 242 - signatureWidth - 10;
  //   const signatureY = 115;
  //   doc.addImage(signature, 'PNG', signatureX, signatureY, signatureWidth, signatureHeight);
  
  //   // Générer le PDF en tant que Blob
  //   const pdfBlob = doc.output('blob');
  
  //   // Créer un lien de téléchargement invisible
  //   const link = document.createElement('a');
  //   link.href = URL.createObjectURL(pdfBlob);
  //   link.download = `${studentData.nom}_${studentData.prenom}_badge.pdf`;
  
  //   // Simuler un clic sur le lien pour déclencher le téléchargement
  //   document.body.appendChild(link);
  //   link.click();
    
  //   // Supprimer le lien après le téléchargement
  //   document.body.removeChild(link);
    
  //   // Cleanup l'URL du PDF
  //   setTimeout(() => {
  //     URL.revokeObjectURL(link.href);
  //   }, 100);
  // };

  const generateStudentBadge = async (studentData, date) => {
    const doc = new jsPDF({
      orientation: 'l',
      unit: 'pt',
      format: [242, 153],
      compressPdf: false  // Désactiver la compression pour une meilleure qualité
    });
  
  
    // Charger l'image de fond du badge
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = './img/badge_background.png';  // Assurez-vous que cette image a une haute résolution
    await new Promise((resolve) => {
      img.onload = resolve;
    });
  
    const canvas = document.createElement('canvas');
    canvas.width = 2420;  // 10x plus grand pour une meilleure résolution
    canvas.height = 1530;
    const context = canvas.getContext('2d');
    context.drawImage(img, 0, 0, canvas.width, canvas.height);
    const imgData = canvas.toDataURL('image/png');  // Garder le format PNG pour l'image de fond
  
    doc.addImage(imgData, 'PNG', 0, 0, 242, 153);
  
    // Charger la photo de l'étudiant
    const photoCanvas = document.createElement('canvas');
    const photoSize = 300; // Augmenter la taille pour une meilleure résolution interne
    photoCanvas.width = photoSize;
    photoCanvas.height = photoSize;
    const photoContext = photoCanvas.getContext('2d');
  
    const photo = new Image();
    photo.crossOrigin = "anonymous";
    photo.src = studentData.photo;  // Assurez-vous que l'image source est de haute résolution
    await new Promise((resolve) => {
      photo.onload = resolve;
    });
  
    // Dessiner l'image sur le canvas photo
    photoContext.drawImage(photo, 0, 0, photoSize, photoSize);
    const photoData = photoCanvas.toDataURL('image/png');  // Exporter en PNG pour préserver la transparence
  
    // Ajout de la photo au PDF
    const photoX = 20;
    const photoY = (143 - 60) / 2;  // Positionner l'image verticalement au centre
    doc.addImage(photoData, 'PNG', photoX, photoY, 70, 70);  // Redimensionner dans le PDF à 60x60
  
    // Ajouter les informations de l'étudiant
    doc.setFontSize(9);
    doc.setFont('times', 'bold');
    doc.text(`Matricule : ${studentData.matricule}`, 95, 62);
    doc.text(`Nom : ${studentData.nom}`, 95, 77);
    doc.text(`Prenom : ${studentData.prenom}`, 95, 92);
    doc.text(`Classe : ${studentData.niveau} ${studentData.classe_nom}`, 95, 107);
  
    // Ajouter la date
    doc.setFontSize(10);
    doc.setFont('times', 'bold');
    doc.text(`${date}`, photoX, photoY + 60 + 30);
  
    // Ajouter la signature
    const signature = new Image();
    signature.crossOrigin = "anonymous";
    signature.src = './img/signature.png';  // Garder la signature en PNG si nécessaire
    await new Promise((resolve) => {
      signature.onload = resolve;
    });
    const signatureWidth = 65;
    const signatureHeight = 30;
    const signatureX = 238 - signatureWidth - 10;
    const signatureY = 111;
    doc.addImage(signature, 'PNG', signatureX, signatureY, signatureWidth, signatureHeight);
  
    // Générer et télécharger le fichier PDF
    doc.save(`${studentData.nom}_${studentData.prenom}_badge.pdf`);
  };
  
const exportAllBadges = async () => {
  for (const student of studentData) {
    await generateStudentBadge(student, record.date);
  }
};

const handlePrint = async (row) => {
  await generateStudentBadge(row, record.date);
};
  const columnsStudent = [
    {
      name: "Nom Complet",
      selector: (row) => (
        <div className="d-flex align-items-center">
          <img
            src={row.photo}
            alt="profile"
            style={{ width: 30, height: 30, borderRadius: "50%", marginRight: 5 }}
          />
          {row.nom + " " + row.prenom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Matricule",
      selector: (row) => row.matricule,
      sortable: true,
    },
    {
      name: "Classe",
      selector: (row) => (
        <div className="d-flex align-items-start">
          {row.niveau + " " + row.classe_nom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group" role="group">
          <Link to="/student-profile/"  state={{student_id: row.id }} className="btn btn-warning btn-sm me-1">
            <i className="fas fa-edit"></i>
          </Link>
          <button onClick={() => handlePrint(row)} className="btn btn-primary btn-sm me-1">
            <i className="fas fa-print"></i>
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
        <div className="wrapper">
          <Sidebar />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2 mt-3">
                  <div className="col-sm-6 d-flex align-items-center">
                    <div className="bg-blue rounded-lg p-2 text-center">
                      <i className='fas fa-users-cog'></i>
                    </div>
                    <div className="ml-2"><h3 className='display fw-12'>Gestion des Badges</h3></div>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item"><Link to="/dashboard">Tableau de bord</Link></li>
                      <li className="breadcrumb-item active"><Link to="/dashboard">Élèves</Link></li>
                      <li className="breadcrumb-item active">Badges</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="clearfix hidden-md-up"></div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-6 ">
                            <div className="input-group">
                              <select className="form-control" value={selectedLevel} onChange={handleLevelChange}>
                                <option value="">Niveau</option>
                                {Array.isArray(classes) &&
                                  [...new Set(classes.map((classe) => classe.niveau))].map((niveau) => (
                                    <option key={niveau} value={niveau}>
                                      {niveau}
                                    </option>
                                  ))}
                              </select>
                              <select className="form-control" value={selectedClass} onChange={handleClassChange}>
                                <option value="">Classe</option>
                                {filteredClasses.map((classe) => (
                                  <option key={classe.id} value={classe.classe}>
                                    {classe.classe}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-1 ">
                            <button className="btn btn-success" onClick={exportAllBadges}>
                              <i className="fas fa-print"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            {loading ? (
                              <Loader />
                            ) : (
                              <>
                                {studentData.length > 0 ? (
                                  <div className="row">
                                    <DataTable
                                      columns={columnsStudent}
                                      data={studentData}
                                      pagination
                                      fixedHeader
                                      fixedHeaderScrollHeight="500px"
                                      highlightOnHover
                                      customStyles={customStyles}
                                      responsive
                                    />
                                  </div>
                                ) : (
                                  <p>Aucun étudiant trouvé pour cette classe.</p>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card-footer text-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default Badge;
