import React, { createContext, useContext } from 'react';
import AuthContext from './AuthContext';
// import { useHistory } from 'react-router-dom';
import { baseUrlAdmin } from './BaseUrl';
const swal = require('sweetalert2')
const baseUrl = baseUrlAdmin;

const AdminContext = createContext();

const AdminProvider = ({ children }) => {
  //   const [isLoading, setIsLoading] = useState(false);
  const { authTokens, setUser, setAuthTokens, user, fetchWithAuth } = useContext(AuthContext);
  //   const [loading, setLoading] = useState(true);

  const createAnneeAcademique = async (formData) => {
    const response = await fetchWithAuth(`${baseUrl}annee-academique/`, {
      method: "POST",
      body: formData
    });

    if (response.status === 200) {
      window.location.reload();
      swal.fire({
        title: "Annee Academique Created Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      // console.log(response.status);
      // console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const getAnneeAc = async () => {
    const response = await fetchWithAuth(`${baseUrl}annee-academique/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };
  const getAnneeAcStudent = async () => {
    const response = await fetch(`${baseUrl}annee-academique-student/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };
  const getAnneeAcList = async () => {
    const response = await fetchWithAuth(`${baseUrl}list-academiques/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  const createShoolPrice = async (formData) => {
    const response = await fetchWithAuth(`${baseUrl}school-price/`, {
      method: "POST",
      body: formData
    });

    if (response.status === 201) {
      window.location.reload();
      swal.fire({
        title: "Inscription Created Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      // console.log(response.status);
      // console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const getShoolPrice = async () => {
    const response = await fetchWithAuth(`${baseUrl}school-price/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  // Nouvelle méthode pour obtenir le nombre d'étudiants par classe
  const getStudentCountByClass = async () => {
    const response = await fetchWithAuth(`${baseUrl}student-count-by-class/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  // Nouvelle méthode pour obtenir le nombre de cours
  const getCourseCount = async () => {
    const response = await fetchWithAuth(`${baseUrl}course-count/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    return data;
  };

  // Nouvelle méthode pour obtenir le nombre de professeurs
  const getTeacherCount = async () => {
    const response = await fetchWithAuth(`${baseUrl}teacher-count/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };
  const getStudentCount = async () => {
    const response = await fetchWithAuth(`${baseUrl}student-count/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };
  const getVerifiedStudentCount = async () => {
    const response = await fetchWithAuth(`${baseUrl}verified-student-count/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };
  const getVerifiedInscriptionCount = async () => {
    const response = await fetchWithAuth(`${baseUrl}verified-inscriptions-count/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };
  const getUnVerifiedStudentCount = async () => {
    const response = await fetchWithAuth(`${baseUrl}unverified-student-count/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  const getInscriptionCount = async () => {
    const response = await fetchWithAuth(`${baseUrl}inscription-count/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  // Nouvelle méthode pour obtenir les 5 derniers étudiants enregistrés
  const getLastFiveStudents = async () => {
    const response = await fetchWithAuth(`${baseUrl}latest-students/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };
  const getTotalSum = async () => {
    const response = await fetchWithAuth(`${baseUrl}total-sum/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  const getStudentCountByLevel = async () => {
    const response = await fetchWithAuth(`${baseUrl}student-count-by-level/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };



  const contextData = {
    authTokens, setAuthTokens,
    user, setUser,
    createAnneeAcademique,
    getAnneeAc,
    getAnneeAcList,
    createShoolPrice,
    getShoolPrice,
    getStudentCountByClass,
    getCourseCount,
    getTeacherCount,
    getLastFiveStudents,
    getStudentCount,
    getTotalSum,
    getStudentCountByLevel,
    getInscriptionCount,
    getVerifiedInscriptionCount,
    getUnVerifiedStudentCount,
    getVerifiedStudentCount,
    getAnneeAcStudent,


  };

  return (
    <AdminContext.Provider value={contextData}>
      {children}
    </AdminContext.Provider>
  );
};

export { AdminContext, AdminProvider };
