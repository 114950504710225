import { useContext, useState } from "react";
import { TeacherContext } from "../../context/TeacherContext";
import AuthContext from "../../context/AuthContext";

function FormTeacher() {
  const { createTeacher } = useContext(TeacherContext);
  const [profileImage, setProfileImage] = useState(null);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false); // État de chargement


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const nom = e.target.nom.value;
    const prenom = e.target.prenom.value;
    const email = e.target.email.value;
    const sexe = e.target.sexe.value;
    const date_naissance = e.target.date_naissance.value;
    const adresse = e.target.adresse.value;
    const telephone = e.target.telephone.value;
    const nif_cin = e.target.nif_cin.value;

    let formData = new FormData();
    formData.append('nom', nom);
    formData.append('prenom', prenom);
    formData.append('email', email);
    formData.append('sexe', sexe);
    formData.append('date_naissance', date_naissance);
    formData.append('adresse', adresse);
    formData.append('telephone', telephone);
    formData.append('nif_cin', nif_cin);
    formData.append('created_by', user.user_id);

    const imageFile = e.target.photo.files[0];
    if (imageFile) {
      formData.append('photo', imageFile);
    }
    setLoading(true);
    try {
      email.length > 0 && email.includes('@') && email.includes('.') &&
        createTeacher(formData);

    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Remettre loading à false après la fin du processus
    }
  };
  return (
    <form onSubmit={handleSubmit} className="forms-sample" encType="multipart/form-data">
      <div className="row">
        {profileImage && (
          <div className="col-md-3">
            <label htmlFor="profil">Photo</label>
            <div className="form-group">
              {/* eslint-disable-next-line */}
              <img src={profileImage} id="profil" alt="Photo de profil" style={{ maxWidth: '80px', maxHeight: '80px', borderRadius: '50px' }} />
            </div>
          </div>
        )}
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="name">Nom</label>
            <input type="text" className="form-control" id="name"
              placeholder="Nom du Professeur" required name="nom" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="prenom">Prénom</label>
            <input type="text" className="form-control" id="prenom"
              placeholder="Prenom du Professeur" required name="prenom" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="exampleInputEmail3">Email</label>
            <input type="email" className="form-control" id="exampleInputEmail3"
              placeholder="Email du Professeur" required name="email" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="Téléphone">NIF | CIN</label>
            <input type="text" className="form-control" id="Téléphone"
              placeholder="Téléphone" required name="nif_cin" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="exampleSelectGender">Sexe</label>
            <select className="form-control" id="exampleSelectGender" required name="sexe">
              <option selected readonly></option>
              <option value={'M'}>Masculin</option>
              <option value={'F'}>Feminin</option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="date">Date naissance</label>
            <input type="date" className="form-control" id="date"
              placeholder="Date Naissance" required name="date_naissance" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="Adresse">Adresse</label>
            <input type="test" className="form-control" id="Adresse"
              placeholder="Adresse" required name="adresse" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="Téléphone">Téléphone</label>
            <input type="number" className="form-control" id="Téléphone"
              placeholder="Téléphone" required name="telephone" />
          </div>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="exampleInputFile">Fichier importer</label>
          <div className="input-group">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="exampleInputFile"
                accept="image/*"
                name="photo"
                onChange={handleImageChange}
              />
              <label className="custom-file-label" htmlFor="exampleInputFile">
                {profileImage ? 'Image sélectionnée' : 'Sélectionner une image'}
              </label>
            </div>
            <div className="input-group-append">
              <span className="input-group-text">Importer</span>
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-4 text-right">
          <div className="form-group pt-1">
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              disabled={loading}
            >
              {loading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> Enregistrement
                  en cours...
                </>
              ) : (
                <>
                  <i className="fas fa-save"></i> Enregistrer
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
export default FormTeacher