/* eslint-disable */
import React, { useState } from "react";
import { Link } from 'react-scroll';
import { Link as RouterLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

function NavbarHome() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Lycée National François Cappoix de Limonade</title>
        <meta
          name="description"
          content="Bienvenue sur le site officiel du Lycée National François Cappoix de Limonade. Découvrez nos programmes éducatifs et notre engagement envers l'excellence."
        />
        <meta
          name="keywords"
          content="LNFCL, François Cappoix, Lycée, Limonade, éducation, excellence, écoles Haïti"
        />
        <meta property="og:title" content="Lycée National François Cappoix de Limonade" />
        <meta
          property="og:description"
          content="Bienvenue sur le site officiel du Lycée National François Cappoix de Limonade. Explorez nos programmes et initiatives."
        />
        <meta property="og:image" content="https://lnfcl.com/img/log.png" />
        <meta property="og:url" content="https://lnfcl.com" />
        <meta name="twitter:card" content="/img/carousel-4.png" />
        <meta name="twitter:title" content="Lycée National François Cappoix de Limonade" />
        <meta
          name="twitter:description"
          content="Découvrez le Lycée National François Cappoix de Limonade, un modèle d'éducation et de développement à Limonade."
        />
        <meta name="twitter:image" content="https://lnfcl.com/img/log.png" />
      </Helmet>

      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5 py-lg-0" aria-label="Main navigation">
        <a href="/" className="navbar-brand">
          {/* Utilisation de <h1> pour le titre principal du site */}
          <h1 className="m-1 text-primary text-center" style={{ fontFamily: "Sansita" }}>
            <img className="img1" src="./img/log.png" alt="Logo du Lycée National François Cappoix de Limonade" />
            <span className="pt-2">LNFCL</span>
          </h1>
        </a>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
              onClick={toggleMenu}
              aria-expanded={isMenuOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''
                }`}id="navbarCollapse">
          <div className="navbar-nav mx-auto">
          <Link
                  to="home"
                  className="nav-item nav-link"
                  smooth={true}
                  duration={500}
                  onClick={closeMenu}
                >
                  Accueil
                </Link>
                <Link
                  to="about"
                  className="nav-item nav-link"
                  smooth={true}
                  duration={500}
                  onClick={closeMenu}
                >
                  A Propos
                </Link>
                <Link
                  to="services"
                  className="nav-item nav-link"
                  smooth={true}
                  duration={500}
                  onClick={closeMenu}
                >
                  Services
                </Link>
                <Link
                  to="gallery"
                  className="nav-item nav-link"
                  smooth={true}
                  duration={500}
                  onClick={closeMenu}
                >
                  Gallerie
                </Link>
                <Link
                  to="programmes"
                  className="nav-item nav-link"
                  smooth={true}
                  duration={500}
                  onClick={closeMenu}
                >
                  Programmes
                </Link>
                <Link
                  to="contact"
                  className="nav-item nav-link"
                  smooth={true}
                  duration={500}
                  onClick={closeMenu}
                >
                  Contact
                </Link>
            {/* <a href="/" className="nav-item nav-link active" aria-current="page">Accueil</a> */}
            {/* <a href="#about" className="nav-item nav-link">À Propos</a>
            <a href="#serv" className="nav-item nav-link">Nos Services</a>
            <a href="#gall" className="nav-item nav-link">Galerie</a>
            <a href="#contact" className="nav-item nav-link">Contact</a> */}
          </div>
          <div className="btn-group" role="group">
            <RouterLink to="/login" className="btn btn-primary btn-sm fw-bold text-white" aria-label="Accéder à l'espace de connexion">
              Connecter <i className="fas fa-sign-in-alt ms-1"></i>
            </RouterLink>
            <RouterLink to="/result" className="btn btn-info btn-sm fw-bold text-white" aria-label="Accéder aux résultats">
              Résultats <i className="fa fa-file-invoice ms-1"></i>
            </RouterLink>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavbarHome;
