/* eslint-disable */
import { useContext, useState } from "react";
import AuthContext from "../../context/AuthContext";
import { Link } from "react-router-dom";
import Loader from "../../Includes/loader/loader";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const { forgotPassword } = useContext(AuthContext);
  const [loading, setLoading] = useState(false); // État de chargement

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.length > 0) {
      setLoading(true); // Commence le chargement
      await forgotPassword(email);
      setLoading(false); // Termine le chargement
      setEmail("");
    }
  };
  return (
    <>
      <div className="wrapper">
        <div className="container-fluid h-100">
          <div className="row flex-row h-100">
            
            <div className="col-xl-8 p-0 d-none d-lg-block">
              <div
                className="lavalite-bg"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 100, 0.5), rgba(0, 0, 100, 0.5)), url(./img/auth/login-bg.png)`,
                  height: "100vh",
                  backgroundSize: "cover",
                }}
              >
                <div className="lavalite-overlay"></div>
              </div>
            </div>
            <div className="col-xl-4 my-auto bg-white">
              <div className="card">
                <Link
                  to="/"
                  className="close bg-white mt-0 mb-3"
                  style={{ display: "flex" }}
                >
                  <i className="fa fa-home text-primary"></i>
                </Link>
              </div>
              <div
                className="container-fluid mx-auto p-3 my-auto"
                style={{ paddingTop: "20px" }}
              >
                <div className="d-flex justify-content-center align-items-center mb-4">
                  <a href="/">
                    <img src="/img/log.png" alt="logo" />
                  </a>
                </div>
                <h6 className="text-center">
                  Lycée National François Cappoix de Limonade
                </h6>
                <div
                  className="card justify-content-center align-items-center p-3 elevation-1 mb-0"
                  style={{
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "0px",
                    borderBottomLeftRadius: "0px",
                  }}
                >
                  <h4 className="display text-blue">Mot de Passe oublié</h4>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form-group position-relative">
                    <input
                      type="email"
                      className="form-control pl-5"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <i
                      className="fa fa-user position-absolute"
                      style={{
                        top: "50%",
                        transform: "translateY(-50%)",
                        left: "10px",
                      }}
                    ></i>
                  </div>
                  <div className="row">
                    <div className="row  mb-3 text-end p-0">
                      <Link to="/login">Connecter</Link>

                      {/* <div className="col-md-6 text-end p-0">
                                        <Link to="/register" >Pas de Compte?</Link>
                                    </div> */}
                    </div>
                  </div>
                  <div className="sign-btn text-center">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i> Envoie en
                          cours...
                        </>
                      ) : (
                        <>
                          <i className="fas fa-save"></i> Envoyer
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
