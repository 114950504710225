import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom'; // Utilisez useNavigate pour react-router-dom v6
// import dayjs from 'dayjs';
import { createContext, useState, useEffect, useCallback } from 'react';
import { baseUrlAuth } from './BaseUrl';
const swal = require('sweetalert2');

const AuthContext = createContext();
const baseUrl = baseUrlAuth;

export default AuthContext;

export const AuthProvider = ({ children }) => {
    const [authTokens, setAuthTokens] = useState(() => {
        const tokens = localStorage.getItem("authTokens");
        return tokens ? JSON.parse(tokens) : null;
    });
    const [user, setUser] = useState(() => {
        const tokens = localStorage.getItem("authTokens");
        if (tokens) {
            const parsedTokens = JSON.parse(tokens);
            // Check if access token is valid
            if (parsedTokens.access && typeof parsedTokens.access === 'string') {
                return jwtDecode(parsedTokens.access);
            }
        }
        return null;
    });
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const loginUser = async (email, password) => {
        try {
            const response = await fetch(`${baseUrl}token/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email, password })
            });
            const data = await response.json();

            if (response.status === 200) {
                const token = data.access;
                if (typeof token === 'string') {
                    const decodedToken = jwtDecode(token);
                    setAuthTokens(data);
                    setUser(decodedToken);
                    localStorage.setItem("authTokens", JSON.stringify(data));
                    swal.fire({
                        title: "Login Successful",
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        position: 'top-right',
                        timerProgressBar: true,
                        showConfirmButton: false,
                    });
                    navigate("/dashboard");
                } else {
                    throw new Error('Token is not a valid string');
                }
            } else {
                // console.log("Error:", response.status);
                swal.fire({
                    title: "Error",
                    text: response.detail || 'Email or password incorrect',
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            console.error("Login error:", error);
            swal.fire({
                title: "An error occurred",
                text: error.message,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };

    // const fetchWithAuth = async (url, options = {}) => {
    //     try {
    //         const response = await fetch(url, {
    //             ...options,
    //             headers: {
    //                 ...options.headers,
    //                 "Authorization": `Bearer ${authTokens?.access}`
    //             }
    //         });

    //         if (response.status === 401) {
    //             // If the token is expired or invalid, refresh it or log out the user
    //             await refreshToken();
    //             // Retry the request after refreshing the token
    //             const retryResponse = await fetch(url, {
    //                 ...options,
    //                 headers: {
    //                     ...options.headers,
    //                     "Authorization": `Bearer ${authTokens?.access}`
    //                 }
    //             });
    //             return retryResponse;
    //         }
    // // console.log(response);

    //         return response;
    //     } catch (error) {
    //         console.error("Fetch with auth error:", error);
    //         throw error;
    //     }
    // };

    const fetchWithAuth = async (url, options = {}) => {
        try {
            const headers = {
                ...options.headers,
                "Authorization": `Bearer ${authTokens?.access}`,
            };

            // Supprimer l'en-tête 'Content-Type' si body est un FormData
            if (!(options.body instanceof FormData)) {
                headers["Content-Type"] = headers["Content-Type"] || "application/json";
            }

            const response = await fetch(url, {
                ...options,
                headers,
            });

            if (response.status === 401) {
                // Token expiré ou invalide, rafraîchir ou déconnecter l'utilisateur
                await refreshToken();
                // Réessayer la requête après le rafraîchissement du token
                const retryResponse = await fetch(url, {
                    ...options,
                    headers: {
                        ...headers,
                        "Authorization": `Bearer ${authTokens?.access}`,
                    },
                });
                return retryResponse;
            }

            return response;
        } catch (error) {
            console.error("Fetch with auth error:", error);
            throw error;
        }
    };


    const registerUser = async (full_name, email, username, password, password2) => {
        try {
            const response = await fetch(`${baseUrl}register/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ full_name, email, username, password, password2 })
            });
            if (response.status === 201) {
                navigate("/login");
                swal.fire({
                    title: "Registration Successful, Login Now",
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            } else {
                // console.log("Error:", response.status);
                swal.fire({
                    title: `An Error Occurred ${response.status}`,
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            console.error("Registration error:", error);
            swal.fire({
                title: "An error occurred",
                text: error.message,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };

    const logoutUser = useCallback(() => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem("authTokens");
        navigate("/");
        swal.fire({
            title: "You have been logged out...",
            icon: "success",
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
        });
    }, [navigate]);

    const createUser = async (formData) => {
        try {
            const response = await fetchWithAuth(`${baseUrl}user/create/`, {
                method: "POST",
                body: formData
            });

            if (response.status === 201) {
                window.location.reload();
                swal.fire({
                    title: "User Created Successfully",
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            } else {
                // console.log("Error:", response.status);
                swal.fire({
                    title: `An Error Occurred ${response.status}`,
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            console.error("Create user error:", error);
            swal.fire({
                title: "An error occurred",
                text: error.message,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };


    const updateUser = async (userId, formData) => {
        try {
            const response = await fetchWithAuth(`${baseUrl}user/${userId}/`, {
                method: "PUT",
                body: formData
            });

            if (response.status === 200) {
                swal.fire({
                    title: "Profile Updated Successfully",
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
                navigate("/dashboard");
            } else {
                // console.log("Error:", response.status);
                swal.fire({
                    title: `An Error Occurred ${response.status}`,
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            console.error("Update user error:", error);
            swal.fire({
                title: "An error occurred",
                text: error.message,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };

    const deleteUser = async (userId) => {
        try {
            const response = await fetchWithAuth(`${baseUrl}user/${userId}/`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 204) {
                swal.fire({
                    title: "User Deleted Successfully",
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            } else {
                // console.log("Error:", response.status);
                swal.fire({
                    title: `An Error Occurred ${response.status}`,
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            console.error("Delete user error:", error);
            swal.fire({
                title: "An error occurred",
                text: error.message,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };

    const getUserById = async (userId) => {
        try {
            const response = await fetchWithAuth(`${baseUrl}user/profile/${userId}/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            // console.log(data);
            return data;
        } catch (error) {
            console.error("Get user by ID error:", error);
            throw error;
        }
    };
    const getUsers = async () => {
        const response = await fetchWithAuth(`${baseUrl}user/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }
        return await response.json();
    };


    const activateDeactivateUser = async (userId) => {
        try {
            const response = await fetchWithAuth(`${baseUrl}activate-deactivate-user/${userId}/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                if (response.status === 403) {
                    swal.fire({
                        title: "Permission Denied: \nYou cannot deactivate your own account.",
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        position: 'top-right',
                        timerProgressBar: true,
                        showConfirmButton: false,
                    });
                } else {
                    throw new Error('Failed to activate/deactivate user');
                }
            } else {
                navigate("/users");
                const data = await response.json();
                swal.fire({
                    title: data.message,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            console.error("Activate/Deactivate user error:", error);
            swal.fire({
                title: "Failed to activate/deactivate user",
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };

    const forgotPassword = async (email) => {
        try {
            const response = await fetch(`${baseUrl}forgot-password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                const data = await response.json();
                swal.fire({
                    title: 'Success',
                    text: data.message,
                    icon: 'success',
                    timer: 3000,
                    timerProgressBar: true,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false
                });
            }
            else {
                const errorData = await response.json();
                swal.fire({
                    title: 'Error',
                    text: errorData.email || 'An error occurred. Please try again.',
                    icon: 'error',
                    timer: 3000,
                    timerProgressBar: true,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false
                });
            }
        } catch (error) {
            console.error(error);
            swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                timer: 3000,
                timerProgressBar: true,
                toast: true,
                position: 'top-end',
                showConfirmButton: false
            });
        }
    };

    const resetPassword = async (uidb64, token, newPassword, confirmPassword) => {
        try {
            const response = await fetch(`${baseUrl}reset-password/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ uidb64, token, new_password: newPassword, confirm_password: confirmPassword })
            });

            if (response.ok) {
                swal.fire({
                    title: "Password reset successful!",
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    position: 'top-end',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
                navigate("/login");
            }
            else if (response.status === 404) {
                const errorData = await response.json();
                swal.fire({
                    title: "Error",
                    text: errorData.error || 'An error occurred. Please try again.',
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    position: 'top-end',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
                navigate("/login");
            }
            else {
                const errorData = await response.json();
                swal.fire({
                    title: "Failed to reset password",
                    text: errorData.error || 'An error occurred. Please try again.',
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    position: 'top-end',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            console.error("Reset password error:", error);
            swal.fire({
                title: "Failed to reset password",
                text: error.message,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-end',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };


    const refreshToken = useCallback(async () => {
        try {
            if (!authTokens?.refresh) {
                logoutUser();
                return;
            }
            const response = await fetch(`${baseUrl}token/refresh/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    refresh: authTokens.refresh,
                }),
            });

            const data = await response.json();

            if (response.status === 200) {
                if (typeof data.access === 'string') {
                    setAuthTokens(data);
                    setUser(jwtDecode(data.access));
                    localStorage.setItem("authTokens", JSON.stringify(data));
                } else {
                    throw new Error('Token is not a valid string');
                }
            } else {
                logoutUser();
            }
        } catch (error) {
            console.error("Failed to refresh token", error);
            logoutUser();
        }
    }, [authTokens, logoutUser]);

    const handleInactivity = useCallback(() => {
        logoutUser();
    }, [logoutUser]);

    useEffect(() => {
        if (authTokens) {
            const token = authTokens.access;
            if (token && typeof token === 'string') {
                setUser(jwtDecode(token));
            } else {
                setUser(null);
            }
        }
        setLoading(false);
    }, [authTokens]);

    useEffect(() => {
        const events = ["mousemove", "keydown", "click"];
        let timeout;

        const resetTimeout = () => {
            clearTimeout(timeout);
            timeout = setTimeout(handleInactivity, 60 * 60 * 1000); // Déconnexion après 1 heure d'inactivité
        };

        events.forEach(event => window.addEventListener(event, resetTimeout));

        resetTimeout(); // Initialiser le timeout

        return () => {
            events.forEach(event => window.removeEventListener(event, resetTimeout));
            clearTimeout(timeout);
        };
    }, [handleInactivity]);

    return (
        <AuthContext.Provider value={{
            authTokens, setAuthTokens,
            user, setUser,
            loading,
            loginUser,
            registerUser,
            logoutUser,
            createUser,
            updateUser,
            deleteUser,
            getUserById,
            getUsers,
            activateDeactivateUser,
            resetPassword,
            refreshToken,
            forgotPassword,
            fetchWithAuth,
        }}>
            {children}
        </AuthContext.Provider>
    );
};
