import React, { createContext, useContext } from 'react';
import AuthContext from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { baseUrlStudent } from './BaseUrl';
const swal = require('sweetalert2')
const baseUrl = baseUrlStudent;

const StudentContext = createContext();

const StudentProvider = ({ children }) => {
    const { authTokens, fetchWithAuth } = useContext(AuthContext);
    const navigate = useNavigate(); // Utilisez useNavigate pour la navigation
    // Fonctions de gestion des étudiants
    const getStudents = async () => {
        try {
            const response = await fetchWithAuth(`${baseUrl}create/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            // Gérer les erreurs ici
        }
    };
    const getStudentsArchived = async (year) => {
        try {
            const response = await fetchWithAuth(`${baseUrl}archived/${year}/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            // Gérer les erreurs ici
        }
    };

    const getStudentsArchivedProfile = async (year, student_id) => {
        try {
            const response = await fetchWithAuth(`${baseUrl}archived/${year}/${student_id}/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            // Gérer les erreurs ici
        }
    };

    const getStudentsNonPaye = async () => {
        try {
            const response = await fetchWithAuth(`${baseUrl}list-students/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            // Filtrer les lignes vides
            const filteredData = data.filter(item => Object.keys(item).length !== 0);
            return filteredData;
        } catch (error) {
            console.error(error);
            // Gérer les erreurs ici
        }
    };
    const createStudents = async (formData) => {
        const response = await fetchWithAuth(`${baseUrl}create/`, {
            method: "POST",
            body: formData // Utilisation directe de FormData, sans conversion en JSON
        });

        const responseText = await response.text();

        if (response.ok) {
            swal.fire({
                title: "Inscription Created Successfully",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
            window.location.reload();
            const responseData = JSON.parse(responseText);
            return responseData.matricule;
        } else {
            // console.log(response.status);
            // console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };


    const createClasse = async (formData) => {
        const response = await fetchWithAuth(`${baseUrl}classes/`, {
            method: "POST",
            body: formData
        });

        if (response.status === 201) {
            window.location.reload();
            //   navigate("/students");

            swal.fire({
                title: "Inscription Created Successfully",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
            const data = await response.json();
            return data.matricule; // Return
        } else {
            // console.log(response.status);
            // console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };
    const getStudentById = async (userId) => {
        const response = await fetchWithAuth(`${baseUrl}student-profile/${userId}/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        // console.log(data);
        return data;
    }
    const updateStudent = async (userId, formData) => {
        const response = await fetchWithAuth(`${baseUrl}student-profile/${userId}/`, {
            method: "PUT",
            body: formData
        });
        if (response.status === 200) {
            swal.fire({
                title: "Student Updated Successfully",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
            navigate("/students");
        } else {
            // console.log(response.status);
            // console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status + "\n" + response.error,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    }
    const deleteStudent = async (userId) => {
        const response = await fetchWithAuth(`${baseUrl}student-profile/${userId}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.status === 204) {
            swal.fire({
                title: "Student Deleted Successfully",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        } else {
            // console.log(response.status);
            // console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    }
    const getInscriptions = async () => {
        const response = await fetchWithAuth(`${baseUrl}inscriptions/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        // Filtrer les lignes vides
        const filteredData = data.filter(item => Object.keys(item).length !== 0);
        return filteredData;
    }
    const getInscriptionsById = async (userId) => {
        const response = await fetchWithAuth(`${baseUrl}inscription-profile/${userId}/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        // console.log(data);
        return data;
    }
    const deleteInscriptions = async (userId) => {
        const response = await fetchWithAuth(`${baseUrl}inscription-profile/${userId}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.status === 204) {
            swal.fire({
                title: "User Deleted Successfully",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        } else {
            // console.log(response.status);
            // console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    }
    const updateInscriptions = async (userId, formData) => {
        const response = await fetchWithAuth(`${baseUrl}inscription-profile/${userId}/`, {
            method: "PUT",
            body: formData
        });
        if (response.status === 200) {
            swal.fire({
                title: "Inscription Updated Successfully",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
            navigate("/inscription");
        } else {
            // console.log(response.status);
            // console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status + "\n" + response.error,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    }
    const getClasses = async () => {
        const response = await fetchWithAuth(`${baseUrl}classes/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        return data;
    };
    const createInscription = async (formData) => {
        const response = await fetchWithAuth(`${baseUrl}inscriptions/`, {
            method: "POST",
            body: formData
        });

        if (response.status === 201) {
            //   navigate("/inscription");

            swal.fire({
                title: "Inscription Created Successfully",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
            const data = await response.json();
            // window.location.reload();
            return data.matricule; // Return
        } else {
            // console.log(response.status);
            // console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };
    const createReinscription = async (formData) => {
        const response = await fetchWithAuth(`${baseUrl}reinscription/`, {
            method: "POST",
            body: formData
        });

        if (response.status === 201) {
            //   navigate("/inscription");

            swal.fire({
                title: "Reinscription Created Successfully",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
            const data = await response.json();
            window.location.reload();
            return data.matricule; // Return
        } else {
            // console.log(response.status);
            // console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };
    const createConcours = async (formData) => {
        const response = await fetchWithAuth(`${baseUrl}passer-concours/`, {
            method: "POST",
            body: formData
        });

        if (response.status === 200) {
            window.location.reload();
            // navigate("/concours");

            swal.fire({
                title: "Examen passé avec succès",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        } else if (response.status === 400) {
            // window.location.reload();
            navigate("/concours");

            swal.fire({
                title: "Note insuffisante pour passer le concours",
                icon: "warning",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        } else {
            // console.log(response.status);
            // console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };


    const contextData = {
        authTokens,
        getStudents,
        getClasses,
        createInscription,
        getInscriptions,
        getInscriptionsById,
        updateInscriptions,
        deleteInscriptions,
        createConcours,
        createStudents,
        getStudentById,
        updateStudent,
        deleteStudent,
        getStudentsNonPaye,
        createReinscription,
        createClasse,
        getStudentsArchived,
        getStudentsArchivedProfile,

    };

    return (
        <StudentContext.Provider value={contextData}>
            {children}
        </StudentContext.Provider>
    );
};

export { StudentContext, StudentProvider };
