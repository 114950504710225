import  { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../Includes/Footer";
import Sidebar from "../../Includes/Sidebar";
import AuthContext from "../../context/AuthContext"; // Importez le contexte d'authentification

function UserEdit() {
  const { getUserById } = useContext(AuthContext);
  const [records, setRecords] = useState();
  const location = useLocation();
  const { user_id } = location.state || {};

  useEffect(() => {
    const userId = user_id;
    getUserById(userId).then((data) => {
      setRecords(data); // Mettez à jour records avec les données récupérées
    });
  }, [getUserById, user_id]);
  return (
    <>
      <Sidebar />
      <div className="content-wrapper wow fadeIn " data-wow-delay="0.05s ">
        <section className="content-header mt-3">
          <div className="container-fluid">
            <div className="row mb-2 mt-3">
              <div className="col-sm-6 d-flex align-items-center">
                <div className="bg-blue rounded-lg p-2 text-center">
                  <i className="fas fa-users-cog"></i>
                </div>
                <div className="ml-2">
                  <h3 className="display fw-12">Profil Utilisateur</h3>
                </div>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Tableau de bord</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/users">Utilisateurs</Link>
                  </li>
                  <li className="breadcrumb-item active">Profil</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid">
          <div className="card p-3">
      <div className="col-md-12 text-end">
      <Link to="/users" className="btn btn-primary rounded-lg">
    <i className="fa fa-arrow-left"></i>
      </Link>
      </div>
            <form className="forms-sample" encType="multipart/form-data">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="profil">Photo</label>
                  <div className="form-group">
                    <img
                      src={records?.image || ""}
                      id="profil"
                      alt="profil"
                      style={{
                        maxWidth: "80px",
                        maxHeight: "80px",
                        borderRadius: "50px",
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name">Nom</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={
                        records?.first_name + " " + records?.last_name || ""
                      }
                      readOnly
                      placeholder="Nom Utilisateur"
                      required
                      name="full_name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail3">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail3"
                      readOnly
                      value={records?.email || ""}
                      placeholder="Email"
                      required
                      name="email"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name">Nom Utilisateur</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      readOnly
                      value={records?.username || ""}
                      placeholder="Nom Utilisateur"
                      required
                      name="username"
                    />
                  </div>
                </div>
                {/* <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="name">Mot de passe</label>
            <input type="text" className="form-control" id="name" 
            placeholder="Nom Utilisateur" required name='password'/>
          </div>
        </div> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="role">Role</label>
                    <input
                      type="text"
                      className="form-control"
                      id="role"
                      readOnly
                      value={records?.role || ""}
                      placeholder="Nom Utilisateur"
                      required
                      name="username"
                    />
                    {/* <select name='role' className="form-control" id="exampleSelectGender" readOnly
            value={records?.role || ''} >
              <option readOnly></option>
              <option>Admin</option>
              <option>User</option>
              <option>Teacher</option>
              <option>Secretaire</option>
            </select> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="verified">Super Utilisateur</label>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="verified"
                        disabled
                        checked={records?.is_superuser || false}
                        onChange={(e) =>
                          setRecords({
                            ...records,
                            is_superuser: e.target.checked,
                          })
                        }
                      />
                      <label className="form-check-label" htmlFor="verified">
                        Vérifié
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="verified">Staff</label>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="verified"
                        disabled
                        checked={records?.is_staff || false}
                        onChange={(e) =>
                          setRecords({ ...records, is_staff: e.target.checked })
                        }
                      />
                      <label className="form-check-label" htmlFor="verified">
                        Staff
                      </label>
                    </div>
                  </div>
                </div>
                {/* 
        <div className="form-group col-md-6">
          <label htmlFor="exampleInputFile">Fichier importer</label>
          <div className="input-group">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="exampleInputFile"
                accept="image/*"
                value={records?.image.url || ''}
                name='image'
                onChange={handleImageChange}
              />
              <label className="custom-file-label" htmlFor="exampleInputFile">
                {profileImage ? 'Image sélectionnée' : 'Sélectionner une image'}
              </label>
            </div>
            <div className="input-group-append">
              <span className="input-group-text">Importer</span>
            </div>
          </div>
        </div> */}
                <div className="col-md-12 mt-4 text-center">
                  {/* <div className="form-group pt-1">
            <Button type="submit" variant="btn btn-primary">
              <i className="fas fa-save"></i> Enregistrer
            </Button>
          </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UserEdit;
