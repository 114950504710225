import React from "react";
import { Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "./Programs.css";

const swal = require('sweetalert2')

const Programs = () => {
  // Exemple de données des programmes avec des couleurs variées
  const programs = [
    {
      id: 1,
      title: "Créole",
      fileName: "Télécharger",
      fileUrl: "/pdfs/PWOGRAM_KAD_KREYOL.pdf",
      color: "primary",
    },
    {
      id: 2,
      title: "Français",
      fileName: "Télécharger",
      fileUrl: "/pdfs/PROGRAMME_CADRE_FRANCAIS.pdf",
      color: "success",
    },
    {
      id: 3,
      title: "Anglais",
      fileName: "Télécharger",
      fileUrl: "/pdfs/PROGRAMME_CADRE_ANGLAIS.pdf",
      color: "danger",
    },
    {
      id: 4,
      title: "Mathématiques",
      fileName: "Télécharger",
      fileUrl: "/pdfs/PROGRAMME_CADRE_MATHEMATIQUE.pdf",
      color: "warning",
    },
    {
      id: 5,
      title: "Histoire",
      fileName: "Télécharger",
      fileUrl: "/pdfs/PROGRAMME_CADRE_SCIENCES_SOCIALES.pdf",
      color: "info",
    },
    {
      id: 6,
      title: "Géographie",
      fileName: "Télécharger",
      fileUrl: "",
      color: "dark",
    },
    {
      id: 7,
      title: "Physique",
      fileName: "Télécharger",
      fileUrl: "",
      color: "secondary",
    },
    {
      id: 8,
      title: "Chimie",
      fileName: "Télécharger",
      fileUrl: "/pdfs/PROGRAMME_CADRE_CHIMIE.pdf",
      color: "primary",
    },
    {
      id: 9,
      title: "Biologie",
      fileName: "Télécharger",
      fileUrl: "/pdfs/PROGRAMME_CADRE_BIOLOGIE.pdf",
      color: "success",
    },
    {
      id: 10,
      title: "Geologie",
      fileName: "Télécharger",
      fileUrl: "",
      color: "danger",
    },
    {
      id: 11,
      title: "Economie",
      fileName: "Télécharger",
      fileUrl: "/pdfs/PROGRAMME_CADRE_ECONOMIE.pdf",
      color: "warning",
    },
    {
      id: 12,
      title: "EPS",
      fileName: "Télécharger",
      fileUrl: "/pdfs/PROGRAMME_EPS.pdf",
      color: "info",
    },
    {
      id: 13,
      title: "Art & Musique",
      fileName: "Télécharger",
      fileUrl: "",
      color: "dark",
    },
    {
      id: 14,
      title: "Education à la Citoyenneté",
      fileName: "Télécharger",
      fileUrl: "",
      color: "secondary",
    },
    {
      id: 15,
      title: "Informatiques",
      fileName: "Télécharger",
      fileUrl: "/pdfs/PROGRAMME_CADRE_INFORMATIQUE.pdf",
      color: "success",
    },
    {
      id: 16,
      title: "Espagnol",
      fileName: "Télécharger",
      fileUrl: "/pdfs/PROGRAMME_CADRE_ESPAGNOL.pdf",
      color: "info",
    },
  ];

  // Fonction pour télécharger le fichier
  const handleDownload = (fileUrl, title) => {
    if (!fileUrl) {
      swal.fire({
        title: '?',
        text: `Le programme "${title}" n'est pas encore disponible.`,
        icon: 'warning',
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }
  
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank"; // Ouvre dans un nouvel onglet pour forcer le téléchargement
    link.rel = "noopener noreferrer";
    link.download = fileUrl.split('/').pop(); // Nom du fichier automatiquement extrait
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  return (
    <>
      <Helmet>
        <title>Programme - Lycée François Cappoix de Limonade</title>
        <meta
          name="description"
          content="Découvrez le Lycée François Cappoix de Limonade, un établissement éducatif dédié à l'épanouissement des élèves. Apprenez-en plus sur notre équipe, nos valeurs et nos activités culturelles."
        />
        <meta
          name="keywords"
          content="lycée, éducation, développement des élèves, culture, apprenants, Limonade, François Cappoix"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="container-xxl py-5" id="programmes">
        <div className="container">
          <h1 className="display-5 mb-4 text-center">Nos Programmes</h1>
          <p className="lead text-center">
            Découvrez les programmes scolaires disponibles et téléchargez le
            programme détaillé pour chaque classe et matière.
          </p>

          {/* Utilisation de Accordion avec alwaysOpen={false} */}
          <Accordion alwaysOpen={false}>
            <div className="row">
              {programs.map((program) => (
                <div className="col-md-3 mb-3" key={program.id}>
                  <Accordion.Item eventKey={program.id.toString()}>
                    <Accordion.Header>{program.title}</Accordion.Header>
                    <Accordion.Body>
                      <button className={`btn btn-${program.color} btn-sm`}
                       onClick={() =>
                        handleDownload(program.fileUrl, program.title)
                      }>
                        <i className="fas fa-download me-2"></i>
                        {program.fileName}
                      </button>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              ))}
            </div>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default Programs;
