
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../context/AuthContext'

function Register() {

    const [email, setEmail] = useState("")
    const [full_name, setFull_name] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const {registerUser} = useContext(AuthContext)

    const handleSubmit = async e => {
      e.preventDefault()
      registerUser(full_name,email, username, password, password2)
    }
    
    return (
        <>
        <div className="wrapper">
            <div className="container-fluid h-100">
                <div className="row flex-row h-100">
                    <div className="col-xl-8 p-0 d-none d-md-block">
                        <div className="lavalite-bg" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 100, 0.5), rgba(0, 0, 100, 0.5)), url(./img/auth/login-bg.png)`, height: '100vh', backgroundSize: 'cover'}}>
                            <div className="lavalite-overlay"></div>
                        </div>
                    </div>
                    <div className="col-xl-4 my-auto">
                    <div className="card">
                    <Link to="/" className="close bg-white mt-0 mb-3" style={{ display: 'flex' }}>
                        <i className="fa fa-home text-primary"></i>
                    </Link>

                    </div>
                        <div className="container-fluid mx-auto p-4">
                            <div className="d-flex justify-content-center align-items-center mb-2">
                                <a href="ghjhmll"><img src='./img/log.png' alt="logo" /></a>
                            </div>
                            <h6 className="text-center">Lycée François Cappoix de Limonade</h6>
                            <div className='row justify-content-center align-items-center  elevation-1 pb-2'
                            style={{borderTopLeftRadius:'20px',
                                    borderTopRightRadius:'20px',
                                    borderBottomRightRadius:'0px',
                                    borderBottomLeftRadius:'0px'}}>
                                    <h4 className='display text-blue text-center  p-0'>Inscription<hr/></h4>
                                
                            <form onSubmit={handleSubmit}>
                                <div className="form-group position-relative">
                                    <label htmlFor="name">Nom Complet</label>
                                    <input type="text" id='name' 
                                    className="form-control " 
                                    onChange={e => setFull_name(e.target.value)}
                                    placeholder="Nom Complet" required name='full_name'/>
                                    </div>
                                <div className="form-group position-relative">
                                    <label htmlFor="name">Nom Utilisateur</label>
                                    <input type="text" id='name' 
                                    className="form-control " 
                                    onChange={e => setUsername(e.target.value)}
                                    placeholder="Email" required name='email'/>
                                    </div>
                                <div className="form-group position-relative">
                                    <label htmlFor="email">Email</label>
                                    <input type="text" className="form-control " 
                                    onChange={e => setEmail(e.target.value)}
                                    placeholder="Email" id='email' required name='email'/>
                                </div>
                                <div className="form-group position-relative">
                                    <label htmlFor="pass">Mot de passe</label>
                                    <input type="password" id='pass' 
                                    className="form-control "
                                    onChange={e => setPassword(e.target.value)}
                                    placeholder="Mot de passe" name='password' required/>
                                </div>
                                <div className="form-group position-relative">
                                    <label htmlFor="pass">Confirmer Mot de passe</label>
                                    <input type="password"id='pass'
                                     className="form-control "
                                     onChange={e => setPassword2(e.target.value)}
                                    placeholder="Mot de passe" name='password' required/>
                                </div>
                                <div className="row">
                                    <div className="col text-left">
                                        <p>Vous avez déjà un compte ? <Link to="/login">Connecter</Link></p>
                                    </div>
                                </div>
                                <div className="sign-btn text-center">
                                    <button className="btn btn-primary" type="submit">Incrire</button>
                                </div>
                            </form>
                            </div>
    
    
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
    
    </>
      )
}

export default Register