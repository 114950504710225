import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async"; // Import HelmetProvider
import Home from "../pages/Home";
import Dashboard from "../Layouts/Dashboard";
import Inscription from "../Layouts/students/Inscription";
import Reinscription from "../Layouts/students/Reinscription";
import Students from "../Layouts/students/Students";
import Teacher from "../Layouts/teachers/Teacher";
import Archives from "../Layouts/students/Archives";
import LoginResult from "../Layouts/Auth/LoginResult";
import Login from "../Layouts/Auth/Login";
import User from "../Layouts/Auth/User";
import { AuthProvider } from "../context/AuthContext";
import PrivateRoute from "../utils/PrivateRoute";
import Register from "../Layouts/Auth/Register";
import ForgotPassword from "../Layouts/Auth/ForgotPassword";
import ResetPassword from "../Layouts/Auth/ResetPassword";
import UserDetail from "../Layouts/Auth/UserDetail";
import StudentProfile from "../Layouts/students/StudentProfile";
import { StudentProvider } from "../context/StudentContext";
import { TeacherProvider } from "../context/TeacherContext";
import TeacherEdit from "../Layouts/teachers/TeacherEdit";
import { CourseProvider } from "../context/CourseContext";
import Courses from "../Layouts/courses/Courses";
import CourseEdit from "../Layouts/courses/CourseEdit";
import Concours from "../Layouts/students/Concours";
import Profile from "../Layouts/Auth/Profile";
import InscriptionEdit from "../Layouts/students/InscriptionEdit";
import Note from "../Layouts/notes/Notes";
import Bulletin from "../Layouts/notes/Bulletin";
import { AdminProvider } from "../context/AdminContext";
import AdminPanel from "../settings/AdminPanel";
import Paiements from "../Layouts/paiments/Paiements";
import { PaiementProvider } from "../context/PaiementContext";
import DetailsNote from "../Layouts/notes/DetailsNote";
import StudentResult from "../Layouts/results/StudentResult";
import Badge from "../Layouts/students/Badge/Badge";
import StudentArchivedprofile from "../Layouts/students/StudentArchivedprofile";
import DetailsNoteArchived from "../Layouts/notes/DetailsNoteArchived";
import NotFound from "../Layouts/400/NotFound";
import PaiementDetails from "../Layouts/paiments/PaiementDetails";
import PaiementInscDetails from "../Layouts/paiments/PaiementInscDetails";

function AppRoutes() {
  return (
    <HelmetProvider>
      <div className="wrapper">
        <Router>
          <AuthProvider>
            <StudentProvider>
              <TeacherProvider>
                <CourseProvider>
                  <AdminProvider>
                    <PaiementProvider>
                      <Routes>
                        {/* Public Routes */}
                        <Route
                          path="/"
                          element={
                            <>
                              <Helmet>
                                <title>Accueil - Lycée François Cappoix de Limonade</title>
                                <meta name="description" content="Bienvenue sur le site officiel du Lycée François Cappoix de Limonade. Découvrez notre engagement envers l'éducation et le développement des jeunes." />
                                <meta property="og:image" content="/log.png" />
                                <meta name="keywords" content="Lycée, Education, François Cappoix, Accueil, LNFCL, lnfcl, Lycée National François Cappoix" />
                              </Helmet>
                              <Home />
                            </>
                          }
                        />
                        <Route
                          path="/login"
                          element={
                            <>
                              <Helmet>
                                <title>Connexion - Lycée François Cappoix de Limonade</title>
                                <meta name="description" content="Connectez-vous pour accéder à votre espace personnel au Lycée François Cappoix de Limonade." />
                                <meta name="keywords" content="Lycée, Education, François Cappoix, Connexion, LNFCL, lnfcl, Lycée National François Cappoix" />
                                </Helmet>
                              <Login />
                            </>
                          }
                        />
                        <Route
                          path="/forgot-password"
                          element={
                            <>
                              <Helmet>
                                <title>Mot de passe oublié - Lycée François Cappoix de Limonade</title>
                                <meta name="description" content="Réinitialisez votre mot de passe pour accéder à votre compte." />
                                <meta name="keywords" content="Lycée, Education, François Cappoix, LNFCL, lnfcl, Lycée National François Cappoix" />
                                </Helmet>
                              <ForgotPassword />
                            </>
                          }
                        />
                        <Route
                          path="/reset-password/:uidb64/:token"
                          element={
                            <>
                              <Helmet>
                                <title>Réinitialisation du mot de passe - Lycée François Cappoix de Limonade</title>
                                <meta name="description" content="Réinitialisez votre mot de passe en toute sécurité." />
                                <meta name="keywords" content="Lycée, Education, François Cappoix, Accueil, LNFCL, lnfcl, Lycée National François Cappoix" />
                                </Helmet>
                              <ResetPassword />
                            </>
                          }
                        />
                        <Route
                          path="/register"
                          element={
                            <>
                              <Helmet>
                                <title>Inscription - Lycée François Cappoix de Limonade</title>
                                <meta name="description" content="Inscrivez-vous pour accéder aux services du Lycée François Cappoix de Limonade." />
                                <meta name="keywords" content="Lycée, Education, François Cappoix, Accueil, LNFCL, lnfcl, Lycée National François Cappoix" />
                                </Helmet>
                              <Register />
                            </>
                          }
                        />
                        <Route
                          path="/student-result"
                          element={
                            <>
                              <Helmet>
                                <title>Résultats des étudiants - Lycée François Cappoix de Limonade</title>
                                <meta name="description" content="Consultez les résultats académiques des étudiants." />
                                <meta name="keywords" content="Lycée, Education, François Cappoix, resultats, LNFCL, lnfcl, Lycée National François Cappoix" />
                                </Helmet>
                              <StudentResult />
                            </>
                          }
                        />

                        {/* Private Routes */}
                        <Route path="/result" element={<LoginResult />} />
                        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                        <Route path="/admin-settings" element={<PrivateRoute><AdminPanel /></PrivateRoute>} />
                        <Route path="/paiements-eleves" element={<PrivateRoute><Paiements /></PrivateRoute>} />
                        <Route path="/paiement-detail" element={<PrivateRoute><PaiementDetails /></PrivateRoute>} />
                        <Route path="/paiement-insc-detail" element={<PrivateRoute><PaiementInscDetails /></PrivateRoute>} />

                        {/* Student */}
                        <Route path="/inscription" element={<PrivateRoute><Inscription /></PrivateRoute>} />
                        <Route path="/concours" element={<PrivateRoute><Concours /></PrivateRoute>} />
                        <Route path="/reinscription" element={<PrivateRoute><Reinscription /></PrivateRoute>} />
                        <Route path="/students" element={<PrivateRoute><Students /></PrivateRoute>} />
                        <Route path="/student-profile/" element={<PrivateRoute><StudentProfile /></PrivateRoute>} />
                        <Route path="/inscription-profile/" element={<PrivateRoute><InscriptionEdit /></PrivateRoute>} />
                        <Route path="/archives" element={<PrivateRoute><Archives /></PrivateRoute>} />
                        <Route path="/badge" element={<PrivateRoute><Badge /></PrivateRoute>} />
                        <Route path="/student/archive/profile" element={<PrivateRoute><StudentArchivedprofile /></PrivateRoute>} />
                        <Route path="/archived-student-results/detail" element={<PrivateRoute><DetailsNoteArchived /></PrivateRoute>} />

                        {/* Teacher */}
                        <Route path="/teacher" element={<PrivateRoute><Teacher /></PrivateRoute>} />
                        <Route path="/detail-teacher" element={<PrivateRoute><TeacherEdit /></PrivateRoute>} />

                        {/* Courses */}
                        <Route path="/courses" element={<PrivateRoute><Courses /></PrivateRoute>} />
                        <Route path="/course-detail/" element={<PrivateRoute><CourseEdit /></PrivateRoute>} />
                        <Route path="/notes" element={<PrivateRoute><Note /></PrivateRoute>} />
                        <Route path="/student/detail-note" element={<PrivateRoute><DetailsNote /></PrivateRoute>} />
                        <Route path="/bulletin" element={<PrivateRoute><Bulletin /></PrivateRoute>} />

                        {/* User */}
                        <Route path="/users" element={<PrivateRoute><User /></PrivateRoute>} />
                        <Route path="/user-edit" element={<PrivateRoute><Profile /></PrivateRoute>} />
                        <Route path="/user-profile" element={<PrivateRoute><UserDetail /></PrivateRoute>} />
                    
                        {/* Route 404 */}
                        <Route
                          path="*"
                          element={
                            <>
                              <Helmet>
                                <title>Page non trouvée - Lycée François Cappoix de Limonade</title>
                                <meta name="description" content="La page que vous recherchez n'existe pas." />
                                <meta name="keywords" content="Lycée, Education, François Cappoix, page non trouvée, LNFCL, lnfcl, Lycée National François Cappoix" />
                                </Helmet>
                              <NotFound />
                            </>
                          }
                        />
                      </Routes>
                    </PaiementProvider>
                  </AdminProvider>
                </CourseProvider>
              </TeacherProvider>
            </StudentProvider>
          </AuthProvider>
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default AppRoutes;
