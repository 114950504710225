import React, { createContext, useContext, useState } from 'react';
import AuthContext from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { baseUrlPaiement } from './BaseUrl';
const swal = require('sweetalert2')
const baseUrl = baseUrlPaiement;

const PaiementContext = createContext();

const PaiementProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { authTokens, user, fetchWithAuth } = useContext(AuthContext);
  const navigate = useNavigate(); // Utilisez useNavigate pour la navigation


  const getPaiements = async () => {
    try {
      const response = await fetchWithAuth(`${baseUrl}stdpay/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      // handleAuthError(response);
      const data = await response.json();
      if (data) {
        return data;
      }
    } catch (error) {
      console.error("Error fetching Paiements:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getPaiementsInsc = async () => {
    try {
      const response = await fetchWithAuth(`${baseUrl}inscriptions/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      // handleAuthError(response);
      const data = await response.json();
      if (data) {
        return data;
      }
    } catch (error) {
      console.error("Error fetching Paiements:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getPaiementById = async (PaiementId) => {
    const response = await fetchWithAuth(`${baseUrl}stdpay/${PaiementId}/detail/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    //   handleAuthError(response);
    const data = await response.json();
    // console.log(data);
    return data;
  }
  const getPaiementInscById = async (PaiementId) => {
    const response = await fetchWithAuth(`${baseUrl}inscpay/${PaiementId}/detail/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    //   handleAuthError(response);
    const data = await response.json();
    // console.log(data);
    return data;
  }

  const deletePaiements = async (PaiementId) => {
    const response = await fetchWithAuth(`${baseUrl}${PaiementId}/detail/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    //   handleAuthError(response);
    if (response.status === 204) {
      swal.fire({
        title: "User Deleted Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    } else {
      // console.log(response.status);
      // console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }
  }
  const updatePaiements = async (PaiementId, formData) => {
    const response = await fetchWithAuth(`${baseUrl}${PaiementId}/detail/`, {
      method: "PUT",
      body: formData
    });
    //   handleAuthError(response);
    if (response.status === 200) {
      swal.fire({
        title: "Paiement Updated Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
      navigate("/Paiements");
    } else {
      // console.log(response.status);
      // console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status + "\n" + response.error,
        icon: "error",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }
  }
  // const createPaiement = async (formData) => {
  //     const response = await fetchWithAuth(`${baseUrl}stdpay/`, {
  //    method: "POST",
  //    headers: {
  //        "Content-Type": "application/json",
  //    },body: formData
  // }); 
  //     // handleAuthError(response);

  //     if (response.status === 201) {
  //         window.location.reload();
  //         swal.fire({
  //             title: "Paiement Created Successfully",
  //             icon: "success",
  //             toast: true,
  //             timer: 3000,
  //             position: 'top-right',
  //             timerProgressBar: true,
  //             showConfirmButton: false,
  //         });
  //     }else if (response.status === 400) {
  //       swal.fire({
  //           title: "Paiement déjà pour cet élève ",
  //           icon: "error",
  //           toast: true,
  //           timer: 3000,
  //           position: 'top-right',
  //           timerProgressBar: true,
  //           showConfirmButton: false,
  //       });
  //   } else {
  //         // console.log(response.status);
  //         // console.log("there was a server issue");
  //         swal.fire({
  //             title: "An Error Occured " + response.status,
  //             icon: "error",
  //             toast: true,
  //             timer: 3000,
  //             position: 'top-right',
  //             timerProgressBar: true,
  //             showConfirmButton: false,
  //         });
  //     }
  // };
  const createPaiement = async (formData) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}stdpay/`, {
        method: "POST",
        body: formData
      });

      // const responseText = await response.text(); // Lire la réponse en texte brut
      // console.log("Response Text:", responseText); // Afficher la réponse pour débogage

      if (response.ok) {
        // Vérifiez si la réponse est en JSON
        try {
          // const responseData = JSON.parse(responseText);
          // Traitez les données ici
          swal.fire({
            title: "Paiement Created Successfully",
            icon: "success",
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } catch (e) {
          console.error("Failed to parse JSON:", e);
          // Traitez l'erreur de parsing JSON
        }
      } else {
        // Gérer les autres codes de statut
        // console.log(response.status);
        // console.log("there was a server issue");
        swal.fire({
          title: "An Error Occured " + response.status,
          icon: "error",
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error('Erreur lors de la requête:', error);
      swal.fire({
        title: "An Error Occured",
        icon: "error",
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };


  const contextData = {
    authTokens,
    user,
    createPaiement,
    getPaiements,
    getPaiementById,
    updatePaiements,
    deletePaiements,
    getPaiementsInsc,
    isLoading,
    getPaiementInscById,
  };

  return (
    <PaiementContext.Provider value={contextData}>
      {children}
    </PaiementContext.Provider>
  );
};

export { PaiementContext, PaiementProvider };
