/* eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../Includes/Sidebar'
import Footer from '../Includes/Footer'
import { AdminContext } from '../context/AdminContext';
import Loader from '../Includes/loader/loader';

function Dashboard() {
  const {
    getStudentCountByClass,
    getCourseCount,
    getTeacherCount,
    getLastFiveStudents,
    getStudentCount,
    getStudentCountByLevel,
    getInscriptionCount,
    getVerifiedStudentCount,
    getVerifiedInscriptionCount,
    getUnVerifiedStudentCount,
    getTotalSum, // Ajoutez cette ligne
  } = useContext(AdminContext);

  const [studentCountByClass, setStudentCountByClass] = useState([]);
  const [courseCount, setCourseCount] = useState(null);
  const [studentCount, setStudentCount] = useState(null);
  const [VstudentCount, setVstudentCount] = useState(null);
  const [UvstudentCount, setUvstudentCount] = useState(null);
  const [VInscCount, setVisncCount] = useState(null);
  const [inscCount, setInscCount] = useState(null);
  const [teacherCount, setTeacherCount] = useState(null);
  const [studentCountByNiveau, setStudentCountByNiveau] = useState({});
  const [lastFiveStudents, setLastFiveStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalSum, setTotalSum] = useState({
    total_insc_pay: 0,
    total_std_pay: 0,
    total_amount: 0,
  });
  const getFormattedDate = () => {
    const optionsDate = { day: 'numeric', month: 'short', year: 'numeric' };
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };
    const now = new Date();

    // Obtenir la date et l'heure au format souhaité
    const date = now.toLocaleDateString('fr-FR', optionsDate);
    const time = now.toLocaleTimeString('fr-FR', optionsTime);

    return `${date} | ${time}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {

        setLoading(true);
        const studentCountBy = await getStudentCountByClass();
        setStudentCountByClass(studentCountBy);
        setLoading(false);


        setLoading(true);
        const studentCountByNiveau = await getStudentCountByLevel();
        setStudentCountByNiveau(studentCountByNiveau);
        setLoading(false);

        setLoading(true);
        const courseCount = await getCourseCount();
        setCourseCount(courseCount);
        setLoading(false);

        setLoading(true);
        const studentCount = await getStudentCount();
        setStudentCount(studentCount);
        setLoading(false);

        setLoading(true);
        const VstudentCount = await getVerifiedStudentCount();
        setVstudentCount(VstudentCount);
        setLoading(false);

        setLoading(true);
        const UvstudentCount = await getUnVerifiedStudentCount();
        setUvstudentCount(UvstudentCount);
        setLoading(false);

        setLoading(true);
        const VinscCount = await getVerifiedInscriptionCount();
        setVisncCount(VinscCount);
        setLoading(false);

        setLoading(true);
        const inscCount = await getInscriptionCount();
        setInscCount(inscCount);
        setLoading(false);

        setLoading(true);
        const teacherCount = await getTeacherCount();
        setTeacherCount(teacherCount);
        setLoading(false);

        setLoading(true);
        const lastFive = await getLastFiveStudents();
        setLastFiveStudents(lastFive);
        setLoading(false);

        setLoading(true);
        const totalSum = await getTotalSum();
        setTotalSum(totalSum);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


    fetchData();
  }, [getStudentCountByClass, getCourseCount, getTeacherCount, getLastFiveStudents, getTotalSum]);

  const colors = [
    'bg-primary',
    'bg-secondary',
    'bg-success',
    'bg-danger',
    'bg-warning',
    'bg-info',
    'bg-dark',
    'bg-secondary',
    'bg-success-subtle'
  ];

  return (
    <div className="wrapper">
      {/* <div className="preloader flex-column justify-content-center align-items-center">
  <img className="animation__wobble" src={Logo} alt="AdminLTELogo" height="60" width="60"/>
</div> */}


      <Sidebar />
      <div className="content-wrapper">
        <div className="content-header mt-3">
          <div className="container-fluid">
            <div className="row mb-2 mt-3">
              <div className="col-sm-6">
                <h1 className="m-0">Tableau de Bord</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">LNFCL</a></li>
                  <li className="breadcrumb-item active">Tableau de Bord</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid col-12">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box">
                  <span className="info-box-icon bg-info elevation-1"><i class="fas fa-user-graduate"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">ETUDIANTS</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>
                          {studentCount}
                        </>
                      )}
                      <small> Étudiants</small>
                    </span>
                    <span className="info-box-number pb-0">
                      <small className='mb-0 p-0'>Dernière mise a jours</small><br className='m-0' />
                      <small className='mb-0 mt-0 text-info text-small fw-bold'>{getFormattedDate()}</small>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 ">
                <div className="info-box mb-3">
                  <span className="info-box-icon bg-danger elevation-1"><i class="fas fa-chalkboard-teacher"></i></span>
                  <div className="info-box-content">
                    <span className="info-box-text">PROFESSEURS</span>
                    <span className="info-box-number">{loading ? (
                      <>
                        <i className="fas fa-spinner fa-spin"></i>{" "}
                      </>
                    ) : (
                      <>
                        {teacherCount}
                      </>
                    )}
                      <small> Professeurs</small>
                    </span>
                    <span className="info-box-number pb-0">
                      <small className='mb-0'>Dernière mise a jours</small><br />
                      <small className='mb-0 mt-0 text-danger text-small fw-bold'>{getFormattedDate()}</small>
                    </span>
                  </div>
                </div>
              </div>


              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box mb-3">
                  <span className="info-box-icon bg-success elevation-1"><i class="fas fa-book"></i></span>
                  <div className="info-box-content">
                    <span className="info-box-text">COURS</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>
                          {courseCount}
                        </>
                      )}
                      <small> Cours</small>
                    </span>
                    <span className="info-box-number pb-0">
                      <small className='mb-0 '>Dernière mise a jours</small><br />
                      <small className='mb-0 mt-0 text-success text-small fw-bold'>{getFormattedDate()}</small>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box mb-3">
                  <span className="info-box-icon bg-warning elevation-1"><i class="fas fa-file-invoice-dollar"></i></span>
                  <div className="info-box-content">
                    <span className="info-box-text">CAISSE</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>
                          {totalSum.total_amount}
                        </>
                      )}
                      <small> Gourdes</small>
                    </span>
                    <span className="info-box-number pb-0 ">
                      <small className='mb-0 '>Dernière mise a jours</small><br />
                      <small className='mb-0 mt-0 text-warning text-small fw-bold'>{getFormattedDate()}</small>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Statistiques</h5>

                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse">
                        <i className="fas fa-minus"></i>
                      </button>
                      <div className="btn-group">
                        <button type="button" className="btn btn-tool dropdown-toggle" data-toggle="dropdown">
                          <i className="fas fa-wrench"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" role="menu">
                          <a href="#" className="dropdown-item">Action</a>
                          <a href="#" className="dropdown-item">Another action</a>
                        </div>
                      </div>
                      <button type="button" className="btn btn-tool" data-card-widget="remove">
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <p className="text-center">
                          <strong>Nombre d'etudiants | Classes</strong>
                        </p>
                      </div>
                      <div className="col-md-6">

                        {studentCountByClass
                          .filter((_, index) => index % 2 === 0)
                          .map((item, index) => (
                            <div className="progress-group" key={index}>
                              {loading ? (
                                <>
                                  <i className="fas fa-spinner fa-spin"></i>{" "}
                                </>
                              ) : (
                                <>
                                  {`${item.classe__niveau} ${item.classe__classe}`}
                                </>
                              )}
                              <span className="float-right">
                                <b>{item.student_count}</b> | {((item.student_count * 100) / studentCount).toFixed(1)}%
                              </span>
                              <div className="progress progress-sm">
                                <div className={`progress-bar ${colors[index % colors.length]}`} style={{ width: `${((item.student_count * 100) / studentCount).toFixed(1)}%` }}></div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="col-md-6">
                        {studentCountByClass
                          .filter((_, index) => index % 2 !== 0)
                          .map((item, index) => (
                            <div className="progress-group" key={index}>
                              {loading ? (
                                <>
                                  <i className="fas fa-spinner fa-spin"></i>{" "}
                                </>
                              ) : (
                                <>
                                  {`${item.classe__niveau} ${item.classe__classe}`}
                                </>
                              )}
                              <span className="float-right">
                                <b>{item.student_count}</b> | {((item.student_count * 100) / studentCount).toFixed(1)}%
                              </span>
                              <div className="progress progress-sm">
                                <div className={`progress-bar ${colors[index % colors.length + 4]}`} style={{ width: `${((item.student_count * 100) / studentCount).toFixed(1)}%` }}></div>
                              </div>
                            </div>
                          ))}
                      </div>

                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="row">
                      <div className="col-sm-3 col-6">
                        <div className="description-block border-right">
                          <span className="description-percentage text-success"><i className="fas fa-caret-up"></i> +</span>
                          <h5 className="description-header">
                            {loading ? (
                              <>
                                <i className="fas fa-spinner fa-spin"></i>{" "}
                              </>
                            ) : (
                              <>
                                {studentCountByNiveau.NSI}
                              </>
                            )}
                            <small>élèves</small></h5>
                          <span className="description-text">TOTAL NSI</span>
                        </div>
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="description-block border-right">
                          <span className="description-percentage text-warning"><i className="fas fa-caret-left"></i> -</span>
                          <h5 className="description-header">
                            {loading ? (
                              <>
                                <i className="fas fa-spinner fa-spin"></i>{" "}
                              </>
                            ) : (
                              <>
                                {studentCountByNiveau.NSII}
                              </>
                            )}
                            <small>élèves</small></h5>
                          <span className="description-text">TOTAL NSII</span>
                        </div>
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="description-block border-right">
                          <span className="description-percentage text-success"><i className="fas fa-caret-up"></i> +</span>
                          <h5 className="description-header">
                            {loading ? (
                              <>
                                <i className="fas fa-spinner fa-spin"></i>{" "}
                              </>
                            ) : (
                              <>
                                {studentCountByNiveau.NSIII}
                              </>
                            )}
                            <small>élèves</small></h5>
                          <span className="description-text">TOTAL NSIII</span>
                        </div>
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="description-block">
                          <span className="description-percentage text-danger"><i className="fas fa-caret-down"></i> -</span>
                          <h5 className="description-header">
                            {loading ? (
                              <>
                                <i className="fas fa-spinner fa-spin"></i>{" "}
                              </>
                            ) : (
                              <>
                                {studentCountByNiveau.NSIV}
                              </>
                            )}
                            <small>élèves</small></h5>
                          <span className="description-text">TOTAL NSIV</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="row">

                </div>

                <div className="card">
                  <div className="card-header border-transparent">
                    <h3 className="card-title">Dernière Enregistrements</h3>

                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse">
                        <i className="fas fa-minus"></i>
                      </button>
                      <button type="button" className="btn btn-tool" data-card-widget="remove">
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table className="table m-0">
                        <thead>
                          <tr>
                            <th>Matricule</th>
                            <th>Nom Complet</th>
                            <th>Niveau</th>
                            <th>Classe</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <>
                              <i className="fas fa-spinner fa-spin"></i>{" "}
                            </>
                          ) : (
                            <>
                              {lastFiveStudents.map((student, index) => (
                                <tr key={index}>
                                  <td><a href=''>{student.matricule}</a></td>
                                  <td>{student.nom} {student.prenom}</td>
                                  <td><span className="badge badge-success">{student.niveau}</span></td>
                                  <td>{student.classe_nom}</td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="card-footer clearfix">
                    <a href="#" className="btn btn-sm btn-info float-left">-</a>
                    <a href="/students" className="btn btn-sm btn-secondary float-right">Voir la Liste des élèves</a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="info-box mb-3 bg-primary">
                  <span className="info-box-icon"><i className="fas fa-cloud-download-alt"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">Total élèves Inscrit</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>
                          {inscCount}
                        </>
                      )}
                    </span>
                  </div>

                </div>
                <div className="info-box mb-3 bg-warning">
                  <span className="info-box-icon"><i class="fas fa-money-check-alt"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">Montant Inscription</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>
                          {totalSum.total_insc_pay}
                        </>
                      )}
                    </span>
                  </div>
                </div>
                <div className="info-box mb-3 bg-success">
                  <span className="info-box-icon"><i class="fas fa-file-invoice-dollar"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">Montant Paiement</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>
                          {totalSum.total_std_pay}
                        </>
                      )}
                    </span>
                  </div>
                </div>
                <div className="info-box mb-3 bg-info">
                  <span className="info-box-icon"><i className="far fa-comment"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">Inscriptions Validés</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>
                          {VInscCount}
                        </>
                      )} </span>
                  </div>

                </div>
                <div className="info-box mb-3 bg-dark">
                  <span className="info-box-icon"><i className="far fa-comment"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">Nombre d'élèves payé</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>{VstudentCount} </>
                      )}</span>
                  </div>

                </div>
                <div className="info-box mb-3 bg-danger">
                  <span className="info-box-icon"><i className="far fa-comment"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">Nombre d'élèves non payé</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>{UvstudentCount}
                        </>
                      )}</span>
                  </div>

                </div>


              </div>

            </div>



          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default Dashboard