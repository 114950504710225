import React from 'react'
import { Helmet } from 'react-helmet'

const Services = () => {
  return (
    <>
      <Helmet>
        <title>Nos Activités - Lycée François Cappoix de Limonade</title>
        <meta name="description" content="Découvrez les différentes activités proposées au Lycée François Cappoix de Limonade : clubs culturels, activités sportives, soutien académique, et plus." />
        <meta name="keywords" content="activités scolaires, clubs culturels, sport, restauration, soutien académique, Lycée François Cappoix de Limonade" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="container-xxl py-5" id="services">
        <div className="container">
          <div className="row g-4">
            {/* Titre principal H1 */}
            <h1 className="display-5 mb-5 wow fadeInLeft" data-wow-delay="0.3s">Nos Activités</h1>

            {/* Sous-titre H2 */}
            <h2 className="display fw-500 mb-5">Une variété d'activités pour enrichir l'expérience scolaire</h2>

            {/* Premier service */}
            <div className="col-lg-3 col-sm-6 wow fadeInRight" data-wow-delay="0.4s">
              <div className="facility-item">
                <div className="facility-icon bg-primary">
                  <span className="bg-primary"></span>
                  <i className="fas fa-brain fa-3x text-primary"></i>
                  <span className="bg-primary"></span>
                </div>
                <div className="facility-text bg-primary">
                  <h3 className="text-primary mb-3">Clubs Culturels</h3>
                  <p className="mb-0 text-dark">Débat, théâtre et arts visuels pour enrichir l'expérience scolaire.</p>
                </div>
              </div>
            </div>

            {/* Deuxième service */}
            <div className="col-lg-3 col-sm-6 wow fadeInLeft" data-wow-delay="0.3s">
              <div className="facility-item">
                <div className="facility-icon bg-success">
                  <span className="bg-success"></span>
                  <i className="fa fa-futbol fa-3x text-success"></i>
                  <span className="bg-success"></span>
                </div>
                <div className="facility-text bg-success">
                  <h3 className="text-success mb-3">Activités Sportives</h3>
                  <p className="mb-0 text-dark">Équipes sportives et programmes de fitness pour tous les niveaux.</p>
                </div>
              </div>
            </div>

            {/* Troisième service */}
            <div className="col-lg-3 col-sm-6 wow fadeInRight" data-wow-delay="0.5s">
              <div className="facility-item">
                <div className="facility-icon bg-warning">
                  <span className="bg-warning"></span>
                  <i className="fa fa-utensils fa-3x text-warning"></i>
                  <span className="bg-warning"></span>
                </div>
                <div className="facility-text bg-warning">
                  <h3 className="text-warning mb-3">Restauration Équilibrée</h3>
                  <p className="mb-0 text-dark">Repas sains pour soutenir la concentration et l'énergie.</p>
                </div>
              </div>
            </div>

            {/* Quatrième service */}
            <div className="col-lg-3 col-sm-6 wow fadeInLeft" data-wow-delay="0.7s">
              <div className="facility-item">
                <div className="facility-icon bg-info">
                  <span className="bg-info"></span>
                  <i className="fa fa-book fa-3x text-info"></i>
                  <span className="bg-info"></span>
                </div>
                <div className="facility-text bg-info">
                  <h3 className="text-info mb-3">Soutien Académique</h3>
                  <p className="mb-0 text-dark">Ressources et ateliers pour améliorer vos compétences académiques.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Services
