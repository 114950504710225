import React from 'react';
import { Helmet } from 'react-helmet';

function Footer() {
  return (
    <>
      <Helmet>
        {/* Ajouter des informations pertinentes pour le SEO */}
        <meta name="description" content="Pied de page de LNFCL, contenant des informations de version et des droits d'auteur." />
        <meta name="keywords" content="footer, LNFCL, version, droits réservés, Limonade" />
      </Helmet>
      <footer className="main-footer" aria-label="Pied de page">
        <div className="footer-content">
          <div className="float-right d-none d-sm-block">
            <small><b>Version</b> 1.0.0</small>
          </div>
          <small>
            <strong>&copy; 2024-2025 <a href="https://lnfcl.com" target="_blank" rel="noopener noreferrer">LNFCL</a>.</strong> Tous droits réservés.
          </small>
          <address className="mt-3 d-none">
            <p>Contactez-nous : <a href="mailto:info.lnfcl@gmail.com">info.lnfcl@gmail.com</a></p>
            <p>Adresse : Limonade, Haïti</p>
          </address>
        </div>
      </footer>
    </>
  );
}

export default Footer;
