/* eslint-disable */
import Footer from "../../Includes/Footer";
import Sidebar from "../../Includes/Sidebar";
import { Link } from "react-router-dom";
import { StudentContext } from "../../context/StudentContext";
import { useContext, useEffect, useState } from "react";
import { ExportDropdown, customStyles } from "../Data/Utility";
import { Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { PaiementContext } from "../../context/PaiementContext";
import { DataPaid, DataPaidInsc } from "../Data/DataPaid";
import "jspdf-autotable"; // Importez jspdf-autotable
import jsPDF from "jspdf";
// import axios from 'axios';
import { AdminContext } from "../../context/AdminContext";
// const baseUrl = 'http://127.0.0.1:8000/api/students/';

export default function Paiements() {
  const { getStudentsNonPaye } = useContext(StudentContext);
  const { createPaiement } = useContext(PaiementContext);
  const { getAnneeAc } = useContext(AdminContext);
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [records1, setRecords1] = useState([]);
  const [loading, setLoading] = useState(false); // État de chargement
  const [selectedClasse, setSelectedClasse] = useState("Toutes les classes"); // État pour suivre la classe sélectionnée

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getStudentsNonPaye();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        const data1 = await getAnneeAc();
        setRecords1(data1);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [getStudentsNonPaye, getAnneeAc]);

  const record = records1.length > 0 ? records1[0] : null;

  const columnsInscription = [
    {
      name: "Matricule",
      selector: (row) => row.matricule,
      sortable: true,
    },
    {
      name: "Nom Complet",
      selector: (row) => (
        <div className="d-flex align-items-center">
          <img
            src={row.photo}
            alt="profile"
            style={{
              width: 30,
              height: 30,
              borderRadius: "50%",
              marginRight: 5,
            }}
          />
          {row?.nom + " " + row.prenom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group" role="group">
          <button
            type="submit"
            className="btn btn-primary btn-sm"
            disabled={loading}
            onClick={() => {
              handleAddPaiement(row.matricule);
              handlePrint(row);
            }}
          >
            {loading ? (
              <>
                <i className="fas fa-spinner fa-spin"></i> Paiement...
              </>
            ) : (
              <>
                <i className="fas fa-save"></i> Payé
              </>
            )}
          </button>
        </div>
      ),
    },
  ];
  function handleAddPaiement(matricule) {
    let formData = new FormData();
    formData.append("matricule", matricule);

    setLoading(true);
    try {
      matricule.length > 0 && createPaiement(formData);
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Remettre loading à false après la fin du processus
    }
  }

  const handlePrint = async (row) => {
    await generatePDF(row, record.date);

    window.location.reload();
  };
  const generatePDF = async (data, date) => {
    const doc = new jsPDF();
    // Ajouter l'image en arrière-plan
    doc.addImage("/img/filig.png", "PNG", 0, 0, 210, 297);

    // Logo
    doc.addImage("/img/header.png", "PNG", 0, 0, 212, 48);

    // Ligne horizontale
    doc.setLineWidth(0.4);
    doc.line(0, 48, 210, 48);

    // Texte en gras "Fiche d'inscription"
    doc.setFont("times", "bold");
    doc.setFontSize(16);
    doc.text("Reçu", 105, 55, { align: "center" });

    // Créer une table pour les informations d'inscription
    const tableData = [
      [
        {
          content: "Matricule :",
          styles: { fontStyle: "bold", halign: "left" },
        },
        { content: data.matricule, styles: { halign: "left" } },
      ],
      [
        { content: "Nom :", styles: { fontStyle: "bold", halign: "left" } },
        { content: data.nom, styles: { halign: "left" } },
      ],
      [
        { content: "Prenom:", styles: { fontStyle: "bold", halign: "left" } },
        { content: data.prenom, styles: { halign: "left" } },
      ],
      [
        { content: "Adresse:", styles: { fontStyle: "bold", halign: "left" } },
        { content: data.adresse, styles: { halign: "left" } },
      ],
      [
        {
          content: "Téléphone:",
          styles: { fontStyle: "bold", halign: "left" },
        },
        { content: data.telephone, styles: { halign: "left" } },
      ],
      // [{ content: "Email:", styles: { fontStyle: 'bold', halign: 'left' } }, { content: data.email, styles: { halign: 'left' } }]
    ];

    // Générer la table
    doc.autoTable({
      startY: 65,
      head: [""],
      body: tableData,
      margin: { left: 60 },
      styles: {
        cellPadding: 2,
        fontSize: 12,
        font: "times", // Appliquer la police Times New Roman à la table
      },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: "auto" },
      },
      theme: "plain",
    });

    // Texte "Année académique 2024-2025" en haut à droite de la table
    doc.setFont("times", "normal");
    doc.setFontSize(10);
    doc.text(`Année académique ${date}`, 105, 65, { align: "center" });

    // // Ligne pour la signature
    // doc.setLineWidth(0.4);
    // doc.line(150, 200, 200, 200); // Modifier les coordonnées Y selon vos besoins

    // // Texte "Signature" en bas à droite de la ligne
    // doc.text("Signature", 175, 205, { align: "center" });

    // const pdfBlob = doc.output('blob');
    // const formData = new FormData();
    // formData.append('pdf', pdfBlob, `${data.nom}_${data.prenom}_paiements.pdf`);
    // formData.append('classe_nom', data.classe_nom);

    // try {
    //   const response = await axios.post(`${baseUrl}save-paiements-pdf/`, formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     }
    //   });
    //   if (response.status === 200) {
    //     // Ouvrir le PDF dans une nouvelle fenêtre sans le télécharger à nouveau
    //     const url = URL.createObjectURL(pdfBlob);
    //     window.open(url, '_blank');

    //     // Cleanup
    //     setTimeout(() => {
    //       URL.revokeObjectURL(url);
    //     }, 100);
    //   }
    //   console.log(response.data);
    // } catch (error) {
    //   console.error('Erreur lors de l\'envoi du PDF:', error);
    // }
    // Sauvegarder le PDF
    doc.save(`Fiche-${data.matricule}-${data.annee_academique}.pdf`);

    // Ouvrir la fenêtre d'impression
    doc.autoPrint();
  };

  function handleFilter(event) {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const matriculeLower = row.matricule.toLowerCase();
      const nomLower = row.nom.toLowerCase();
      const prenomLower = row.prenom.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) ||
        prenomLower.includes(searchLower) ||
        matriculeLower.includes(searchLower)
      );
    });

    setRecords(newData);
  }
  const refreshData = async () => {
    try {
      const data = await getInscriptions();
      setOriginalRecords(data);
      setRecords(data);
    } catch (error) {
      console.error(error);
    }
  };
  function handleFilterByClasse(classe_nom) {
    setSelectedClasse(classe_nom);
    if (classe_nom === "Toutes les classes") {
      setRecords(originalRecords);
    } else {
      const newData = originalRecords.filter(
        (row) => row.classe_nom.toLowerCase() === classe_nom.toLowerCase()
      );
      setRecords(newData);
    }
  }
  return (
    <>
      <Sidebar />
      <div className="content-wrapper">
        <section className="content-header mt-3">
          <div className="container-fluid">
            <div className="row mb-2 mt-3">
              <div className="col-sm-6">
                <h1 className="display">Gestion Paiement Élèves</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <Link to="/dashboard" className="breadcrumb-item">
                    <a href="#">Tableau de bord</a>
                  </Link>
                  <li className="breadcrumb-item active">Paiements</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Liste des élèves</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus"></i>
                    </button>
                  </div>
                </div>
                <div className="card-body p-0 m-0 pt-1">
                  <div
                    className="container-lg wow fadeIn "
                    data-wow-delay="0.1s "
                  >
                    <div className="row">
                      <div className="col-md-6 text-start d-flex align-items-center">
                        <ExportDropdown
                          id="dropdown-item-button"
                          title={`Trier par ${selectedClasse}`}
                          menualign="right"
                          variant="outline-primary btn btn-primary btn-sm mb-0"
                        >
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Toutes les classes")
                            }
                          >
                            Toutes les classes
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Antenor Firmin")
                            }
                          >
                            NSI Antenor Firmin
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Oswald Durand")
                            }
                          >
                            NSI Oswald Durand
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Jacques Stephen Alexis")
                            }
                          >
                            NSI Jacques Stephen Alexis
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Demesvar Delorme")
                            }
                          >
                            NSII Demesvar Delorme
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Jean Price Mars")
                            }
                          >
                            NSII Jean Price Mars
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Jean Jacques Dessalines")
                            }
                          >
                            NSII Jean Jacques Dessalines
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Etzer Vilaire")
                            }
                          >
                            NSIII Etzer Vilaire
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Catherine Flon")
                            }
                          >
                            NSIII Catherine Flon
                          </Dropdown.Item>
                        </ExportDropdown>
                      </div>

                      <div className="col-md-6 ">
                        <input
                          className="form-control mb-2"
                          type="text"
                          placeholder="Search"
                          onChange={handleFilter}
                        />
                      </div>

                      <div className="">
                        <DataTable
                          columns={columnsInscription}
                          data={records}
                          fixedHeader
                          pagination
                          customStyles={customStyles}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                          paginationComponentOptions={{
                            rowsPerPageText: "Lignes par page:",
                            rangeSeparatorText: "sur",
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: "Tous",
                          }}
                          subHeader
                          noDataComponent="Aucune donnée trouvée"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Liste des Paiements</h3>

                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus"></i>
                    </button>
                  </div>
                </div>
                <div className="card-body p-0 m-0">
                  <DataPaid />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="card card-success">
                <div className="card-header">
                  <h3 className="card-title">
                    Liste des Paiements Inscriptions
                  </h3>

                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus"></i>
                    </button>
                  </div>
                </div>
                <div className="card-body p-0 m-0">
                  <DataPaidInsc />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
