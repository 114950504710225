/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { ExportDropdown, customStyles } from "./Utility";
import { Dropdown } from "react-bootstrap";
import Loader from '../../Includes/loader/loader';
import { PaiementContext } from '../../context/PaiementContext';
const swal = require('sweetalert2');

const DataPaid = () => {
    const { getPaiements } = useContext(PaiementContext); // Récupérez la fonction getPaiements du contexte d'authentification
    const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
    const [records, setRecords] = useState([]);
    const [selectedClasse, setSelectedClasse] = useState('Toutes les classes'); 
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getPaiements();
                setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
                setRecords(data);
                setLoading(false); 
              } catch (error) {
                setLoading(false);
                console.error(error);
            }
        };
        fetchData();
    }, [getPaiements]);
    if (loading) {
        return <Loader />; // Affiche le loader tant que les données sont en cours de chargement
    }
    const columnsCourses = [
        {
            name: "Matricule",
            selector: (row) => row.student_matricule,
            sortable: true,
        },
        {
            name: "Année Académique",
            selector: (row) => row.annee_academique,
            sortable: true,
        },
        {
            name: "montant",
            selector: (row) => (
                <div className="d-flex align-items-start">
                    {row.montant}
                </div>
            ),
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="btn-group" role="group">
                    <Link to={`/paiement-detail`}  state={{paie_id: row.id }} className="btn btn-success btn-sm me-1">
                        <i className="fas fa-eye"></i> Details
                    </Link>
                </div>
            ),
        },
    ];
    const handleFilter = (event) => {
        const { value } = event.target;
        if (value === "") {
            setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
            return;
        }
        const newData = originalRecords.filter((row) => {
            const nomLower = row.student_matricule.toLowerCase();
            const codeLower = row.annee_academique.toLowerCase();
            const searchLower = value.toLowerCase();
            return nomLower.includes(searchLower) || codeLower.includes(searchLower);
        });

        setRecords(newData);
    };

    return (
        <>
            <div className="container-lg wow fadeIn" data-wow-delay="0.1s">
                <div className="row">
                    <div className="col-md-12 mt-1">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Search"
                            onChange={handleFilter}
                        />
                    </div>

                    <div className="col-md-12">
                   
                            <DataTable
                                columns={columnsCourses}
                                data={records}
                                fixedHeader
                                pagination
                                customStyles={customStyles}
                                paginationPerPage="10"
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                                paginationComponentOptions={{
                                    rowsPerPageText: "Lignes par page:",
                                    rangeSeparatorText: "sur",
                                    noRowsPerPage: false,
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: "Tous",
                                }}
                                subHeader
                                noDataComponent="Aucune donnée trouvée"
                            />
                    </div>
                </div>
            </div>
        </>
    );
};
const DataPaidInsc = () => {
    const { getPaiementsInsc } = useContext(PaiementContext); // Récupérez la fonction getPaiements du contexte d'authentification
    const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
    const [records, setRecords] = useState([]);
    const [selectedClasse, setSelectedClasse] = useState('Toutes les classes'); 
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getPaiementsInsc();
                setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
                setRecords(data);
                setLoading(false); 
              } catch (error) {
                setLoading(false);
                console.error(error);
            }
        };
        fetchData();
    }, [getPaiementsInsc]);
    if (loading) {
        return <Loader />; // Affiche le loader tant que les données sont en cours de chargement
    }
    const columnsCourses = [
        {
            name: "Matricule",
            selector: (row) => row.student_matricule,
            sortable: true,
        },
        {
            name: "Année Académique",
            selector: (row) => row.annee_academique,
            sortable: true,
        },
        {
            name: "montant",
            selector: (row) => (
                <div className="d-flex align-items-start">
                    {row.montant}
                </div>
            ),
            sortable: true,
        },
        {
            name: "Date Enregistrement",
            selector: (row) => row.date,
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="btn-group" role="group">
                    <Link to={`/paiement-insc-detail`}  state={{paie_id: row.id }}  className="btn btn-success btn-sm me-1">
                        <i className="fas fa-eye"></i> Details
                    </Link>
                </div>
            ),
        },
    ];
    const handleFilter = (event) => {
        const { value } = event.target;
        if (value === "") {
            setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
            return;
        }
        const newData = originalRecords.filter((row) => {
            const nomLower = row.student_matricule.toLowerCase();
            const codeLower = row.annee_academique.toLowerCase();
            const searchLower = value.toLowerCase();
            return nomLower.includes(searchLower) || codeLower.includes(searchLower);
        });

        setRecords(newData);
    };

    return (
        <>
            <div className="container-lg wow fadeIn" data-wow-delay="0.1s">
                <div className="row">
                    <div className="col-md-12 mt-1">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Search"
                            onChange={handleFilter}
                        />
                    </div>

                    <div className="col-md-12">
                   
                            <DataTable
                                columns={columnsCourses}
                                data={records}
                                fixedHeader
                                pagination
                                customStyles={customStyles}
                                paginationPerPage="10"
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                                paginationComponentOptions={{
                                    rowsPerPageText: "Lignes par page:",
                                    rangeSeparatorText: "sur",
                                    noRowsPerPage: false,
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: "Tous",
                                }}
                                subHeader
                                noDataComponent="Aucune donnée trouvée"
                            />
                    </div>
                </div>
            </div>
        </>
    );
};

export {DataPaid,DataPaidInsc};
