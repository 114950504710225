import  { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Footer from '../../Includes/Footer'
import Sidebar from '../../Includes/Sidebar'
import { Link } from 'react-router-dom';
import { FormInscription } from '../Data/FormStudent';
import {DataInscription} from '../Data/DataStudent';

export default function Inscription() {
  const [showModal, setShowModal] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  // eslint-disable-next-line

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the form from submitting
    setShowModal(true); // Show the modal
  };


  const closeModal = () => {
    setShowModal(false); // Hide the modal
    setReloadData(prev => !prev); // Toggle the state to reload data
  };
  

  return (
   
    <div className="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
    <div className="wrapper">
      <Sidebar />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 mt-3">
            <div className="col-sm-6 d-flex align-items-center">
                <div className="bg-blue rounded-lg p-2 text-center">
                  <i className='fas fa-user-plus'></i>
                </div>
                <div className="ml-2"><h3 className='display fw-12'>Gestion des Inscriptions</h3></div>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/dashboard">Tableau de bord</Link></li>
                  <li className="breadcrumb-item active">Inscriptions</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="clearfix hidden-md-up"></div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Liste des élèves inscrits</h3>

                    <div className="card-tools">
                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn btn-success btn-sm"
                      >
                        <i className="fas fa-user-plus"></i> Inscription
                      </button>
                    </div>
                  </div>

                  <div className="card-body p-0 m-0">
                  <DataInscription reloadData={reloadData} />
                  </div>

                  <div className="card-footer text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
           <Modal className='mt-0' show={showModal} onHide={closeModal} size="xl" top>
     <Modal.Header closeButton>
       <Modal.Title>Formulaire d'enregistrement</Modal.Title>
     </Modal.Header>
     <Modal.Body>
         <FormInscription/>
     </Modal.Body>
     <Modal.Footer>
       <Button variant="btn btn-danger btn-sm" onClick={closeModal}><i className='fas fa-trash'></i> Fermer</Button>
       </Modal.Footer>
   </Modal>
      </div>
    </div>
  </div>
  )
}

