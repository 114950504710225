import { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../Includes/Footer";
import Sidebar from "../../Includes/Sidebar";
import { StudentContext } from "../../context/StudentContext";
import AuthContext from "../../context/AuthContext";
import Loader from "../../Includes/loader/loader";

function StudentEdit() {
  const { getClasses, getStudentById, updateStudent } =
    useContext(StudentContext);
  const { user } = useContext(AuthContext);
  const [records, setRecords] = useState();
  const [classes, setClasses] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageView, setProfileImageView] = useState(null);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [loading1, setLoading1] = useState(true);
  const location = useLocation();
  const { student_id } = location.state || {};
  const [loading, setLoading] = useState(false); // État de chargement

  useEffect(() => {
    const userId = student_id;
    getStudentById(userId).then((data) => {
      setRecords(data); // Mettez à jour records avec les données récupérées
      setSelectedLevel(data.niveau);
    });
    const fetchClasses = async () => {
      try {
        const classesData = await getClasses();
        setClasses(classesData);
        setLoading1(false);
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };
    fetchClasses();
  }, [getStudentById, student_id, getClasses]);

  useEffect(() => {
    if (selectedLevel) {
      const filtered = classes.filter(
        (classe) => classe.niveau === selectedLevel
      );
      setFilteredClasses(filtered);
    } else {
      setFilteredClasses([]);
    }
  }, [selectedLevel, classes]);

  const handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setProfileImage(file); // Mettre à jour profileImage avec le fichier
      const imageUrl = URL.createObjectURL(file);
      setProfileImageView(imageUrl);
    }
  };

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
    setRecords({ ...records, niveau: event.target.value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("created_by", user.user_id);

    if (profileImage) {
      formData.append("photo", profileImage);
    }

    setLoading(true); // Activer le loader

    try {
      await updateStudent(records.id, formData); // Assure-toi que updateStudent renvoie une promesse
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Désactiver le loader après la fin de l'opération
    }
  };

  return (
    <div className="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2 mt-3">
                <div className="col-sm-6 d-flex align-items-center">
                  <div className="bg-blue rounded-lg p-2 text-center">
                    <i className="fas fa-user"></i>
                  </div>
                  <div className="ml-2">
                    <h3 className="display fw-12">Detail Élève</h3>
                  </div>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      <Link to="/students">Élèves</Link>
                    </li>
                    <li className="breadcrumb-item active">Detail</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="clearfix hidden-md-up"></div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Informations de l'élève</h3>

                      <div className="card-tools">
                        <Link className="btn btn-success btn-sm" to="/students">
                          <i className="fas fa-list"></i> Liste des élèves
                        </Link>
                      </div>
                    </div>

                    <div className="card-body p-0 m-0">
                      {loading1 ? (
                        <Loader />
                      ) : (
                        <form
                          onSubmit={handleSubmit}
                          className="forms-sample"
                          encType="multipart/form-data"
                        >
                          <div className="row">
                            <div className="card-header">
                              <img
                                src={
                                  profileImageView ||
                                  (records && records.photo) ||
                                  ""
                                }
                                id="profil"
                                alt="profil"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  borderRadius: "50px",
                                }}
                              />
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="name">Nom</label>
                                    <input
                                      type="text"
                                      value={records?.nom || ""}
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          nom: e.target.value,
                                        })
                                      }
                                      className="form-control"
                                      id="name"
                                      placeholder="Nom de l'élève"
                                      name="nom"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="prenom">Prénom</label>
                                    <input
                                      type="text"
                                      value={records?.prenom || ""}
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          prenom: e.target.value,
                                        })
                                      }
                                      className="form-control"
                                      id="name"
                                      placeholder="Nom de l'élève"
                                      name="prenom"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="exampleSelectGender">
                                      Sexe
                                    </label>
                                    <select
                                      className="form-control"
                                      id="exampleSelectGender"
                                      name="sexe"
                                      value={records?.sexe || ""}
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          sexe: e.target.value,
                                        })
                                      }
                                    >
                                      <option selected readonly></option>
                                      <option value={"M"}>Masculin</option>
                                      <option value={"F"}>Feminin</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="date">Date naissance</label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="date"
                                      value={records?.date_naissance || ""}
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          date_naissance: e.target.value,
                                        })
                                      }
                                      placeholder="Date Naissance"
                                      name="date_naissance"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="Adresse">
                                      Lieu de Naissance
                                    </label>
                                    <input
                                      type="test"
                                      className="form-control"
                                      id="Adresse"
                                      value={records?.lieu_naissance || ""}
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          lieu_naissance: e.target.value,
                                        })
                                      }
                                      placeholder="Adresse"
                                      name="lieu_naissance"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="Adresse">
                                      N˚ d'ordre 9ème AF
                                    </label>
                                    <input
                                      type="test"
                                      className="form-control"
                                      id="Adresse"
                                      value={records?.no_ordre || ""}
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          no_ordre: e.target.value,
                                        })
                                      }
                                      placeholder="Adresse"
                                      name="no_ordre"
                                    />
                                  </div>
                                </div>
                                <div className="form-group col-md-3">
                                  <label htmlFor="exampleInputFile">
                                    Photo
                                  </label>
                                  <div className="input-group">
                                    <div className="custom-file">
                                      <input
                                        type="file"
                                        className="custom-file-input"
                                        id="exampleInputFile"
                                        accept="image/*"
                                        name="image"
                                        onChange={handleImageChange}
                                      />
                                      <label
                                        className="custom-file-label"
                                        htmlFor="exampleInputFile"
                                      >
                                        {profileImage
                                          ? "Image sélectionnée"
                                          : "Sélectionner une image"}
                                      </label>
                                    </div>
                                    <div className="input-group-append">
                                      <span className="input-group-text">
                                        Importer
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="exampleSelectGender">
                                      Niveau
                                    </label>
                                    <select
                                      className="form-control"
                                      id="exampleSelectGender"
                                      name="niveau"
                                      value={records?.niveau || ""}
                                      onChange={handleLevelChange}
                                    >
                                      <option value="" disabled selected>
                                        Choisir un Niveau
                                      </option>
                                      <option value={"NSI"}>NSI</option>
                                      <option value={"NSII"}>NSII</option>
                                      <option value={"NSIII"}>NSIII</option>
                                      <option value={"NSIV"}>NSIV</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="exampleSelectGender">
                                      Classe
                                    </label>
                                    <select
                                      className="form-control"
                                      id="exampleSelectGender"
                                      name="classe"
                                      value={records?.classe || ""}
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          classe: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="" disabled></option>
                                      {filteredClasses.map((classe) => (
                                        <option
                                          key={classe.id}
                                          value={classe.id}
                                        >
                                          {classe.classe}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="Téléphone">
                                      École l'élève a fait 9e
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Téléphone"
                                      value={records?.ecole_9e || ""}
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          ecole_9e: e.target.value,
                                        })
                                      }
                                      placeholder="Ecole ou l'élève a fait 9e"
                                      name="ecole_9e"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="Téléphone">
                                      Année Réussite 9e
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Téléphone"
                                      value={records?.annee_9e || ""}
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          annee_9e: e.target.value,
                                        })
                                      }
                                      placeholder="Année de réussite 9e"
                                      name="annee_9e"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="Téléphone">
                                      Dernier Etablissement
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Téléphone"
                                      value={
                                        records?.dernier_etablissement || ""
                                      }
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          dernier_etablissement: e.target.value,
                                        })
                                      }
                                      placeholder="Dernière Etablissement"
                                      name="dernier_etablissement"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card">
                            <div className="card-header">
                              Informations Parent
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail3">
                                      Nom du père
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleInputEmail3"
                                      placeholder="Email"
                                      value={records?.nom_pere || ""}
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          nom_pere: e.target.value,
                                        })
                                      }
                                      name="nom_pere"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail3">
                                      Nom de la mère
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleInputEmail3"
                                      placeholder="Email"
                                      value={records?.nom_mere || ""}
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          nom_mere: e.target.value,
                                        })
                                      }
                                      name="nom_mere"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="Téléphone">
                                      Personne Responsable
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Téléphone"
                                      placeholder="Parent"
                                      value={records?.parent || ""}
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          parent: e.target.value,
                                        })
                                      }
                                      name="parent"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="Téléphone">Adresse</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Téléphone"
                                      placeholder="Téléphone"
                                      value={records?.adresse || ""}
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          adresse: e.target.value,
                                        })
                                      }
                                      name="adresse"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="Téléphone">Téléphone</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        +509
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="Téléphone"
                                      placeholder="Téléphone"
                                      value={records?.telephone || ""}
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          telephone: e.target.value,
                                        })
                                      }
                                      name="telephone"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-9  text-right">
                                  <label
                                    htmlFor="Téléphone"
                                    className="text-white"
                                  >
                                    .
                                  </label>
                                  <div className="form-group">
                                    <button
                                      type="submit"
                                      className="btn btn-warning btn-sm"
                                      disabled={loading}
                                    >
                                      {loading ? (
                                        <>
                                          <i className="fas fa-spinner fa-spin"></i>{" "}
                                          Modification en cours...
                                        </>
                                      ) : (
                                        <>
                                          <i className="fas fa-save"></i>{" "}
                                          Modifier
                                        </>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>

                    <div className="card-footer text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default StudentEdit;
