import { useContext, useEffect, useState } from "react";
import { StudentContext } from "../../context/StudentContext";
import AuthContext from "../../context/AuthContext";
import jsPDF from "jspdf";
import { UrlFilig1, UrlHeader1 } from "../../context/BaseUrl";

const currentYear = new Date().getFullYear();
const UrlFilig = UrlFilig1;
const UrlHeader = UrlHeader1;

const generatePDF = async (data) => {
  const doc = new jsPDF();
  // Ajouter l'image en arrière-plan
  doc.addImage(`${UrlFilig}`, "PNG", 0, 0, 210, 297);

  // Logo
  doc.addImage(`${UrlHeader}`, "PNG", 0, 0, 212, 48);

  // Ligne horizontale
  doc.setLineWidth(0.4);
  doc.line(0, 48, 210, 48);

  // Texte en gras "Fiche d'inscription"
  doc.setFont("times", "bold");
  doc.setFontSize(16);
  doc.text("Fiche d'inscription", 105, 55, { align: "center" });

  // Créer une table pour les informations d'inscription
  const tableData = [
    [
      { content: "Matricule :", styles: { fontStyle: "bold", halign: "left" } },
      { content: data.matricule, styles: { halign: "left" } },
    ],
    [
      { content: "Nom :", styles: { fontStyle: "bold", halign: "left" } },
      { content: data.nom, styles: { halign: "left" } },
    ],
    [
      { content: "Prenom:", styles: { fontStyle: "bold", halign: "left" } },
      { content: data.prenom, styles: { halign: "left" } },
    ],
    [
      { content: "Adresse:", styles: { fontStyle: "bold", halign: "left" } },
      { content: data.adresse, styles: { halign: "left" } },
    ],
    [
      { content: "Téléphone:", styles: { fontStyle: "bold", halign: "left" } },
      { content: data.telephone, styles: { halign: "left" } },
    ],
    // [{ content: "Email:", styles: { fontStyle: 'bold', halign: 'left' } }, { content: data.email, styles: { halign: 'left' } }]
  ];

  // Générer la table
  doc.autoTable({
    startY: 65,
    head: [""],
    body: tableData,
    margin: { left: 60 },
    styles: {
      cellPadding: 2,
      fontSize: 12,
      font: "times", // Appliquer la police Times New Roman à la table
    },
    columnStyles: {
      0: { cellWidth: 40 },
      1: { cellWidth: "auto" },
    },
    theme: "plain",
  });

  // Texte "Année académique 2024-2025" en haut à droite de la table
  doc.setFont("times", "normal");
  doc.setFontSize(10);
  doc.text("Année académique 2024-2025", 105, 65, { align: "center" });

  // Ligne pour la signature
  doc.setLineWidth(0.4);
  doc.line(150, 200, 200, 200); // Modifier les coordonnées Y selon vos besoins

  // Texte "Signature" en bas à droite de la ligne
  doc.text("Signature", 175, 205, { align: "center" });

  // Sauvegarder le PDF
  doc.save(`Insc-${data.matricule}-${currentYear}.pdf`);

  // Ouvrir la fenêtre d'impression
  doc.autoPrint();
  window.location.reload();
};
// Form Student
export const FormInscription = () => {
  const { getClasses, createInscription } = useContext(StudentContext);
  const [profileImage, setProfileImage] = useState(null);
  const { user } = useContext(AuthContext);
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [loading, setLoading] = useState(false); // État de chargement

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const classesData = await getClasses();
        setClasses(classesData);
        // console.log(classesData);
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };
    fetchClasses();
  }, [getClasses]);
  useEffect(() => {
    if (selectedLevel) {
      const filtered = classes.filter(
        (classe) => classe.niveau === selectedLevel
      );
      setFilteredClasses(filtered);
    } else {
      setFilteredClasses([]);
    }
  }, [selectedLevel, classes]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const nom = e.target.nom.value;
    const prenom = e.target.prenom.value;
    const lieu_naissance = e.target.lieu_naissance.value;
    const sexe = e.target.sexe.value;
    const date_naissance = e.target.date_naissance.value;
    const no_ordre = e.target.no_ordre.value;
    const ecole_9e = e.target.ecole_9e.value;
    const annee_9e = e.target.annee_9e.value;
    const adresse = e.target.adresse.value;
    const telephone = e.target.telephone.value;
    const classe = e.target.classe.value;
    const niveau = e.target.niveau.value;
    const dernier_etablissement = e.target.dernier_etablissement.value;
    const nom_pere = e.target.nom_pere.value;
    const nom_mere = e.target.nom_mere.value;
    const parent = e.target.parent.value;

    let formData = new FormData();
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("sexe", sexe);
    formData.append("date_naissance", date_naissance);
    formData.append("lieu_naissance", lieu_naissance);
    formData.append("classe", classe);
    formData.append("niveau", niveau);
    formData.append("no_ordre", no_ordre);
    formData.append("ecole_9e", ecole_9e);
    formData.append("annee_9e", annee_9e);
    formData.append("telephone", telephone);
    formData.append("dernier_etablissement", dernier_etablissement);
    formData.append("parent", parent);
    formData.append("adresse", adresse);
    formData.append("nom_pere", nom_pere);
    formData.append("nom_mere", nom_mere);
    formData.append("created_by", user.user_id);

    const imageFile = e.target.image.files[0];
    if (imageFile) {
      formData.append("photo", imageFile);
    }

    // email.length > 0 && email.includes('@') && email.includes('.') &&
    // createInscription(formData);

    setLoading(true);
    try {
      const matricule = await createInscription(formData);
      if (matricule) {
        const studentData = {
          nom,
          prenom,
          adresse,
          telephone,
          classe_nom: classe,
          matricule,
        };

        generatePDF(studentData);
      }
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Remettre loading à false après la fin du processus
    }
  };

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="forms-sample"
      encType="multipart/form-data"
    >
      <div className="row">
        <div className="card">
          <div className="card-header">Informations de l'élève</div>
          <div className="card-body">
            <div className="row">
              {profileImage && (
                <div className="col-md-3">
                  <label htmlFor="profil">Photo</label>
                  <div className="form-group">
                    {/* eslint-disable-next-line */}
                    <img
                      src={profileImage}
                      id="profil"
                      alt="Photo de profil"
                      style={{
                        maxWidth: "80px",
                        maxHeight: "80px",
                        borderRadius: "50px",
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="name">Nom</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Nom de l'élève"
                    name="nom"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="prenom">Prénom</label>
                  <input
                    type="text"
                    className="form-control"
                    id="prenom"
                    placeholder="Prenom de l'élève"
                    name="prenom"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="exampleSelectGender">Sexe</label>
                  <select
                    className="form-control"
                    id="exampleSelectGender"
                    name="sexe"
                  >
                    <option value="" disabled selected>
                      Choisir le sexe
                    </option>
                    <option value={"M"}>Masculin</option>
                    <option value={"F"}>Feminin</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="date">Date naissance</label>
                  <input
                    type="date"
                    className="form-control"
                    id="date"
                    placeholder="Date Naissance"
                    name="date_naissance"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Adresse">Lieu de Naissance</label>
                  <input
                    type="test"
                    className="form-control"
                    id="Adresse"
                    placeholder="Lieu de naissance"
                    name="lieu_naissance"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Adresse">N˚ d'ordre 9ème AF</label>
                  <input
                    type="test"
                    className="form-control"
                    id="Adresse"
                    placeholder="Numero d'ordre 9e"
                    name="no_ordre"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="exampleSelectGender">Niveau</label>
                  <select
                    className="form-control"
                    id="exampleSelectGender"
                    name="niveau"
                    value={selectedLevel}
                    onChange={handleLevelChange}
                  >
                    <option value="" disabled selected>
                      Choisir un Niveau
                    </option>
                    <option value={"NSI"}>NSI</option>
                    <option value={"NSII"}>NSII</option>
                    <option value={"NSIII"}>NSIII</option>
                    <option value={"NSIV"}>NSIV</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="exampleSelectGender">Classe</label>
                  <select
                    className="form-control"
                    id="exampleSelectGender"
                    name="classe"
                  >
                    <option value="" disabled selected>
                      Choisir une classe
                    </option>
                    {filteredClasses.map((classe) => (
                      <option key={classe.id} value={classe.id}>
                        {classe.classe}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Téléphone">École l'élève a fait 9e</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Téléphone"
                    placeholder="Ecole 9e de l'élève"
                    name="ecole_9e"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Téléphone">Année Réussite 9e</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Téléphone"
                    placeholder="Année Reussite en 9e"
                    name="annee_9e"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Téléphone">Dernier Etablissement</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Téléphone"
                    placeholder="Dernière Etablissement"
                    name="dernier_etablissement"
                  />
                </div>
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="exampleInputFile">Fichier importer</label>
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="exampleInputFile"
                      accept="image/*"
                      name="image"
                      onChange={handleImageChange}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="exampleInputFile"
                    >
                      {profileImage
                        ? "Image sélectionnée"
                        : "Sélectionner une image"}
                    </label>
                  </div>
                  <div className="input-group-append">
                    <span className="input-group-text">Importer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">Informations Parent</div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail3">Nom du père</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail3"
                    placeholder="Nom du père"
                    name="nom_pere"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail3">Nom de la mère</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail3"
                    placeholder="Nom complet de la mère"
                    name="nom_mere"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Téléphone">Personne Responsable</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Téléphone"
                    placeholder="Personne responsable"
                    name="parent"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Téléphone">Adresse</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Téléphone"
                    placeholder="Adresse"
                    name="adresse"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label htmlFor="Téléphone">Téléphone</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">+509</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    id="Téléphone"
                    placeholder="Téléphone"
                    name="telephone"
                  />
                </div>
              </div>
              <div className="col-md-9  text-right">
                <label htmlFor="Téléphone" className="text-white">
                  .
                </label>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <i className="fas fa-spinner fa-spin"></i>{" "}
                        Enregistrement en cours...
                      </>
                    ) : (
                      <>
                        <i className="fas fa-save"></i> Enregistrer
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export const FormStudent = () => {
  const { getClasses, createStudents } = useContext(StudentContext);
  const [profileImage, setProfileImage] = useState(null);
  const { user } = useContext(AuthContext);
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [loading, setLoading] = useState(false); // État de chargement

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const classesData = await getClasses();
        setClasses(classesData);
        // console.log(classesData);
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };
    fetchClasses();
  }, [getClasses]);

  useEffect(() => {
    if (selectedLevel) {
      const filtered = classes.filter(
        (classe) => classe.niveau === selectedLevel
      );
      setFilteredClasses(filtered);
    } else {
      setFilteredClasses([]);
    }
  }, [selectedLevel, classes]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const nom = e.target.nom.value;
    const prenom = e.target.prenom.value;
    const lieu_naissance = e.target.lieu_naissance.value;
    const sexe = e.target.sexe.value;
    const date_naissance = e.target.date_naissance.value;
    const no_ordre = e.target.no_ordre.value;
    const ecole_9e = e.target.ecole_9e.value;
    const annee_9e = e.target.annee_9e.value;
    const adresse = e.target.adresse.value;
    const telephone = e.target.telephone.value;
    const classe = e.target.classe.value;
    const niveau = e.target.niveau.value;
    const dernier_etablissement = e.target.dernier_etablissement.value;
    const nom_pere = e.target.nom_pere.value;
    const nom_mere = e.target.nom_mere.value;
    const parent = e.target.parent.value;

    let formData = new FormData();
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("sexe", sexe);
    formData.append("date_naissance", date_naissance);
    formData.append("lieu_naissance", lieu_naissance);
    formData.append("classe", classe);
    formData.append("niveau", niveau);
    formData.append("no_ordre", no_ordre);
    formData.append("ecole_9e", ecole_9e);
    formData.append("annee_9e", annee_9e);
    formData.append("telephone", telephone);
    formData.append("dernier_etablissement", dernier_etablissement);
    formData.append("parent", parent);
    formData.append("adresse", adresse);
    formData.append("nom_pere", nom_pere);
    formData.append("nom_mere", nom_mere);
    formData.append("created_by", user.user_id);

    const imageFile = e.target.image.files[0];
    if (imageFile) {
      formData.append("photo", imageFile);
    }
    if (nom.length > 0 && prenom.length > 0) {
      setLoading(true); // Commence le chargement
      const matricule = await createStudents(formData);
      setLoading(false); // Termine le chargement
      if (matricule) {
        const studentData = {
          nom,
          prenom,
          adresse,
          telephone,
          classe_nom: classe,
          matricule,
        };

        generatePDF(studentData);
      }
    }
  };
  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="forms-sample"
      encType="multipart/form-data"
    >
      <div className="row">
        <div className="card">
          <div className="card-header">Informations de l'élève</div>
          <div className="card-body">
            <div className="row">
              {profileImage && (
                <div className="col-md-3">
                  <label htmlFor="profil">Photo</label>
                  <div className="form-group">
                    {/* eslint-disable-next-line */}
                    <img
                      src={profileImage}
                      id="profil"
                      alt="Photo de profil"
                      style={{
                        maxWidth: "80px",
                        maxHeight: "80px",
                        borderRadius: "50px",
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="name">Nom</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Nom de l'élève"
                    name="nom"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="prenom">Prénom</label>
                  <input
                    type="text"
                    className="form-control"
                    id="prenom"
                    placeholder="Prenom de l'élève"
                    name="prenom"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="exampleSelectGender">Sexe</label>
                  <select
                    className="form-control"
                    id="exampleSelectGender"
                    name="sexe"
                  >
                    <option value="" disabled selected>
                      Choisir le sexe
                    </option>
                    <option value={"M"}>Masculin</option>
                    <option value={"F"}>Feminin</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="date">Date naissance</label>
                  <input
                    type="date"
                    className="form-control"
                    id="date"
                    placeholder="Date Naissance"
                    name="date_naissance"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Adresse">Lieu de Naissance</label>
                  <input
                    type="test"
                    className="form-control"
                    id="Adresse"
                    placeholder="Lieu de naissance"
                    name="lieu_naissance"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Adresse">N˚ d'ordre 9ème AF</label>
                  <input
                    type="test"
                    className="form-control"
                    id="Adresse"
                    placeholder="Numero d'ordre 9e"
                    name="no_ordre"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="exampleSelectGender">Niveau</label>
                  <select
                    className="form-control"
                    id="exampleSelectGender"
                    name="niveau"
                    value={selectedLevel}
                    onChange={handleLevelChange}
                  >
                    <option value="" disabled selected>
                      Choisir un Niveau
                    </option>
                    <option value={"NSI"}>NSI</option>
                    <option value={"NSII"}>NSII</option>
                    <option value={"NSIII"}>NSIII</option>
                    <option value={"NSIV"}>NSIV</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="exampleSelectGender">Classe</label>
                  <select
                    className="form-control"
                    id="exampleSelectGender"
                    name="classe"
                  >
                    <option value="" disabled selected>
                      Choisir une classe
                    </option>
                    {filteredClasses.map((classe) => (
                      <option key={classe.id} value={classe.id}>
                        {classe.classe}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Téléphone">École l'élève a fait 9e</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Téléphone"
                    placeholder="Ecole 9e de l'élève"
                    name="ecole_9e"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Téléphone">Année Réussite 9e</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Téléphone"
                    placeholder="Année Reussite en 9e"
                    name="annee_9e"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Téléphone">Dernier Etablissement</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Téléphone"
                    placeholder="Dernière Etablissement"
                    name="dernier_etablissement"
                  />
                </div>
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="exampleInputFile">Fichier importer</label>
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="exampleInputFile"
                      accept="image/*"
                      name="image"
                      onChange={handleImageChange}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="exampleInputFile"
                    >
                      {profileImage
                        ? "Image sélectionnée"
                        : "Sélectionner une image"}
                    </label>
                  </div>
                  <div className="input-group-append">
                    <span className="input-group-text">Importer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">Informations Parent</div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail3">Nom du père</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail3"
                    placeholder="Nom du père"
                    name="nom_pere"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail3">Nom de la mère</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail3"
                    placeholder="Nom complet de la mère"
                    name="nom_mere"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Téléphone">Personne Responsable</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Téléphone"
                    placeholder="Personne responsable"
                    name="parent"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Téléphone">Adresse</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Téléphone"
                    placeholder="Adresse"
                    name="adresse"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label htmlFor="Téléphone">Téléphone</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">+509</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    id="Téléphone"
                    placeholder="Téléphone"
                    name="telephone"
                  />
                </div>
              </div>
              <div className="col-md-9  text-right">
                <label htmlFor="Téléphone" className="text-white">
                  .
                </label>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <i className="fas fa-spinner fa-spin"></i>{" "}
                        Enregistrement en cours...
                      </>
                    ) : (
                      <>
                        <i className="fas fa-save"></i> Enregistrer
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
