import React, { createContext, useContext } from 'react';
import AuthContext from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { baseUrlTeacher } from './BaseUrl';
const swal = require('sweetalert2')
const baseUrl = baseUrlTeacher;

const TeacherContext = createContext();

const TeacherProvider = ({ children }) => {
    const { fetchWithAuth } = useContext(AuthContext);
    const navigate = useNavigate(); // Utilisez useNavigate pour la navigation

    // Fonctions de gestion des professeurs

    const getTeachers = async () => {
        const response = await fetchWithAuth(`${baseUrl}create-view/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        return data;
    }
    const getTeacherById = async (userId) => {
        const response = await fetchWithAuth(`${baseUrl}detail-view/${userId}/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        // console.log(data);
        return data;
    }
    const deleteTeachers = async (userId) => {
        const response = await fetchWithAuth(`${baseUrl}detail-view/${userId}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.status === 204) {
            swal.fire({
                title: "User Deleted Successfully",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        } else {
            // console.log(response.status);
            // console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    }
    const updateTeachers = async (userId, formData) => {
        const response = await fetchWithAuth(`${baseUrl}detail-view/${userId}/`, {
            method: "PUT",
            body: formData
        });
        if (response.status === 200) {
            swal.fire({
                title: "Teacher Updated Successfully",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
            navigate("/teacher");
        } else {
            // console.log(response.status);
            // console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status + "\n" + response.error,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    }
    const createTeacher = async (formData) => {
        const response = await fetchWithAuth(`${baseUrl}create-view/`, {
            method: "POST",
            body: formData
        });

        if (response.status === 201) {
            window.location.reload();
            swal.fire({
                title: "Inscription Created Successfully",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        } else {
            // console.log(response.status);
            // console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };
    // Autres fonctions de gestion des professeurs...

    // Context data
    const contextData = {
        createTeacher,
        getTeachers,
        getTeacherById,
        updateTeachers,
        deleteTeachers,
    };

    return (
        <TeacherContext.Provider value={contextData}>
            {children}
        </TeacherContext.Provider>
    );
};

export { TeacherContext, TeacherProvider };
