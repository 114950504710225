/* eslint-disable */
import { useContext, useEffect, useState } from "react";
import Sidebar from "../../Includes/Sidebar";
import { Link } from "react-router-dom";
import { CourseContext } from "../../context/CourseContext";
import { StudentContext } from "../../context/StudentContext";
import Loader from "../../Includes/loader/loader";
import jsPDF from "jspdf";
import DataTable from "react-data-table-component";
import { customStyles } from "../Data/Utility";
import 'jspdf-autotable';
const swal = require('sweetalert2')
// import axios from 'axios';
import { AdminContext } from "../../context/AdminContext";
// const baseUrl = 'http://127.0.0.1:8000/api/students/';

function Bulletin() {
  const { fetchStudentBulletins, studentBulletins} = useContext(CourseContext);
  const { getClasses } = useContext(StudentContext);
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const { getAnneeAc} =useContext(AdminContext);
  const [records, setRecords] = useState([]);


  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const classesData = await getClasses();
        if (Array.isArray(classesData)) {
          setClasses(classesData);
        } else {
          console.error('Classes data is not an array');
        }
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };
    const fetchData = async () => {
      try {
        const data = await getAnneeAc();
        setRecords(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    fetchClasses();
  }, [getClasses]);

  useEffect(() => {
    if (selectedLevel) {
      const filtered = classes.filter((classe) => classe.niveau === selectedLevel);
      setFilteredClasses(filtered);
      setLoading(false);
    } else {
      setFilteredClasses([]);
    }
  }, [selectedLevel, classes,getAnneeAc]);

  const record = records.length > 0 ? records[0] : null;

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
    setSelectedClass('');
  };

  const handleClassChange = async (event) => {
    const newClass = event.target.value;
    setSelectedClass(newClass);
    
    setError(''); // Réinitialiser l'erreur avant le chargement
  
    try {
      if (newClass) {
        await fetchStudentBulletins(selectedLevel, newClass);
        setLoading(false);
        console.log(studentBulletins);
        if (studentBulletins.length === 0) {
          setError('Aucun élève trouvé avec des notes suffisantes.');
        }
      }
    } catch (error) {
      setError('Une erreur est survenue lors de la récupération des bulletins.');
      console.error('Error fetching student bulletins:', error);
    }
  };

  const generateStudentPDF = async (studentData,date) => {
    
    const doc = new jsPDF('p', 'pt', 'letter'); // 'letter' sets the size to 8.5 x 11 inches
  
    // Add background image
    const img = new Image();
    img.src = '/img/filig.png';
  
    // Wait for the image to load
    await new Promise((resolve) => {
      img.onload = resolve;
    });
  
    // Draw the background image using html2canvas
    const canvas = document.createElement('canvas');
    canvas.width = 612; // Letter width in points
    canvas.height = 792; // Letter height in points
    const context = canvas.getContext('2d');
    context.drawImage(img, 0, 0, canvas.width, canvas.height);
  
    const imgData = canvas.toDataURL('image/png');
  
    // Add the background image to the PDF
    doc.addImage(imgData, 'PNG', 0, 0, 612, 792);
  
    // Header
    doc.addImage('/img/header.png', 'PNG', 0, 0, 612, 130);
  
    // Student Info
    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    doc.text(`Nom : ${studentData.nom}`, 40, 150);
    doc.text(`Prenom : ${studentData.prenom}`, 40, 170);
    doc.text(`Année Académique : ${date}`, 200, 195);
    doc.text(`Matricule : ${studentData.matricule}`, 350, 150);
    doc.text(`Classe : ${studentData.niveau} ${studentData.classe_nom}`, 350, 170);
  
    // Notes Table
    const tableColumn = ["Matières", "Max", "Controle 1", "Controle 2", "Controle 3"];
    const tableRows = studentData.notes.map(note => [
      note.course_name,
      note.ponderation,
      note.notes["1er trimestre"],
      note.notes["2e trimestre"],
      note.notes["3e trimestre"]
    ]);
  
    // Adding a smaller, gray-filled line before the totals and averages
    const customRow = [{ content: "", colSpan: 5, styles: { fillColor: [200, 200, 200], fontSize: 5, lineWidth: 0 } }];
    tableRows.push(customRow);
  
 // Adding totals and averages to the table
 tableRows.push([
  { content: "Total", styles: { halign: 'right', fontStyle: 'bold', cellPadding: { right: 5 } } },
  { content: studentData.total_pond_1er_trimestre, styles: { fontStyle: 'bold' } },
  { content: studentData.total_notes_1er_trimestre, styles: { fontStyle: 'bold' } },
  { content: studentData.total_notes_2e_trimestre, styles: { fontStyle: 'bold' } },
  { content: studentData.total_notes_3e_trimestre, styles: { fontStyle: 'bold' } }
]);

tableRows.push([
  { content: "Moyenne", styles: { halign: 'right', fontStyle: 'bold', cellPadding: { right: 5 } } },
  { content: "10", styles: { fontStyle: 'bold' } },
  { content: studentData.average_1er_trimestre, styles: { fontStyle: 'bold' } },
  { content: studentData.average_2e_trimestre, styles: { fontStyle: 'bold' } },
  { content: studentData.average_3e_trimestre, styles: { fontStyle: 'bold' } }
]);

    doc.autoTable({
      startY: 200,
      head: [tableColumn],
      body: tableRows,
      theme: 'grid',
      styles: { 
        font: 'times', 
        fontSize: 12, // Reduce the font size
        cellPadding: 2 // Adjust cell padding if necessary
      },
      headStyles: { halign:'center', valign: 'middle',fillColor: [1, 80, 133], textColor: [255, 255, 255] },
      bodyStyles: { 
        valign: 'middle', 
        textColor: [22, 22, 22],
        halign: 'center' // Center align text in all body cells
      },
      columnStyles: {
        0: { halign: 'left' , fontStyle: 'bold'}, // Align the first column to the left
        1: { halign: 'center' }, // Align the second column to the center
        2: { halign: 'center' }, // Align the third column to the center
        3: { halign: 'center' }, // Align the fourth column to the center
        4: { halign: 'center' }  // Align the fifth column to the center
      },
      margin: { right: 220 }
    });
  
    const finalY = doc.lastAutoTable.finalY;
  
    // Interval Table
    const intervalTableColumn = ["Intervalle", "Niveau", "Appreciations"];
    const intervalTableRows = [
      ["100-90", "5", "T-D"],
      ["89-70", "4", "D"],
      ["69-70", "3", "P."],
      ["54-30", "2", "P-D"],
      ["Moins de 30", "1", "TPD"]
    ];
  
    doc.autoTable({
      startY: 200,
      head: [intervalTableColumn],
      body: intervalTableRows,
      theme: 'grid',
      styles: { 
        font: 'times', 
        fontSize: 10, // Reduce the font size
        cellPadding: 1 // Adjust cell padding if necessary
      },
      headStyles: { fillColor: [1, 80, 133], fontStyle: 'bold', textColor: [255, 255, 255] },
      bodyStyles: { textColor: [22, 22, 22] }, // Center align text
      margin: { left: 395 }
    });
  
    const finalY2 = Math.max(doc.lastAutoTable.finalY, finalY);
  
    // General Average Table
    const MoyenneGTableColumn = ["Moyenne Generale", ""];
    const MoyenneGTableRows = [
      ["1er Controle", studentData.average_1er_trimestre],
      ["2e Controle", studentData.average_2e_trimestre],
      ["3e Controle", studentData.average_3e_trimestre],
      ["Moyenne de l'année", studentData.moyenne_generale],
      ["Décision de l'année", studentData.decision_annee],
    ];
  
    doc.autoTable({
      startY: 287,
      head: [MoyenneGTableColumn],
      body: MoyenneGTableRows,
      theme: 'grid',
      styles: { 
        font: 'times', 
        fontSize: 10, // Reduce the font size
        cellPadding: 1 // Adjust cell padding if necessary
      },
      headStyles: { halign:'center', valign: 'middle', fontStyle: 'bold',
        fillColor: [1, 80, 133], textColor: [255, 255, 255] },
      bodyStyles: { textColor: [22, 22, 22] }, // Center align text
      margin: { left: 395 }
    });
  
    const finalY4 = Math.max(doc.lastAutoTable.finalY, finalY);
  
    // Decision and Notes
    const notesTableColumn1 = ["Valeurs des Notes"];
  
    doc.autoTable({
      startY: 373,
      head: [notesTableColumn1],
      theme: 'grid',
      styles: { 
        font: 'times', 
        fontSize: 10, // Reduce the font size
        cellPadding: 1 // Adjust cell padding if necessary
      },
      headStyles: {halign:'center', valign: 'middle', fontStyle: 'bold',
        fillColor: [1, 80, 133], textColor: [255, 255, 255] },
      margin: { left: 395 }
    });
  
    const notesTableRows = [
      ["T-D", "Très Developper", "D", "Developper"],
      ["P.", "Passable", "P-D", "Peu Developper"],
      ["TPD", "Très Peu Developper", "", ""]
    ];
  
    doc.autoTable({
      startY: 386,
      body: notesTableRows,
      theme: 'grid',
      styles: { 
        font: 'times', 
        fontSize: 10, // Reduce the font size
        cellPadding: 1 // Adjust cell padding if necessary
      },
      bodyStyles: { textColor: [22, 22, 22] }, // Center align text
      margin: { left: 395 }
    });
  
    const finalY3 = doc.lastAutoTable.finalY;
  
 
  // Adding the header "Décision de l'année"
  doc.autoTable({
    startY: 453, // Positioning the table below the last section
    head: [['Décision de l\'année']],
    body: [
      ['Promu (e)', ''],
      ['Promu (e) Ailleurs', ''],
      ['Doublé Ailleurs', '']
    ],
    theme: 'grid',
    styles: { 
      font: 'times', 
      fontSize: 10, // Adjust font size
      cellPadding: 1 // Adjust cell padding
    },
    headStyles: { halign:'center', valign: 'middle',
      fillColor: [1, 80, 133], 
      textColor: [255, 255, 255]
    },
    bodyStyles: { 
      textColor: [22, 22, 22]
    },
    margin: { left: 395 }
  });

  // Drawing the checkboxes
  const checkBoxPositions = [
    { x: 540, y: 468 },
    { x: 540, y: 481 },
    { x: 540, y: 495 }
  ];

  checkBoxPositions.forEach(pos => {
    doc.rect(pos.x, pos.y, 10, 10); // Draw checkbox
  });

  // Adding signature lines and labels
  const yPos = 580; // Position for the signature lines
  const yPos1 = 665; // Position for the signature lines
  doc.setLineWidth(0.5);
  doc.line(400, yPos, 560, yPos); // Line for the "Signature du directeur"
  doc.line(400, yPos1, 560, yPos1); // Line for the "Signature du Censeur"

  doc.setFontSize(10);
  doc.setFont('times', 'normal');
  doc.text('Signature du Directeur', 438, yPos + 15); // Label for the director's signature
  doc.text('Signature du Censeur', 440, yPos1 + 15); // Label for the censor's signature


  // const pdfBlob = doc.output('blob');
  // const formData = new FormData();
  // formData.append('pdf', pdfBlob, `${studentData.nom}_${studentData.prenom}_badge.pdf`);
  // formData.append('classe_nom', studentData.classe_nom);

  // try {
  //   const response = await axios.post(`${baseUrl}save-bulletins-pdf/`, formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   });
  //   if (response.status === 200) {
  //     // Ouvrir le PDF dans une nouvelle fenêtre sans le télécharger à nouveau
  //     const url = URL.createObjectURL(pdfBlob);
  //     window.open(url, '_blank');
      
  //     // Cleanup
  //     setTimeout(() => {
  //       URL.revokeObjectURL(url);
  //     }, 100);
  //   }
  //   console.log(response.data);
  // } catch (error) {
  //   console.error('Erreur lors de l\'envoi du PDF:', error);
  // }
    // Save PDF
    doc.save(`${studentData.nom}_${studentData.prenom}_bulletin.pdf`);
  }
  
  
  const exportAllBulletins = async () => {

    // if (studentBulletins===null) {
    for (const student of studentBulletins) {
      try {
        await generateStudentPDF(student,record.date);
      } catch (error) {
        console.error(`Erreur lors de la récupération des données pour l'étudiant avec matricule ${student.code}:`, error);
      }
    }
      
    // }
    // else{
    //   swal.fire({
    //     title: "Aucun Bulletin disponible pour cette classe",
    //     icon: "error",
    //     toast: true,
    //     timer: 3000,
    //     position: 'top-right',
    //     timerProgressBar: true,
    //     showConfirmButton: false,
    //   });
    // }
  };

  const columnsCourses = [
    {
      name: 'Matricule',
      selector: (row) => row.matricule,
      sortable: true,
    },
    {
      name: 'Controle 1',
      selector: (row) => row.total_notes_1er_trimestre,
      sortable: true,
    },
    {
      name: 'Controle 2',
      selector: (row) => row.total_notes_2e_trimestre,
      sortable: true,
    },
    {
      name: 'Controle 3',
      selector: (row) => row.total_notes_3e_trimestre,
      sortable: true,
    },
    {
      name: 'Moyenne',
      cell: (row) => {
        const getButtonClass = (average) => {
          if (average < 5) {
            return 'btn btn-danger btn-sm me-1 p-1';
          } else if (average >= 5 && average < 6.5) {
            return 'btn btn-warning btn-sm me-1 p-1 ';
          } else {
            return 'btn btn-success btn-sm me-1 p-1 ';
          }
        };

        const buttonClass1 = getButtonClass(row.average_1er_trimestre);
        const buttonClass2 = getButtonClass(row.average_2e_trimestre);
        const buttonClass3 = getButtonClass(row.average_3e_trimestre);

        return (
          <>
            <button className={buttonClass1}>{row.average_1er_trimestre}</button>
            <button className={buttonClass2}>{row.average_2e_trimestre}</button>
            <button className={buttonClass3}>{row.average_3e_trimestre}</button>
          </>
        );
      },
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <button className="btn btn-primary btn-sm me-1 p-1 mt-1 mb-1" onClick={() => generateStudentPDF(row,record.date)}>
         <i className="fas fa-print"></i> Imprimer
        </button>
      ),
      button: true,
    },
  ];

  return (
    <div className="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2 mt-3">
                <div className="col-sm-6 d-flex align-items-center">
                  <div className="bg-blue rounded-lg p-2 text-center">
                    <i className='fas fa-users-cog'></i>
                  </div>
                  <div className="ml-2"><h3 className='display fw-12'>Gestion des Bulletins</h3></div>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><Link to="/dashboard">Tableau de bord</Link></li>
                    <li className="breadcrumb-item"><Link to="/notes">Gestion des Notes</Link></li>
                    <li className="breadcrumb-item active">Bulletins</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row d-flex justify-content-center mt-4 mb-2">
                <h2 className="text-center">Bulletins des étudiants</h2>
                <div className="d-flex justify-content-center">
                  <div className="col-md-3 col-sm-6 col-10 mx-2 mb-3">
                    <select className="form-select" value={selectedLevel} onChange={handleLevelChange}>
                      <option value="">Sélectionner un niveau</option>
                      {Array.isArray(classes) &&
                        [...new Set(classes.map((classe) => classe.niveau))].map((niveau) => (
                          <option key={niveau} value={niveau}>
                            {niveau}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-3 col-sm-6 col-10 mx-2 mb-3">
                    <select className="form-select" value={selectedClass} onChange={handleClassChange}>

                      {loading ? (
                        <Loader />
                      ) : (
                        <>
                      <option value="">Sélectionner une classe</option>
                      {Array.isArray(filteredClasses) &&
                        filteredClasses.map((classe) => (
                          <option key={classe.id} value={classe.classe}>
                            {classe.classe}
                          </option>

                        ))}
                        </>
                      )}

                    </select>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button className="btn btn-success btn-sm" onClick={exportAllBulletins}>
                  <i className="fas fa-print"></i> Imprimer tout
                  </button>
                </div>
              </div>

              {loading ? (
                        <Loader />
                      ) : (
                        <>
              {studentBulletins.length > 0 ? (
                <div className="row">
                  <DataTable
                    columns={columnsCourses}
                    data={studentBulletins}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="500px"
                    highlightOnHover
                    customStyles={customStyles}
                    responsive
                  />
                </div>
              ) : (
                <Loader />
              )}
              </>
            )}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bulletin;
