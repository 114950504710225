/* eslint-disable */
import React, { Suspense, useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import NavbarHome from '../Includes/NavbarHome';
import FooterHome from '../Includes/FooterHome';
import { Helmet } from 'react-helmet';
import About from './About';
import Services from './Services';
import Programs from './Programs';

// Lazy loading des composants pour les charger uniquement lorsqu'ils sont nécessaires
const Gallery = React.lazy(() => import('./Gallery'));
const Carousel = React.lazy(() => import('../Includes/Carousel'));
const Contact = React.lazy(() => import('./Contact'));

function Home() {
    const [loading, setLoading] = useState(true);

    // Simule un délai pour le chargement initial de la page
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);  // Durée du délai (ex. 1 seconde)

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="container-xxl bg-white p-0" id='home'>
            <Helmet>
                <title>Lycée National François Cappoix de Limonade</title>
                <meta name="description" content="Lycée National François Cappoix de Limonade : S'instruire pour mieux servir." />
                <meta name="keywords" content="Lycée, Education, Limonade, François Cappoix, lnfcl" />
                
                {/* Balises Open Graph pour le SEO des réseaux sociaux */}
                <meta property="og:title" content="Lycée National François Cappoix de Limonade" />
                <meta property="og:description" content="S'instruire pour mieux servir." />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="/img/carousel-4.png" />
                <meta property="og:url" content="https://lnfcl.com" />

                {/* Préchargement d'une image essentielle */}
                <link rel="preload" href="/img/carousel-4.png" as="image" />

                {/* Données structurées JSON-LD */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "EducationalOrganization",
                        "name": "Lycée National François Cappoix de Limonade",
                        "url": "https://lnfcl.com",
                        "logo": "/log,png",
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+50944441410",
                            "contactType": "Service Client",
                            "areaServed": "FR",
                            "availableLanguage": "French"
                        }
                    }
                    `}
                </script>
            </Helmet>
            
            {/* Affichage du spinner si la page est en chargement */}
            {loading && (
                <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                    <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}

            {/* Navbar */}
            <NavbarHome />

            {/* Carrousel avec lazy loading */}
            <Suspense fallback={<div className='text-center'><i className="fas fa-spinner fa-spin"></i> Chargement en cours...</div>}>
                <Carousel />
            </Suspense>
            
            <div className="text-center mx-auto mb-5 wow fadeIn p-2" data-wow-delay="0.1s" style={{ maxWidth: '800px' }}>
                <h1 className="display-5 mb-3">Bienvenue sur le site François Cappoix de Limonade</h1>
                <p>Nous sommes ravis de vous accueillir sur notre site. Découvrez tout ce que notre lycée a à offrir pour le développement éducatif et personnel de nos élèves.</p>
            </div>

            {/* Section About */}
            <About />

            {/* Section Services */}
            <Services />
     <Suspense fallback={<div>Chargement du contact...</div>}>
                <Programs />
            </Suspense>
            {/* Galerie avec lazy loading */}
            <Suspense fallback={<div>Chargement de la galerie...</div>}>
                <Gallery />
            </Suspense>

            {/* Témoignages avec Owl Carousel */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
                        <h1 className="display-5 mb-3">Les Parents Disent!</h1>
                        <p>Ces témoignages reflètent la satisfaction et la confiance des parents envers notre école , mettant en évidence notre dévouement à offrir une éducation de qualité et à favoriser l'épanouissement de chaque élève.</p>
                    </div>
                    <OwlCarousel
                        items={1}
                        loop
                        nav
                        dots
                        autoplay
                        autoplayTimeout={4000}
                        autoplaySpeed={1500}
                        className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
                        <div className="testimonial-item bg-primary rounded p-5">
                            <p className="fs-7">Je suis tellement heureuse d'avoir inscrit mon enfant dans cette école ! L'environnement chaleureux et les enseignants dévoués ont aidé mon enfant à s'épanouir et à développer sa curiosité naturelle.</p>
                            <div className="d-flex align-items-center bg-white me-n5" style={{ borderRadius: '50px 0 0 50px' }}>
                                <img loading="lazy" className="img-fluid flex-shrink-0 rounded-circle" src='./img/testimonial-1.jpg' style={{ width: '90px', height: '90px' }}  alt='Photo de Sophie L., parent et commerçante' />
                                <div className="ps-3">
                                    <h3 className="mb-1">Sophie L. (Parent)</h3>
                                    <span>Commerçante</span>
                                </div>
                                <i className="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex"></i>
                            </div>
                        </div>
                        <div className="testimonial-item bg-light rounded p-5">
                            <p className="fs-7">"Cette école a dépassé toutes nos attentes. Les activités variées et l'attention personnalisée accordée à chaque enfant sont remarquables. Mon fils est heureux d'aller à l'école tous les jours et il est enthousiaste à l'idée d'apprendre de nouvelles choses.</p>
                            <div className="d-flex align-items-center bg-white me-n5" style={{ borderRadius: '50px 0 0 50px' }}>
                                <img loading="lazy" className="img-fluid flex-shrink-0 rounded-circle" src='./img/testimonial-2.jpg' style={{ width: '90px', height: '90px' }}  alt='Photo de David T., parent et médecin' />
                                <div className="ps-3">
                                    <h3 className="mb-1">David T. (Parent) </h3>
                                    <span>Médecin</span>
                                </div>
                                <i className="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex"></i>
                            </div>
                        </div>
                        <div className="testimonial-item bg-light rounded p-5">
                            <p className="fs-7">"C'est incroyable de voir à quel point notre enfant a grandi depuis qu'il a rejoint cette école. Les enseignants sont très attentionnés et ils ont su créer un environnement propice à l'apprentissage et à l'amusement</p>
                            <div className="d-flex align-items-center bg-white me-n5" style={{ borderRadius: '50px 0 0 50px' }}>
                                <img loading="lazy" className="img-fluid flex-shrink-0 rounded-circle" src='./img/testimonial-3.jpg' style={{ width: '90px', height: '90px' }} alt='Photo de Marc D., parent et ingénieur' />
                                <div className="ps-3">
                                    <h3 className="mb-1">Marc D. (Parent) </h3>
                                    <span>Ingénieur</span>
                                </div>
                                <i className="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex"></i>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>

            {/* Section Contact avec lazy loading */}
            <Suspense fallback={<div>Chargement du contact...</div>}>
                <Contact />
            </Suspense>
       

            {/* Footer */}
            <FooterHome />
            <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>
        
        </div>
    );
}

export default Home;
