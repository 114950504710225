/* eslint-disable */
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../Includes/loader/loader";

const ResetPassword = () => {
  const { uidb64, token } = useParams();
  const { resetPassword } = useContext(AuthContext);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [loading, setLoading] = useState(false); // État de chargement

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword.length > 0 && confirmPassword.length > 0) {
      setLoading(true); // Commence le chargement
      await resetPassword(uidb64, token, newPassword, confirmPassword);
      setLoading(false); // Termine le chargement
    }
  };

  return (
    <>
      <div className="wrapper">
        <div className="container-fluid h-100">
          <div className="row flex-row h-100">
            <div className="col-xl-8 p-0 d-none d-md-block">
              <div
                className="lavalite-bg"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 100, 0.5), rgba(0, 0, 100, 0.5)), url(/img/auth/login-bg.png)`,
                  height: "100vh",
                  backgroundSize: "cover",
                }}
              >
                <div className="lavalite-overlay"></div>
              </div>
            </div>
            <div className="col-xl-4 my-auto">
              <div className="card">
                <Link
                  to="/"
                  className="close bg-white mt-0 mb-3"
                  style={{ display: "flex" }}
                >
                  <i className="fa fa-home text-primary"></i>
                </Link>
              </div>
              <div className="container-fluid mx-auto p-3">
                <div className="d-flex justify-content-center align-items-center mb-4">
                  <a href="ghjhmll">
                    <img src="/img/log.png" alt="logo" />
                  </a>
                </div>
                <h6 className="text-center">
                  Lycée National François Cappoix de Limonade
                </h6>
                <div
                  className="card justify-content-center align-items-center p-3 elevation-1 mb-0"
                  style={{
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "0px",
                    borderBottomLeftRadius: "0px",
                  }}
                >
                  <h4 className="display text-blue">Reinitialisaton</h4>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form-group position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control pl-5"
                      placeholder="Nouveau mot de passe"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                    <i
                      className={`password-toggle-icon position-absolute fa ${
                        showPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                      onClick={togglePasswordVisibility}
                      style={{
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "10px",
                        cursor: "pointer",
                      }}
                    />
                    <i
                      className="fa fa-lock position-absolute text-blue"
                      style={{
                        top: "50%",
                        transform: "translateY(-50%)",
                        left: "10px",
                      }}
                    ></i>
                  </div>
                  <div className="form-group position-relative">
                    <input
                      type={showPassword1 ? "text" : "password"}
                      className="form-control pl-5"
                      placeholder="Confirmer le nouveau mot de passe"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <i
                      className={`password-toggle-icon position-absolute fa ${
                        showPassword1 ? "fa-eye-slash" : "fa-eye"
                      }`}
                      onClick={togglePasswordVisibility1}
                      style={{
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "10px",
                        cursor: "pointer",
                      }}
                    />
                    <i
                      className="fa fa-lock position-absolute text-blue"
                      style={{
                        top: "50%",
                        transform: "translateY(-50%)",
                        left: "10px",
                      }}
                    ></i>
                  </div>
                  <div className="sign-btn text-center">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                          Réinitialisation en cours...
                        </>
                      ) : (
                        <>
                          <i className="fas fa-save"></i> Réinitialiser le mot
                          de passe
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
