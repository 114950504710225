import { useContext, useState } from "react";
import AuthContext from "../../context/AuthContext";
import { CourseContext } from "../../context/CourseContext";

const FormCourses = () => {
  const { createCourse } = useContext(CourseContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false); // État de chargement

  // useEffect(() => {
  //     const fetchClasses = async () => {
  //         try {
  //             const classesData = await getClasses();
  //             setClasses(classesData);
  //             console.log(classesData);

  //         } catch (error) {
  //             console.error("Error fetching classes:", error);
  //         }
  //     };
  //     fetchClasses();
  // }, [getClasses]);

  //   useEffect(() => {
  //     if (selectedLevel) {
  //         // const filtered = classes.filter(classe => classe.niveau === selectedLevel);
  //         // setFilteredClasses(filtered);
  //     } else {
  //         setFilteredClasses([]);
  //     }
  // }, [selectedLevel]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const nom = e.target.nom.value;
    const description = e.target.description.value;
    const heures_credit = e.target.heures_credit.value;
    const niveau = e.target.niveau.value;
    const ponderation = e.target.ponderation.value;

    let formData = new FormData();
    formData.append("nom", nom);
    formData.append("description", description);
    formData.append("heures_credit", heures_credit);
    formData.append("niveau", niveau);
    formData.append("ponderation", ponderation);
    formData.append("created_by", user.user_id);

    setLoading(true);
    try {
      nom.length > 0 && createCourse(formData);
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Remettre loading à false après la fin du processus
    }
  };

  //   const handleLevelChange = (event) => {
  //     setSelectedLevel(event.target.value);
  // };
  return (
    <form
      onSubmit={handleSubmit}
      className="forms-sample"
      encType="multipart/form-data"
    >
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="name">Nom Matière</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Matière"
              required
              name="nom"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="name">Description</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Matière"
              required
              name="description"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="exampleSelectGender">Niveau</label>
            <select
              className="form-control"
              id="exampleSelectGender"
              name="niveau"
            >
              <option value="" disabled></option>
              <option value={"NSI"}>NSI</option>
              <option value={"NSII"}>NSII</option>
              <option value={"NSIII"}>NSIII</option>
              <option value={"NSIV"}>NSIV</option>
            </select>
          </div>
        </div>
        {/* <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="exampleSelectGender">Classe</label>
            <select className="form-control" id="exampleSelectGender" name='classe'>
                <option value="" disabled selected>Choisir une classe</option>
                {filteredClasses.map(classe => (
                                <option key={classe.id} value={classe.id}>{classe.classe}</option>
                            ))}
            </select>

          </div>
        </div> */}
        {/* <div className="col-md-5">
          <div className="form-group">
            <label htmlFor="exampleSelectGender">Professeur</label>
            <select className="form-control" id="exampleSelectGender" name='teachers'>
                <option value="" disabled selected>Choisir un professeur</option>
                {Array.isArray(teachers) && teachers.map((teachers) => (
                    <option key={teachers.id} value={teachers.id}>
                        {teachers.nom+' '+teachers.prenom}</option>
                ))}
            </select>

          </div>
        </div>
          <div className="col-md-1 pt-3">
            <div className="form-group mt-3">
              <Link to='/teacher' className='btn btn-success'>
                <i className='fas fa-user-plus'></i>
              </Link>
            </div>
          </div> */}
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="exampleInputheures_credit3">Nombre d'heure</label>
            <input
              type="number"
              className="form-control"
              id="exampleInputheures_credit3"
              placeholder="heures_credit du Professeur"
              required
              name="heures_credit"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="Téléphone">Pondération</label>
            <input
              type="number"
              className="form-control"
              id="Téléphone"
              placeholder="Téléphone"
              required
              name="ponderation"
            />
          </div>
        </div>
        <div className="col-md-6 text-end">
          <div className="form-group pt-4">
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              disabled={loading}
            >
              {loading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> Enregistrement en
                  cours...
                </>
              ) : (
                <>
                  <i className="fas fa-save"></i> Enregistrer
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormCourses;
