/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { StudentContext } from "../context/StudentContext";
import { AdminContext } from "../context/AdminContext";
import { DataClasses } from "../Layouts/Data/DataStudent";
import Sidebar from "../Includes/Sidebar";
import Footer from "../Includes/Footer";

const AdminPanel = () => {
  const { createClasse } = useContext(StudentContext);
  const [price, setPrice] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState([]);
  const { createAnneeAcademique, getAnneeAc, getShoolPrice, createShoolPrice } =
    useContext(AdminContext);
  const [loading, setLoading] = useState(false); // État de chargement

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAnneeAc();
        const data1 = await getShoolPrice();
        setPrice(data1); // Stockez les données récupérées dans price
        setRecords(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [getAnneeAc, getShoolPrice]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const niveau = e.target.niveau.value;
    const classe = e.target.classe.value;

    let formData = new FormData();
    formData.append("classe", classe);
    formData.append("niveau", niveau);

    setLoading(true);
    try {
      classe.length > 0 && createClasse(formData);
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Remettre loading à false après la fin du processus
    }
  };
  const handleSubmitPrice = async (e) => {
    e.preventDefault();
    const insc_prix = e.target.insc_prix.value;
    const frais_generaux = e.target.frais_generaux.value;

    let formData = new FormData();
    formData.append("insc_prix", insc_prix);
    formData.append("frais_generaux", frais_generaux);
    setLoading(true);
    try {
      createShoolPrice(formData);
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Remettre loading à false après la fin du processus
    }
  };
  const record = records.length > 0 ? records[0] : null;
  const schoolPrice = price.length > 0 ? price[0] : null;

  const handleSubmitAC = async (e) => {
    e.preventDefault();
    const date = e.target.date.value;

    let formData = new FormData();
    formData.append("date", date);
    setLoading(true);
    try {
      date.length > 0 && createAnneeAcademique(formData);
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Remettre loading à false après la fin du processus
    }
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header mt-3">
            <div className="container-fluid">
              <div className="row mb-2 mt-3">
                <div className="col-sm-6">
                  <h1 className="m-0">Panel Administrateur</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">Tableau de bord</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Panel Administrateur
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <section className="content">
            <div className="container-fluid col-12">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6">
                  <div className="info-box">
                    <span className="info-box-icon bg-info elevation-1"></span>

                    <div className="info-box-content">
                      <span className="info-box-text">Année Academique</span>
                      <span className="info-box-number">
                        <form
                          onSubmit={handleSubmitAC}
                          encType="multipart/form-data"
                        >
                          <div className="row">
                            <label htmlFor="annee">Année</label>
                            <div className="col-8">
                              <input
                                type="text"
                                className="form-control"
                                id="annee"
                                placeholder="nouvelle année academique"
                                name="date"
                                required
                              />
                            </div>
                            <div className="col-4">
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm"
                                disabled={loading}
                              >
                                {loading ? (
                                  <>
                                    <i className="fas fa-spinner fa-spin"></i>{" "}
                                    Ajout en cours...
                                  </>
                                ) : (
                                  <>
                                    <i className="fas fa-save"></i> Ajouter
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </span>
                      <span className="info-box-number pb-0">
                        <small className="mb-0 ">
                          Année Academique en cours
                        </small>
                        <br />
                        <p className="mb-1 mt-0 text-info">
                          <span>{record ? record.date : "Chargement..."}</span>
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 ">
                  <div className="info-box mb-3">
                    <span className="info-box-icon bg-danger elevation-1">
                      <i className="fas fa-dollar"></i>
                    </span>
                    <div className="info-box-content">
                      <span className="info-box-text">Gestion des Frais</span>
                      <span className="info-box-number">
                        <form
                          onSubmit={handleSubmitPrice}
                          encType="multipart/form-data"
                        >
                          <div className="row">
                            <div className="col-12 mb-1">
                              <input
                                type="number"
                                className="form-control"
                                id="annee"
                                placeholder="Inscription"
                                name="insc_prix"
                              />
                            </div>

                            <div className="col-8 mb-1">
                              <input
                                type="number"
                                className="form-control"
                                id="annee"
                                placeholder="Frais generaux"
                                name="frais_generaux"
                              />
                            </div>
                            <div className="col-4 text-end">
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm"
                                disabled={loading}
                              >
                                {loading ? (
                                  <>
                                    <i className="fas fa-spinner fa-spin"></i>
                                    en cours...
                                  </>
                                ) : (
                                  <>
                                    <i className="fas fa-save"></i> Enregistrer
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </span>
                      <span className="info-box-number pb-0">
                        <small className="mb-0 ">Frais Actuel</small>
                        <br />
                        <p className="mb-0 mt-0 text-info">
                          <small className="mb-0 ml-3">Incription:</small>{" "}
                          {schoolPrice
                            ? schoolPrice.insc_prix
                            : "Chargement..."}
                          gdes <small className="mb-0 ml-5">Frais:</small>{" "}
                          {schoolPrice
                            ? schoolPrice.frais_generaux
                            : "Chargement..."}
                          gdes
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Gestion des Classes</h5>

                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-tool dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            <i className="fas fa-wrench"></i>
                          </button>
                          <div
                            className="dropdown-menu dropdown-menu-right"
                            role="menu"
                          >
                            <a href="#" className="dropdown-item">
                              Action
                            </a>
                            <a href="#" className="dropdown-item">
                              Another action
                            </a>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="remove"
                        >
                          <i className="fas fa-times"></i>
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <p className="text-center">
                            <strong>-</strong>
                          </p>
                        </div>
                        <div className="col-md-6">
                          <div className="progress-group">
                            Nouvelle Classe
                            <div className="progress progress-sm">
                              <div
                                className="progress-bar bg-secondary"
                                style={{ width: "100%" }}
                              ></div>
                            </div>
                            <form
                              className="card mt-3 p-2"
                              onSubmit={handleSubmit}
                              encType="multipart/form-data"
                            >
                              <div className="row">
                                <div className="col-12 mb-2">
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label htmlFor="exampleSelectGender">
                                        Niveau
                                      </label>
                                      <select
                                        className="form-control"
                                        id="exampleSelectGender"
                                        name="niveau"
                                      >
                                        <option selected readonly></option>
                                        <option>NSI</option>
                                        <option>NSII</option>
                                        <option>NSIII</option>
                                        <option>NSIV</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label htmlFor="exampleSelectGender">
                                        Classe
                                      </label>

                                      <input
                                        type="text"
                                        className="form-control"
                                        id="annee"
                                        placeholder="Nom de la classe"
                                        name="classe"
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-sm"
                                    disabled={loading}
                                  >
                                    {loading ? (
                                      <>
                                        <i className="fas fa-spinner fa-spin"></i>{" "}
                                        Enregistrement en cours...
                                      </>
                                    ) : (
                                      <>
                                        <i className="fas fa-save"></i>{" "}
                                        Enregistrer
                                      </>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="progress-group">
                            Liste des Classes
                            <div className="progress progress-sm">
                              <div
                                className="progress-bar bg-primary"
                                style={{ width: "100%" }}
                              ></div>
                            </div>
                            <div className="row">
                              <div className="clearfix hidden-md-up"></div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="card">
                                  <DataClasses />

                                  <div className="card-footer text-center"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AdminPanel;
