import { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../Includes/Footer";
import Sidebar from "../../Includes/Sidebar";
import AuthContext from "../../context/AuthContext";
import { CourseContext } from "../../context/CourseContext";

function CourseEdit() {
  const { getCourseById, updateCourses } = useContext(CourseContext);
  const { user } = useContext(AuthContext);
  const [records, setRecords] = useState();
  const location = useLocation();
  const { course_id } = location.state || {};
  const [loading, setLoading] = useState(false); // État de chargement

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        getCourseById(course_id).then((data) => {
          setRecords(data); // Mettez à jour records avec les données récupérées
        });
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };
    fetchClasses();
  }, [course_id, getCourseById]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("created_by", user.user_id);

    setLoading(true);
    try {
      updateCourses(records.id, formData);
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Remettre loading à false après la fin du processus
    }
  };

  return (
    <>
      <Sidebar />
      <div className="content-wrapper wow fadeIn " data-wow-delay="0.05s ">
        <section className="content-header mt-3">
          <div className="container-fluid">
            <div className="row mb-2 mt-3">
              <div className="col-sm-6 d-flex align-items-center">
                <div className="bg-blue rounded-lg p-2 text-center">
                  <i className="fas fa-users-cog"></i>
                </div>
                <div className="ml-2">
                  <h3 className="display fw-12">Detail du cours</h3>
                </div>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Tableau de bord</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/courses">Cours</Link>
                  </li>
                  <li className="breadcrumb-item active">Detail</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid">
          <div className="card p-3">
            <div className="col-md-12 text-end">
              <Link to="/courses" className="btn btn-primary rounded-lg">
                <i className="fa fa-arrow-left"></i>
              </Link>
            </div>
            <form
              onSubmit={handleSubmit}
              className="forms-sample"
              encType="multipart/form-data"
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name">Nom Matière</label>
                    <input
                      type="text"
                      value={records?.nom || ""}
                      onChange={(e) =>
                        setRecords({ ...records, nom: e.target.value })
                      }
                      className="form-control"
                      id="name"
                      placeholder="Matière"
                      required
                      name="nom"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name">Description</label>
                    <input
                      type="text"
                      value={records?.description || ""}
                      onChange={(e) =>
                        setRecords({ ...records, description: e.target.value })
                      }
                      className="form-control"
                      id="name"
                      placeholder="Matière"
                      required
                      name="description"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleSelectGender">Niveau</label>
                    <select
                      value={records?.niveau || ""}
                      onChange={(e) =>
                        setRecords({ ...records, niveau: e.target.value })
                      }
                      className="form-control"
                      id="exampleSelectGender"
                      name="niveau"
                    >
                      <option value="" disabled selected>
                        Choisir un Niveau
                      </option>
                      <option>NSI</option>
                      <option>NSII</option>
                      <option>NSIII</option>
                      <option>NSIV</option>
                    </select>
                  </div>
                </div>
                {/*           
        <div className="col-md-5">
          <div className="form-group">
            <label htmlFor="exampleSelectGender">Professeur</label>
            <select value={records?.teachers || ""}
                      onChange={(e) =>
                        setRecords({ ...records, teachers: e.target.value })
                      }className="form-control" id="exampleSelectGender" name='teachers'>
                <option value="" disabled selected>Choisir un professeur</option>
                {Array.isArray(teachers) && teachers.map((teachers) => (
                    <option key={teachers.id} value={teachers.id}>
                        {teachers.nom+' '+teachers.prenom}</option>
                ))}
            </select>

          </div>
        </div>
          <div className="col-md-1 pt-3">
            <div className="form-group mt-3">
              <Link to='/teacher' className='btn btn-success'>
                <i className='fas fa-user-plus'></i>
              </Link>
            </div>
          </div> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputheures_credit3">
                      Nombre d'heure
                    </label>
                    <input
                      value={records?.heures_credit || ""}
                      onChange={(e) =>
                        setRecords({
                          ...records,
                          heures_credit: e.target.value,
                        })
                      }
                      type="number"
                      className="form-control"
                      id="exampleInputheures_credit3"
                      placeholder="heures_credit du Professeur"
                      required
                      name="heures_credit"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="Téléphone">Pondération</label>
                    <input
                      value={records?.ponderation || ""}
                      onChange={(e) =>
                        setRecords({ ...records, ponderation: e.target.value })
                      }
                      type="number"
                      className="form-control"
                      id="Téléphone"
                      placeholder="Téléphone"
                      required
                      name="ponderation"
                    />
                  </div>
                </div>
                <div className="col-md-6 text-end">
                  <div className="form-group pt-4">
                    {" "}
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i> Connexion
                          en cours...
                        </>
                      ) : (
                        <>
                          <i className="fas fa-save"></i> Se Connecter
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CourseEdit;
