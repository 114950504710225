import Footer from "../../Includes/Footer";
import Sidebar from "../../Includes/Sidebar";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { CourseContext } from "../../context/CourseContext";
import {DataNotes} from "../Data/DataNotes";

const swal = require("sweetalert2");

export default function Notes() {
  const {
    getCoursByCode,
    getStudentByMatricule,
    getAvailableTrimestres,
    addOrUpdateNote,
    fetchCoursesByLevel,
  } = useContext(CourseContext);
  const [matricule, setMatricule] = useState({ matricule: "" });
  const [search, setSearch] = useState({ search: "" });
  const [dataCours, setDataCours] = useState();
  const [dataStudent, setDataStudent] = useState();
  const [availableTrimestres, setAvailableTrimestres] = useState([]);
  const [courseCodes, setCourseCodes] = useState([]);
  const [refreshData, setRefreshData] = useState(false); // Ajouter un état pour rafraîchir DataNotes

  const handleLevelChange = async (e) => {
    const level = e.target.value;

    if (level) {
      try {
        const courses = await fetchCoursesByLevel(level);
        setCourseCodes(courses);
      } catch (error) {
        console.error("Erreur lors de la récupération des cours :", error);
      }
    } else {
      setCourseCodes([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const note = e.target.elements.note.value;
    const trimestre = e.target.elements.trimestre.value;

    if (!dataStudent) {
      swal.fire({
        title: "Veuillez rechercher l'élève",
        icon: "warning",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    const student = dataStudent.id;
    const course = dataCours.id;
    if (!trimestre) {
      swal.fire({
        title: "Veuillez sélectionner un trimestre",
        icon: "warning",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    let formData = new FormData();
    formData.append("student", student);
    formData.append("course", course);
    formData.append("note", note);
    formData.append("trimestre", trimestre);

    try {
      const data = await addOrUpdateNote(formData);
      console.log("Note ajoutée avec succès :", data);
      swal.fire({
        title: "Note ajoutée avec succès",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });

      e.target.elements.note.value = "";
      e.target.elements.trimestre.value = "";
      setDataStudent(null);
      setRefreshData(!refreshData); // Mettre à jour l'état pour rafraîchir DataNotes
    } catch (error) {
      console.error("Erreur lors de l'ajout de la note :", error);
      swal.fire({
        title: "Erreur lors de l'ajout de la note",
        text: error.message || "Une erreur s'est produite",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const handleFilter = () => {
    const value = search.search;
    if (value === "") {
      swal.fire({
        title: "Entrer le code du cours",
        icon: "warning",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    let formData = new FormData();
    formData.append("code", value);

    getCoursByCode(formData).then((data) => {
      if (data) {
        setDataCours(data);
      }
    });
  };

  const handleCourseChange = async (e) => {
    const courseCode = e.target.value;
    setSearch({ search: courseCode });

    if (courseCode) {
      try {
        let formData = new FormData();
        formData.append("code", courseCode);

        const data = await getCoursByCode(formData);
        if (data) {
          setDataCours(data);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du cours :", error);
      }
    } else {
      setDataCours(null);
    }
  };

  const handleFilterStudent = () => {
    const value = matricule.matricule;
    if (value === "") {
      swal.fire({
        title: "Entrer matricule élève",
        icon: "warning",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    let formData = new FormData();
    formData.append("matricule", value);

    getStudentByMatricule(formData).then((data) => {
      if (data) {
        handleSelectClick();
        setDataStudent(data);
      }
    });
  };

  const handleSelectClick = async () => {
    if (!dataCours) {
      swal.fire({
        title:
          "Veuillez rechercher le cours et l'élève avant de sélectionner un trimestre",
        icon: "warning",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    try {
      const trimestres = await getAvailableTrimestres(
        dataStudent.id,
        dataCours.id
      );
      setAvailableTrimestres(trimestres);
      console.log(availableTrimestres);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des trimestres disponibles :",
        error
      );
    }
  };

  return (
    <>
      <Sidebar />
      <div className="content-wrapper">
        <section className="content-header mt-3">
          <div className="container-fluid">
            <div className="row mb-2 mt-3">
              <div className="col-sm-6">
                <h1 className="display">Gestion des Notes</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <Link to="/dashboard" className="breadcrumb-item">
                    Tableau de bord
                  </Link>
                  <Link to="/courses" className="breadcrumb-item">
                    Matieres
                  </Link>
                  <li className="breadcrumb-item active">Notes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-md-7 ">
              <div className="card p-0 ">
                <div className="card-header bg-primary">
                  {" "}
                  Rechercher le Cours et l'élève
                </div>
                <div className="card-body p-0 pt-3 pb-5">
                  <form onSubmit={handleSubmit}>
                    <div
                      className="container-lg wow fadeIn  pb-4"
                      data-wow-delay="0.1s "
                    >
                      <div className="row">
                        <div className="col-10 ">
                          <div className="input-group">
                            <select
                              className="form-control"
                              onChange={handleLevelChange}
                            >
                              <option value="">Niveau</option>
                              <option value="NSI">NSI</option>
                              <option value="NSII">NSII</option>
                              <option value="NSIII">NSIII</option>
                              <option value="NSIV">NSIV</option>
                            </select>
                            <select
                              className="form-control"
                              onChange={handleCourseChange}
                              value={search.search}
                            >
                              <option value="">Code Cours</option>
                              {courseCodes.map((course) => (
                                <option key={course.id} value={course.code}>
                                  {course.code}
                                </option>
                              ))}
                            </select>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Code Cours"
                              name="search"
                              value={search.search}
                              onChange={(e) =>
                                setSearch({ search: e.target.value })
                              }
                              required
                              aria-label="Text input with 2 dropdown buttons"
                            />
                          </div>
                        </div>
                        <div className="col-1 ">
                          <button
                            className="btn btn-secondary"
                            onClick={handleFilter}
                          >
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="container-lg wow fadeIn mt-3">
                      <div className="row">
                        <div className="col-10">
                          <div className="input-group">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Entrer le code de l'élève"
                              name="matricule"
                              value={matricule.matricule}
                              onChange={(e) =>
                                setMatricule({ matricule: e.target.value })
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="col-1">
                          <button
                            className="btn btn-primary"
                            onClick={handleFilterStudent}
                          >
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="container-lg wow fadeIn mt-3">
                      <div className="row">
                        <div className="col-10">
                          <div className="input-group">
                            <select
                              className="form-control"
                              onClick={handleSelectClick}
                              name="trimestre"
                              required
                            >
                              <option value="">Trimestre</option>
                              {availableTrimestres.map((trimestre) => (
                                <option key={trimestre} value={trimestre}>
                                  Trimestre {trimestre}
                                </option>
                              ))}
                            </select>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Note"
                              name="note"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-1">
                          <button className="btn btn-success" type="submit">
                            <i class="fas fa-save"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="card mb-0">
                <div className="card-header bg-secondary">
                  <h3 className="card-title">Detail de l'Élève</h3>
                </div>
                <div className="card-body pt-1 pb-1">
                  <div>
                    {dataStudent && (
                      <div>
                        <p className="form-control mt-1 mb-1">
                          Code : {dataStudent.code}
                        </p>
                        <p className="form-control  mt-1 mb-1">
                          Classe : {dataStudent.niveau} {dataStudent.classe}
                        </p>
                      </div>
                    )}
                    {!dataStudent && (
                      <div>
                        <p>Veuillez rechercher un élève</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="card  mb-2">
                <div className="card-header bg-secondary">
                  <h3 className="card-title">Detail du Cours</h3>
                </div>
                <div className="card-body  pt-1 pb-1">
                  <div>
                    {dataCours && (
                      <div>
                        <p className="form-control  mt-1 mb-1">Code: {dataCours.code}</p>
                        <p className="form-control  mt-1 mb-1">
                          Cours: {dataCours.nom}{" "}
                          <span className="float-end fw-bold">
                            {" "}
                            Sur: {dataCours.ponderation}
                          </span>
                        </p>
                      </div>
                    )}
                    {!dataCours && (
                      <div>
                        <p>Veuillez rechercher un cours</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-4">
              <div className="card">
              <DataNotes refreshData={refreshData} /> {/* Passez refreshData comme prop */}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
