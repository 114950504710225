/* eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// import Logo from '../../img/log.png'
import { jwtDecode } from 'jwt-decode'
import AuthContext from '../context/AuthContext'
import { format } from "date-fns";
import { fr } from 'date-fns/locale';
import { AdminContext } from '../context/AdminContext';
import { ImgUrl } from '../context/BaseUrl';
import "./Side.css"
function Sidebar() {
  const { user, logoutUser } = useContext(AuthContext)
  const [records, setRecords] = useState([]);
  const token = localStorage.getItem("authTokens")
  // Utilisez useState pour stocker la date et l'heure de la connexion
  const [loginTime, setLoginTime] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false); // Ajout de l'état dropdownOpen
  const { getAnneeAc } = useContext(AdminContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  if (token) {
    const decoded = jwtDecode(token)
    var user_id = decoded.user_id
    var username = decoded.username
    var role = decoded.role
    var image = decoded.image
  }
  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      const loginDate = new Date(decoded.iat * 1000);
      const formattedDate = format(loginDate, "EE dd MMMM HH:mm", { locale: fr });

      const parts = formattedDate.split(' ');
      const capitalizedDay = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);
      const capitalizedMonth = parts[2].charAt(0).toUpperCase() + parts[2].slice(1);

      const finalFormattedDate = `${capitalizedDay} ${parts[1]} ${capitalizedMonth} | ${parts[3]}`;

      setLoginTime(finalFormattedDate);
    }
    const fetchData = async () => {
      try {
        const data = await getAnneeAc();
        setRecords(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [token, getAnneeAc]);
  const imageUrl = `${ImgUrl}${image}`;

  const record = records.length > 0 ? records[0] : null;


  const [MenuOpen1, setMenuOpen1] = useState(false);

  const toggleMenu1 = () => {
    setMenuOpen1(!MenuOpen1);
  };
  const [MenuOpen2, setMenuOpen2] = useState(false);

  const toggleMenu2 = () => {
    setMenuOpen2(!MenuOpen2);
  };
  const [MenuOpen3, setMenuOpen3] = useState(false);

  const toggleMenu3 = () => {
    setMenuOpen3(!MenuOpen3);
  };
  const [MenuOpen4, setMenuOpen4] = useState(false);

  const toggleMenu4 = () => {
    setMenuOpen4(!MenuOpen4);
  };
  const [MenuOpen6, setMenuOpen6] = useState(false);

  const toggleMenu6 = () => {
    setMenuOpen6(!MenuOpen6);
  };
  const [MenuOpen7, setMenuOpen7] = useState(false);

  const toggleMenu7 = () => {
    setMenuOpen7(!MenuOpen7);
  };
  const TeacherNotView = role !== 'Teacher'; // ce que les prof n'ont pas access
  const DirectorNotView = role !== 'Censeur'; // ce que les prof n'ont pas access

  return (
    <>
      <nav className="main-header navbar navbar-expand navbar-light " style={{ display: 'flex' }}>
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/dashboard" className="nav-link">Tableau de Bord</Link>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">

          <li className="nav-item">
            <h4 className="nav-link position-relative">
              <span className="position-absolute top-50 start-0 translate-middle-y">
                <span className="fs-6" style={{ marginLeft: '0.3rem' }}>Année Académique</span>
                <hr className='hidden text-white' />
              </span>
              {record ? record.date : 'Chargement...'}
            </h4>
          </li>

          <li className="nav-item dropdown">
            <a className="nav-link" href="#" onClick={() => setDropdownOpen(!dropdownOpen)}>
              <i className="fas fa-user"></i>
            </a>
            <div className={`dropdown-menu dropdown-menu-lg dropdown-menu-right ${dropdownOpen ? 'show' : ''}`}>
              <Link to={'/user-edit'} state={{ user_id: user_id }} className="dropdown-item">
                <div className="media">
                  <img src={imageUrl} alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                  <div className="media-body">
                    <h3 className="dropdown-item-title">
                      {username}
                      <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span>
                    </h3>
                    <p className="text-sm text-success">Connecté</p>
                    <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> {loginTime}</p>
                  </div>
                </div>
              </Link>
              <Link to='#' onClick={logoutUser} className="dropdown-item dropdown-footer bg-danger text-white fw-bold">
                <i className='fas fa-sign-out-alt'></i> Déconnecter
              </Link>
            </div>
          </li>


          <li className="nav-item">
            <a className="nav-link" data-widget="fullscreen" href="#" role="button">
              <i className="fas fa-expand-arrows-alt"></i>
            </a>
          </li>

        </ul>
      </nav>
      {/* Sidebar */}

      {/* <aside className="main-sidebar sidebar-dark-primary elevation-4"> */}
      <aside className={`main-sidebar sidebar-dark-primary elevation-4 ${sidebarOpen ? 'open' : ''}`}>
        <Link to="/dashboard" className="brand-link">
          <img src={'/img/log.png'} alt="LFCL Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
          <h4><span className="brand-text fw-bold">LNFCL</span></h4>
        </Link>

        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img src={imageUrl} className="img-circle elevation-2" alt="User Image" />
            </div>
            <div className="info">
              <a href="#" className="d-block">{role}</a>
            </div>
          </div>
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              <li className="nav-item">
                <Link to="/dashboard" className="nav-link">
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p className='fw-bold'>
                    Tableau de bord
                  </p>
                </Link>
              </li>
              {TeacherNotView && (
                <>
                  <li className="nav-header">Élèves</li>
                  <li className={`nav-item ${MenuOpen1 ? 'menu-open' : ''}`}>
                    <a href="#" className="nav-link" onClick={toggleMenu1}>
                      <i className="nav-icon fas fa-user-plus"></i>
                      <p>
                        Gestion Inscriptions
                        <i className="fas fa-angle-left right"></i>
                      </p>
                    </a>
                    <ul className={`nav nav-treeview ${MenuOpen1 ? 'slide-in' : 'slide-out'}`}>
                      <li className="nav-item">
                        <Link to="/inscription" className="nav-link">
                          <i className="fas fa-user-plus nav-icon"></i>
                          <p>Inscription</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/concours" className="nav-link">
                          <i className="fas fa-user-plus nav-icon"></i>
                          <p>Concours</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/reinscription" className="nav-link">
                          <i className="fas fa-sync-alt nav-icon"></i>
                          <p>Reinscription</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className={`nav-item ${MenuOpen2 ? 'menu-open' : ''}`}>
                    <a href="#" className="nav-link" onClick={toggleMenu2}>
                      <i className="nav-icon fas fa-user-graduate"></i>
                      <p>
                        Gestion des Élèves
                        <i className="right fas fa-angle-left"></i>
                      </p>
                    </a>
                    <ul className={`nav nav-treeview ${MenuOpen2 ? 'slide-in' : 'slide-out'}`}>
                      <li className="nav-item">
                        <Link to="/students" className="nav-link">
                          <i className="fas fa-user-graduate nav-icon fa-xs"></i>
                          <p>Gestion des Élèves</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/archives" className="nav-link">
                          <i className="fas fa-archive nav-icon"></i>
                          <p>Gestion des Archives</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/badge" className="nav-link">
                          <i className="fas fa-archive nav-icon"></i>
                          <p>Gestion des Badges</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </>
              )}
              <li className="nav-header">Matières et Notes</li>

              <li className={`nav-item ${MenuOpen3 ? 'menu-open' : ''}`}>
                <a href="#" className="nav-link" onClick={toggleMenu3}>
                  <i className="nav-icon fas fa-book"></i>
                  <p>
                    Gestion des Matières
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className={`nav nav-treeview ${MenuOpen3 ? 'slide-in' : 'slide-out'}`}>
                  {TeacherNotView && (
                    <>
                      <li className="nav-item">
                        <Link to="/courses" className="nav-link">
                          <i className="fas fa-book nav-icon"></i>
                          <p>Matières</p>
                        </Link>
                      </li>
                    </>
                  )}

                  <li className="nav-item">
                    <Link to="/notes" className="nav-link">
                      <i className="fas fa-list nav-icon"></i>
                      <p>Notes</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a href="" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Horaire</p>
                    </a>
                  </li>
                  {TeacherNotView && (
                    <>
                      <li className="nav-item">
                        <Link to="/bulletin" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Bulletins</p>
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </li>
              {TeacherNotView && (
                <>
                  <li className="nav-header">PERSONNELS</li>
                  <li className={`nav-item ${MenuOpen4 ? 'menu-open' : ''}`}>
                    <a href="#" className="nav-link" onClick={toggleMenu4}>
                      <i className="nav-icon fas fa-users-cog"></i>
                      <p>
                        Ressources Humaines
                        <i className="fas fa-angle-left right"></i>
                      </p>
                    </a>
                    <ul className={`nav nav-treeview ${MenuOpen4 ? 'slide-in' : 'slide-out'}`}>
                      <li className="nav-item">
                        <Link to="/teacher" className="nav-link">
                          <i className="fas fa-chalkboard-teacher nav-icon"></i>
                          <p>Professeurs</p>
                        </Link>
                      </li>
                      {DirectorNotView && (
                        <>
                          <li className="nav-item">
                            <Link to="/users" className="nav-link">
                              <i className="fas fa-user nav-icon"></i>
                              <p>Utilisateurs</p>
                            </Link>
                          </li>
                        </>

                      )}
                    </ul>
                  </li>
                  {DirectorNotView && (
                    <>
                      <li className="nav-header">ECONOMIES</li>
                      <li className={`nav-item ${MenuOpen6 ? 'menu-open' : ''}`}>
                        <a href="#" className="nav-link" onClick={toggleMenu6}>
                          <i className="nav-icon fas fa-user-plus"></i>
                          <p>
                            Gestion Paiements
                            <i className="fas fa-angle-left right"></i>
                          </p>
                        </a>
                        <ul className={`nav nav-treeview ${MenuOpen6 ? 'slide-in' : 'slide-out'}`}>
                          <li className="nav-item">
                            <Link to="/paiements-eleves" className="nav-link">
                              <i className="fas fa-user-plus nav-icon"></i>
                              <p> Paiment Élèves</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-header">PARAMETRES SYSTEME</li>
                      <li className={`nav-item ${MenuOpen7 ? 'menu-open' : ''}`}>
                        <a href="#" className="nav-link" onClick={toggleMenu7}>
                          <i className="nav-icon fas fa-tools"></i>
                          <p>
                            Parametres
                            <i className="fas fa-angle-left right"></i>
                          </p>
                        </a>
                        <ul className={`nav nav-treeview ${MenuOpen7 ? 'slide-in' : 'slide-out'}`}>
                          <li className="nav-item">
                            <Link to="/admin-settings" className="nav-link">
                              <i className="fas fa-tools nav-icon"></i>
                              <p>Panel Administrateur</p>
                            </Link>
                          </li>
                        </ul>
                      </li>

                    </>
                  )}
                </>
              )}
            </ul>
          </nav>
        </div>
      </aside>
      {/* End Sidebar */}
    </>
  )
}

export default Sidebar
