// const baseUrlAdmin = "http://127.0.0.1:8000/api/admin_settings/";
// const baseUrlAuth = "http://127.0.0.1:8000/api/";
// const baseUrlCourse = "http://127.0.0.1:8000/api/courses/";
// const baseUrlCourse2 = "http://127.0.0.1:8000/api/students/";
// const baseUrlPaiement = "http://127.0.0.1:8000/api/paiements/";
// const baseUrlStudent = "http://127.0.0.1:8000/api/students/";
// const baseUrlTeacher = "http://127.0.0.1:8000/api/teachers/";
//  const UrlFilig1 = "http://127.0.0.1:8000/media/filig.png";
//  const UrlHeader1 = "http://127.0.0.1:8000/media/header.png";
//  const Url1 = "http://localhost:8000/";
// const ImgUrl = "http://localhost:8000";

const ImgUrl = "https://api.lnfcl.com";
const Url1 = "https://api.lnfcl.com/";
const UrlFilig1 = "https://api.lnfcl.com/media/filig.png";
const UrlHeader1 = "https://api.lnfcl.com/media/header.png";
const baseUrlTeacher = "https://api.lnfcl.com/api/teachers/";
const baseUrlStudent = "https://api.lnfcl.com/api/students/";
const baseUrlPaiement = "https://api.lnfcl.com/api/paiements/";
const baseUrlCourse = "https://api.lnfcl.com/api/courses/";
const baseUrlCourse2 = "https://api.lnfcl.com/api/students/";
const baseUrlAuth = "https://api.lnfcl.com/api/";
const baseUrlAdmin = "https://api.lnfcl.com/api/admin_settings/";

// const ImgUrl = "https://lnfcl.pythonanywhere.com";
// const Url1 = "https://lnfcl.pythonanywhere.com/";
// const UrlFilig1 = "https://lnfcl.pythonanywhere.com/media/filig.png";
// const UrlHeader1 = "https://lnfcl.pythonanywhere.com/media/header.png";
//  const baseUrlTeacher = "https://lnfcl.pythonanywhere.com/api/teachers/";
//  const baseUrlStudent = "https://lnfcl.pythonanywhere.com/api/students/";
//  const baseUrlPaiement = "https://lnfcl.pythonanywhere.com/api/paiements/";
//  const baseUrlCourse = "https://lnfcl.pythonanywhere.com/api/courses/";
//  const baseUrlCourse2 = "https://lnfcl.pythonanywhere.com/api/students/";
// const baseUrlAuth = "https://lnfcl.pythonanywhere.com/api/";
//  const baseUrlAdmin = "https://lnfcl.pythonanywhere.com/api/admin_settings/";

export {
  baseUrlAdmin,
  baseUrlAuth,
  baseUrlCourse,
  baseUrlCourse2,
  baseUrlPaiement,
  baseUrlStudent,
  baseUrlTeacher,
  UrlFilig1,
  UrlHeader1,
  Url1,
  ImgUrl,
};
