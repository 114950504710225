/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { customStyles } from "./Utility";
import { ExportButton } from "./Data";
import 'jspdf-autotable';
import { TeacherContext } from '../../context/TeacherContext';
import Loader from '../../Includes/loader/loader';
const swal = require('sweetalert2')
// const imageToBase64 = require('image-to-base64');
// Data Inscription

 const DataTeachers = () => {
    const { getTeachers,deleteTeachers } = useContext(TeacherContext); // Récupérez la fonction getTeachers du contexte d'authentification
    const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
    const [records, setRecords] = useState();
    const [loading, setLoading] = useState(true);
  

    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await getTeachers();
          setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
          setRecords(data);
          setLoading(false); 
        } catch (error) {
          setLoading(false);
          console.error(error);
        }
      };
      fetchData();
    }, [getTeachers]);

    if (loading) {
      return <Loader />; // Affiche le loader tant que les données sont en cours de chargement
  }
       const columnsTeacher = [
        {
          name: "ID",
          selector: (row) => row.id,
          sortable: true,
          maxWidth:'50px',
        },
        {
          name: "Code",
          selector: (row) => row.code,
          sortable: true,
        },
      {
        name: "Nom Complet",
        selector: (row) => (
          <div className="d-flex align-items-center">
            <img
              src={row.photo}
              alt="Photo"
              style={{ width: 30, height: 30, borderRadius: "50%", marginRight: 5 }}
            />
            {row.nom+" "+row.prenom}
          </div>
        ),
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Téléphone",
        selector: (row) => row.telephone,
        sortable: true,
      },
      {
        name: "Actions",
        cell: (row) => (
          <div className="btn-group" role="group">
            <Link to={'/detail-teacher'} state={{teacher_id: row.id }} className="btn btn-success btn-sm me-1">
              <i className="fas fa-eye"></i>
            </Link>
            <Link to={'/detail-teacher'} state={{teacher_id: row.id }} className="btn btn-warning btn-sm me-1">
              <i className="fas fa-edit"></i>
            </Link>
            <button onClick={() => handleDelete(row.id)} className="btn btn-danger btn-sm ">
              <i className="fas fa-trash"></i>
            </button>
          </div>
        ),
      },
    ];
  

    function handleFilter(event) {
      const { value } = event.target;
      if (value === "") {
        setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
        return;
      }
      const newData = originalRecords.filter((row) => {
        const nomLower = row.nom.toLowerCase();
        const codeLower = row.code.toLowerCase();
        const prenomLower = row.prenom.toLowerCase();
        const searchLower = value.toLowerCase();
        return nomLower.includes(searchLower) || prenomLower.includes(searchLower)
        || codeLower.includes(searchLower);
      });
    
      setRecords(newData);
    }
    function handleDelete(id) {
      swal.fire({
        title: "Êtes-vous sûr(e) de vouloir supprimer cet élève?",
        text: "Une fois supprimé, vous ne pourrez pas récupérer cet élève!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteTeachers(id); // Assuming userId is defined
          swal.fire("Supprimé!", "Votre élève a été supprimé avec succès.", "success");
          await refreshData();
        }
      });
    }
    
    const refreshData = async () => {
      try {
        const data = await getTeachers();
        setOriginalRecords(data);
        setRecords(data);
      } catch (error) {
        console.error(error);
      }
    };
    
    return (
      <>
                <div className="container-lg wow fadeIn " data-wow-delay="0.1s ">
                  <div className="row">
                  <div className="col-md-6 text-start d-flex align-items-center">
                    {/* <ExportButton onClick={() => handleExport('pdf')}>Exporter en PDF</ExportButton> */}
                  </div>
  
                    <div className="col-md-6 ">
                      <input
                        className="form-control mb-2"
                        type="text"
                        placeholder="Search"
                        onChange={handleFilter}
                      />
                    </div>

            <div className="col-md-12 " >
              <DataTable
                columns={columnsTeacher}
                data={records}
                fixedHeader
                pagination
                customStyles={customStyles}
                paginationPerPage="10"
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page:",
                  rangeSeparatorText: "sur",
                  noRowsPerPage: false,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "Tous",
                }}
                subHeader
                noDataComponent="Aucune donnée trouvée"
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  export default DataTeachers;
  


