/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { customStyles } from "./Utility";
import { CourseContext } from "../../context/CourseContext";
import Loader from "../../Includes/loader/loader";
const swal = require("sweetalert2");

const DataNotes = ({ refreshData }) => {
  const { StudentsTotalNote } = useContext(CourseContext);
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await StudentsTotalNote();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [StudentsTotalNote,refreshData]);
  if (loading) {
    return <Loader />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsCourses = [
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Controle 1",
      selector: (row) => row.total_notes['1er trimestre'],
      sortable: true,
    },
    {
      name: "Controle 2",
      selector: (row) => row.total_notes['2e trimestre'],
      sortable: true,
    },
    {
      name: "Controle 3",
      selector: (row) => row.total_notes['3e trimestre'],
      sortable: true,
    },
    {
      name: "Moyenne",
      cell: (row) => {
        const getButtonClass = (average) => {
          if (average < 5) {
            return "btn btn-danger btn-sm me-1 p-1 mt-1 mb-1";
          } else if (average === 5) {
            return "btn btn-warning btn-sm me-1 p-1 mt-1 mb-1";
          } else {
            return "btn btn-success btn-sm me-1 p-1 mt-1 mb-1";
          }
        };
  
        return (
          <div className="btn-group" role="group">
            <p className={getButtonClass(row.average_notes['1er trimestre'])}>
              {row.average_notes['1er trimestre']}
            </p>
            <p className={getButtonClass(row.average_notes['2e trimestre'])}>
              {row.average_notes['2e trimestre']}
            </p>
            <p className={getButtonClass(row.average_notes['3e trimestre'])}>
              {row.average_notes['3e trimestre']}
            </p>
          </div>
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group" role="group">
          <Link
           to={`/student/detail-note`} state={{student_id: row.id }}
            className="btn btn-primary btn-sm me-1"
          >
            <i className="fas fa-eye"></i>
          </Link>
        </div>
      ),
    },
  ];
  

  const handleFilter = (event) => {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const codeLower = row.code.toLowerCase();
      const searchLower = value.toLowerCase();
      return codeLower.includes(searchLower);
    });

    setRecords(newData);
  };

  return (
    <>
      <div className="container-lg wow fadeIn" data-wow-delay="0.1s">
        <div className="row">
          <div className="col-md-8 text-start d-flex align-items-center"></div>


          <div className="card-header p-0 pl-3 bg-light">
            <div className="row">
              <div className="col-md-6 pt-2 fw-bold"> Liste des Notes</div>
              <div className="col-md-6 float-end">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Rechercher par code "
                  onChange={handleFilter}
                />
              </div>
            </div>
          </div>
          <div className="card-body p-0">
              <DataTable
                columns={columnsCourses}
                data={records}
                fixedHeader
                pagination
                customStyles={customStyles}
                paginationPerPage="10"
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page:",
                  rangeSeparatorText: "sur",
                  noRowsPerPage: false,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "Tous",
                }}
                subHeader
                noDataComponent="Aucune donnée trouvée"
              />
          </div>
        </div>
      </div>
    </>
  );
};

const AllDataBulletin = ({ refreshData,formData }) => {
  const { AllStudentsBulletin } = useContext(CourseContext);
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AllStudentsBulletin(formData);
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [AllStudentsBulletin,refreshData,formData]);
  if (loading) {
    return <Loader />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsCourses = [
    {
      name: "Matricule",
      selector: (row) => row.matricule,
      sortable: true,
    },
    {
      name: "Controle 1",
      selector: (row) => row.total_notes['1er trimestre'],
      sortable: true,
    },
    {
      name: "Controle 2",
      selector: (row) => row.total_notes['2e trimestre'],
      sortable: true,
    },
    {
      name: "Controle 3",
      selector: (row) => row.total_notes['3e trimestre'],
      sortable: true,
    },
    {
      name: "Moyenne",
      cell: (row) => {
        const getButtonClass = (average) => {
          if (average < 5) {
            return "btn btn-danger btn-sm me-1 p-1 mt-1 mb-1";
          } else if (average === 5) {
            return "btn btn-warning btn-sm me-1 p-1 mt-1 mb-1";
          } else {
            return "btn btn-success btn-sm me-1 p-1 mt-1 mb-1";
          }
        };
  
        return (
          <div className="btn-group" role="group">
            <p className={getButtonClass(row.average_notes['1er trimestre'])}>
              {row.average_notes['1er trimestre']}
            </p>
            <p className={getButtonClass(row.average_notes['2e trimestre'])}>
              {row.average_notes['2e trimestre']}
            </p>
            <p className={getButtonClass(row.average_notes['3e trimestre'])}>
              {row.average_notes['3e trimestre']}
            </p>
          </div>
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group" role="group">
          <Link
            to={`/student/detail-note`} state={{student_id: row.id }}
            className="btn btn-primary btn-sm me-1"
          >
            <i className="fas fa-eye"></i>
          </Link>
        </div>
      ),
    },
  ];
  

  const handleFilter = (event) => {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const codeLower = row.code.toLowerCase();
      const searchLower = value.toLowerCase();
      return codeLower.includes(searchLower);
    });

    setRecords(newData);
  };

  return (
    <>
      <div className="container-lg wow fadeIn" data-wow-delay="0.1s">
        <div className="row">
          <div className="col-md-8 text-start d-flex align-items-center"></div>


          <div className="card-header p-0 pl-3 ">
            <div className="row">
              <div className="col-md-6 pt-2 fw-bold"> </div>
              <div className="col-md-6 float-end">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Rechercher par code "
                  onChange={handleFilter}
                />
              </div>
            </div>
          </div>
          <div className="card-body p-0">
              <DataTable
                columns={columnsCourses}
                data={records}
                fixedHeader
                pagination
                customStyles={customStyles}
                paginationPerPage="10"
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page:",
                  rangeSeparatorText: "sur",
                  noRowsPerPage: false,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "Tous",
                }}
                subHeader
                noDataComponent="Aucune donnée trouvée"
              />
          </div>
        </div>
      </div>
    </>
  );
};

const DataNotesDetails = () => {
  const { StudentsTotalNoteByID } = useContext(CourseContext);
  const [studentData, setStudentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { student_id } = location.state || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await StudentsTotalNoteByID(student_id);
        setStudentData(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [StudentsTotalNoteByID, student_id]);

  if (loading) {
    return <Loader />;
  }

  if (!studentData) {
    return <div>No data found</div>;
  }

  // Récupérer les matières du premier trimestre pour obtenir la liste
  const subjects = Object.keys(studentData.notes_by_course['1er trimestre']);
  
  // Formater les données pour DataTable
  const data = subjects.map(subject => {
    const getPonderation = (trimestre) => studentData.notes_by_course[trimestre][subject]?.ponderation || 0;
    const getNote = (trimestre) => studentData.notes_by_course[trimestre][subject]?.note || 'N/A';
    
    const isBelowHalfPonderation = (trimestre) => {
      const note = studentData.notes_by_course[trimestre][subject]?.note || 0;
      const ponderation = studentData.notes_by_course[trimestre][subject]?.ponderation || 0;
      return ponderation > 0 && note < (ponderation / 2);
    };

    return {
      subject,
      '1er trimestre': { note: getNote('1er trimestre'), isBelowHalf: isBelowHalfPonderation('1er trimestre') },
      '2e trimestre': { note: getNote('2e trimestre'), isBelowHalf: isBelowHalfPonderation('2e trimestre') },
      '3e trimestre': { note: getNote('3e trimestre'), isBelowHalf: isBelowHalfPonderation('3e trimestre') }
    };
  });

  // Ajouter une ligne pour les totaux des notes
  const totalRow = {
    subject: 'Total',
    '1er trimestre': { note: studentData.total_notes['1er trimestre'], isBelowHalf: false },
    '2e trimestre': { note: studentData.total_notes['2e trimestre'] || 'N/A', isBelowHalf: false },
    '3e trimestre': { note: studentData.total_notes['3e trimestre'] || 'N/A', isBelowHalf: false }
  };

  data.push(totalRow);

  const columnsCourses = [
    {
      name: "Matières",
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: "1er trimestre",
      selector: (row) => row['1er trimestre'].note,
      sortable: true,
      cell: row => (
        <div className={row['1er trimestre'].isBelowHalf ? 'text-danger fw-bold' : 'fw-bold'}>
          {row['1er trimestre'].note}
        </div>
      ),
    },
    {
      name: "2e trimestre",
      selector: (row) => row['2e trimestre'].note,
      sortable: true,
      cell: row => (
        <div className={row['2e trimestre'].isBelowHalf ? 'text-danger fw-bold' : 'fw-bold'}>
          {row['2e trimestre'].note}
        </div>
      ),
    },
    {
      name: "3e trimestre",
      selector: (row) => row['3e trimestre'].note,
      sortable: true,
      cell: row => (
        <div className={row['3e trimestre'].isBelowHalf ? 'text-danger fw-bold' : 'fw-bold'}>
          {row['3e trimestre'].note}
        </div>
      ),
    }
  ];

  // Déterminer la décision de l'année
  const generalAverage = studentData.general_average;
  const isBelowAverage = generalAverage && parseFloat(generalAverage.replace(',', '.')) < 5;

  return (
    <div className="container-lg wow fadeIn" data-wow-delay="0.1s">
      <div className="row p-0">
        <div className="card-body p-0 mt-0">
          <DataTable
            variant='mt-0 pt-0'
            columns={columnsCourses}
            data={data}
            fixedHeader
            customStyles={customStyles}
            subHeader
            noDataComponent="Aucune donnée trouvée"
          />
        </div>
       
        {generalAverage != null && (
          <div className="card p-0 mb-0">
            <div className="card-header bg-light p-1">
              <h5>Décision de l'année</h5>
            </div>
            <div className="card-body pl-4">
              <div className="form-check form-switch">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  role="switch" 
                  id="promusSwitch" 
                  checked={!isBelowAverage && generalAverage !== 'N/A'}
                  disabled={generalAverage === 'N/A'}
                />
                <label className="form-check-label" htmlFor="promusSwitch">Promus</label>
              </div>
              <div className="form-check form-switch">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  role="switch" 
                  id="redoublerSwitch" 
                  checked={isBelowAverage}
                  disabled={generalAverage === 'N/A'}
                />
                <label className="form-check-label" htmlFor="redoublerSwitch">Redoubler</label>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const DataNotesDetailsToken = () => {
  const { getStudentTotalNotesByToken } = useContext(CourseContext);
  const [studentData, setStudentData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getStudentTotalNotesByToken();
        console.log('Données reçues:', data); // Vérifiez les données reçues
        setStudentData(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Erreur de récupération des données:', error);
      }
    };
    fetchData();
  }, [getStudentTotalNotesByToken]);

  if (loading) {
    return <Loader />;
  }

  if (!studentData) {
    return <div>No data found</div>;
  }

  // Récupérer les matières du premier trimestre pour obtenir la liste
  const subjects = Object.keys(studentData.notes_by_course['1er trimestre']);
  
  // Formater les données pour DataTable
  const data = subjects.map(subject => {
    const getPonderation = (trimestre) => studentData.notes_by_course[trimestre][subject]?.ponderation || 0;
    const getNote = (trimestre) => studentData.notes_by_course[trimestre][subject]?.note || 'N/A';
    
    const isBelowHalfPonderation = (trimestre) => {
      const note = studentData.notes_by_course[trimestre][subject]?.note || 0;
      const ponderation = studentData.notes_by_course[trimestre][subject]?.ponderation || 0;
      return ponderation > 0 && note < (ponderation / 2);
    };

    return {
      subject,
      '1er trimestre': { note: getNote('1er trimestre'), isBelowHalf: isBelowHalfPonderation('1er trimestre') },
      '2e trimestre': { note: getNote('2e trimestre'), isBelowHalf: isBelowHalfPonderation('2e trimestre') },
      '3e trimestre': { note: getNote('3e trimestre'), isBelowHalf: isBelowHalfPonderation('3e trimestre') }
    };
  });

  // Ajouter une ligne pour les totaux des notes
  const totalRow = {
    subject: 'Total',
    '1er trimestre': { note: studentData.total_notes['1er trimestre'], isBelowHalf: false },
    '2e trimestre': { note: studentData.total_notes['2e trimestre'] || 'N/A', isBelowHalf: false },
    '3e trimestre': { note: studentData.total_notes['3e trimestre'] || 'N/A', isBelowHalf: false }
  };

  data.push(totalRow);

  const columnsCourses = [
    {
      name: "Matières",
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: "1er trimestre",
      selector: (row) => row['1er trimestre'].note,
      sortable: true,
      cell: row => (
        <div className={row['1er trimestre'].isBelowHalf ? 'text-danger fw-bold' : 'fw-bold'}>
          {row['1er trimestre'].note}
        </div>
      ),
    },
    {
      name: "2e trimestre",
      selector: (row) => row['2e trimestre'].note,
      sortable: true,
      cell: row => (
        <div className={row['2e trimestre'].isBelowHalf ? 'text-danger fw-bold' : 'fw-bold'}>
          {row['2e trimestre'].note}
        </div>
      ),
    },
    {
      name: "3e trimestre",
      selector: (row) => row['3e trimestre'].note,
      sortable: true,
      cell: row => (
        <div className={row['3e trimestre'].isBelowHalf ? 'text-danger fw-bold' : 'fw-bold'}>
          {row['3e trimestre'].note}
        </div>
      ),
    }
  ];

  // Déterminer la décision de l'année
  const generalAverage = studentData.general_average;
  const isBelowAverage = generalAverage && parseFloat(generalAverage.replace(',', '.')) < 5;

  return (
    <div className="container-lg wow fadeIn" data-wow-delay="0.1s">
      <div className="row p-0">
        <div className="card-body p-0 mt-0">
          <DataTable
            variant='mt-0 pt-0'
            columns={columnsCourses}
            data={data}
            fixedHeader
            customStyles={customStyles}
            subHeader
            noDataComponent="Aucune donnée trouvée"
          />
        </div>
        {generalAverage != null && (
          <div className="card p-0 mb-0">
            <div className="card-header bg-light p-1">
              <h5>Décision de l'année</h5>
            </div>
            <div className="card-body pl-4">
              <div className="form-check form-switch">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  role="switch" 
                  id="promusSwitch" 
                  checked={!isBelowAverage && generalAverage !== 'N/A'}
                  disabled={generalAverage === 'N/A'}
                />
                <label className="form-check-label" htmlFor="promusSwitch">Promus</label>
              </div>
              <div className="form-check form-switch">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  role="switch" 
                  id="redoublerSwitch" 
                  checked={isBelowAverage}
                  disabled={generalAverage === 'N/A'}
                />
                <label className="form-check-label" htmlFor="redoublerSwitch">Redoubler</label>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export {DataNotes,DataNotesDetails,DataNotesDetailsToken,AllDataBulletin};
