import { Link } from "react-router-dom";
import Footer from "../../Includes/Footer";
import Sidebar from "../../Includes/Sidebar";
import { useContext, useEffect, useState } from "react";
import { AdminContext } from "../../context/AdminContext";
import { StudentContext } from "../../context/StudentContext";
import { customStyles } from "../Data/Utility";
import DataTable from "react-data-table-component";
import Loader from "../../Includes/loader/loader";
import { Url1 } from "../../context/BaseUrl";

const baseUrl = Url1;

function Archives() {
  const { getAnneeAcList } = useContext(AdminContext);
  const { getStudentsArchived, getClasses } = useContext(StudentContext);
  const [list, setList] = useState([]);
  const [originalRecords, setOriginalRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [selectedAnneeAcademique, setSelectedAnneeAcademique] = useState("");
  const [selectedClasse, setSelectedClasse] = useState("Toutes les classes");
  const [loading, setLoading] = useState(true);
  const [classes, setClasses] = useState([]);
  const [year, setYear] = useState(""); // Initialize year state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAnneeAcList();
        setList(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchClasses = async () => {
      try {
        const classesData = await getClasses();
        if (Array.isArray(classesData)) {
          setClasses(classesData);
        } else {
          console.error("Classes data is not an array");
        }
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };

    fetchClasses();
    fetchData();
  }, [getAnneeAcList, getClasses]);

  useEffect(() => {
    const fetchStudents = async () => {
      if (selectedAnneeAcademique) {
        setLoading(true);
        try {
          const studentsData = await getStudentsArchived(
            selectedAnneeAcademique
          );
          setRecords(studentsData);
          setOriginalRecords(studentsData);
        } catch (error) {
          console.error("Error fetching students:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchStudents();
  }, [selectedAnneeAcademique, getStudentsArchived]);

  useEffect(() => {
    const filterByClasse = () => {
      if (selectedClasse === "Toutes les classes") {
        setRecords(originalRecords);
      } else {
        const filteredData = originalRecords.filter(
          (row) => row.classe_nom.toLowerCase() === selectedClasse.toLowerCase()
        );
        setRecords(filteredData);
      }
    };

    filterByClasse();
  }, [selectedClasse, originalRecords]);

  const columnsStudent = [
    {
      name: "Nom Complet",
      selector: (row) => (
        <div className="d-flex align-items-center">
          <img
            src={`${baseUrl}${row.photo}`}
            alt="student pic"
            style={{
              width: 30,
              height: 30,
              borderRadius: "50%",
              marginRight: 5,
            }}
          />
          {row.nom + " " + row.prenom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Matricule",
      selector: (row) => row.matricule,
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Classe",
      selector: (row) => (
        <div className="d-flex align-items-start">
          {row.niveau + " " + row.classe_nom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group" role="group">
          <Link
            to="/student/archive/profile"
            state={{ year: year, student_id: row.id }}
            className="btn btn-success btn-sm me-1"
          >
            <i className="fas fa-eye"></i>
          </Link>
          <Link
            to="/archived-student-results/detail"
            state={{ year: year, matricule: row.matricule }}
            className="btn btn-warning btn-sm w-100 me-1"
          >
            Bulletin
          </Link>
        </div>
      ),
    },
  ];

  function handleAnneeAcademiqueChange(event) {
    const selectedYear = event.target.value;
    setSelectedAnneeAcademique(selectedYear);
    setYear(selectedYear); // Update the year state
  }

  function handleClasseChange(event) {
    setSelectedClasse(event.target.value);
  }

  function handleFilter(event) {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords);
      return;
    }
    const newData = originalRecords.filter((row) => {
      const matriculeLower = row.matricule.toLowerCase();
      const nomLower = row.nom.toLowerCase();
      const prenomLower = row.prenom.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) ||
        prenomLower.includes(searchLower) ||
        matriculeLower.includes(searchLower)
      );
    });

    setRecords(newData);
  }

  return (
    <div className="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2 mt-3">
                <div className="col-sm-6 d-flex align-items-center">
                  <div className="bg-blue rounded-lg p-2 text-center">
                    <i className="fas fa-users-cog"></i>
                  </div>
                  <div className="ml-2">
                    <h3 className="display fw-12">Gestion des Archives</h3>
                  </div>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      <Link to="/students">Élèves</Link>
                    </li>
                    <li className="breadcrumb-item active">Archives</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="clearfix hidden-md-up"></div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-6">
                          <div className="input-group">
                            <select
                              className="form-control"
                              onChange={handleAnneeAcademiqueChange}
                              value={selectedAnneeAcademique}
                            >
                              <option value="" disabled>
                                Année académique
                              </option>
                              {list &&
                                list.map((annee, index) => (
                                  <option key={index} value={annee}>
                                    {annee}
                                  </option>
                                ))}
                            </select>
                            <select
                              className="form-control"
                              onChange={handleClasseChange}
                              value={selectedClasse}
                            >
                              <option value="Toutes les classes">
                                Toutes les classes
                              </option>
                              {classes.map((classe, index) => (
                                <option key={index} value={classe.classe}>
                                  {classe.classe}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Rechercher par Nom, Prénom ou Matricule"
                            onChange={handleFilter}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="card-body p-0 m-0">
                      {loading ? (
                        <Loader />
                      ) : (
                        <DataTable
                          columns={columnsStudent}
                          data={records}
                          fixedHeader
                          pagination
                          customStyles={customStyles}
                          paginationPerPage={10}
                          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                          paginationComponentOptions={{
                            rowsPerPageText: "Lignes par page:",
                            rangeSeparatorText: "sur",
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: "Tous",
                          }}
                          subHeader
                          noDataComponent="Aucune donnée trouvée"
                        />
                      )}
                    </div>

                    <div className="card-footer text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Archives;
