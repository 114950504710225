/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom/";
import Sidebar from "../../Includes/Sidebar";
import { Link } from "react-router-dom";
import { CourseContext } from "../../context/CourseContext";
import { DataNotes, DataNotesDetails } from "../Data/DataNotes";
import Loader from "../../Includes/loader/loader";
import { Url1 } from "../../context/BaseUrl";

const Url2 = Url1;

function DetailsNote() {
  const { StudentsTotalNoteByID } = useContext(CourseContext);
  const [records, setRecords] = useState();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { student_id } = location.state || {};

  useEffect(() => {
    const studentId = student_id;
    StudentsTotalNoteByID(studentId).then((data) => {
      setRecords(data); // Mettez à jour records avec les données récupérées
      setLoading(false);
    });
  }, [StudentsTotalNoteByID, student_id]);

  return (
    <div className="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2 mt-3">
                <div className="col-sm-6 d-flex align-items-center">
                  <div className="bg-blue rounded-lg p-2 text-center">
                    <i className="fas fa-users-cog"></i>
                  </div>
                  <div className="ml-2">
                    <h3 className="display fw-12">Gestion des Élèves</h3>
                  </div>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-end ">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/courses">Gestion de Matières</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/notes">Notes</Link>
                    </li>

                    <li className="breadcrumb-item active">Details</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <section className="content wow fadeIn " data-wow-delay="0.05s ">
            <div className="container-fluid">
              <div className="row pt-2">
                <div className="col-md-3">
                  <div className="card card-primary card-outline">
                    <div className="card-header p-1 text-center fw-bold bg-primary ">
                      Profil de l'élève
                    </div>
                    {loading ? (
                      <Loader />
                    ) : (
                      <div className="card-body box-profile">
                        <div className="text-center">
                          <img
                            className="profile-user-img img-fluid img-circle"
                            src={`${Url2}${records?.photo}` || ""}
                            alt="User profile"
                          />
                        </div>

                        <h3 className="profile-username text-center">
                          {records?.nom + " " + records?.prenom || ""}
                        </h3>

                        <p className="text-center mb-0 p-0">Matricule</p>
                        <p className="text-muted text-center m-0 p-0">
                          {records?.matricule || ""}
                        </p>

                        <p className="text-center mb-0">Classe</p>
                        <p className="text-muted text-center ">
                          {records?.niveau + " " + records?.classe || ""}
                        </p>

                        <ul className="list-group list-group-unbordered mb-3">
                          Moyenne
                          <li className="list-group-item">
                            <b>1er Trimestre</b>{" "}
                            <a className="float-right">
                              {records?.average_notes["1er trimestre"] || ""}
                            </a>
                          </li>
                          <li className="list-group-item">
                            <b>2e Trimestre</b>{" "}
                            <a className="float-right">
                              {records?.average_notes["2e trimestre"] || ""}
                            </a>
                          </li>
                          <li className="list-group-item">
                            <b>3e Trimestre</b>{" "}
                            <a className="float-right">
                              {records?.average_notes["3e trimestre"] || ""}
                            </a>
                          </li>
                        </ul>

                        <a href="" className="btn btn-primary btn-block ">
                          <b>Moyenne général:</b>{" "}
                          <a className="float-right text-white">
                            {records?.general_average || ""}
                          </a>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="card">
                    <div className="card-header p-1 text-center fw-bold bg-primary mb-0">
                      <div className="row">
                        <div className="col-md-6 text-start">
                          Palmares # : {records?.code || ""}
                        </div>

                        <div className="col-md-6 mb-0 text-end">
                          <Link to="/notes" className="btn btn-warning btn-sm">
                            <i className="fas fa-list"></i> Gestion des Notes
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="card-body m-0 p-0">
                      <DataNotesDetails />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default DetailsNote;
