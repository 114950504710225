/* eslint-disable */
import Footer from "../../Includes/Footer";
import Sidebar from "../../Includes/Sidebar";
import { Link } from "react-router-dom";
import { StudentContext } from "../../context/StudentContext";
import { useContext, useEffect, useState } from "react";
import { ExportDropdown, customStyles } from "../Data/Utility";
import { Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";

export default function Concours() {
  const { getInscriptions, getInscriptionsById, createConcours } =
    useContext(StudentContext); // Récupérez la fonction getInscriptions du contexte d'authentification
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [dataConcours, setDataConcours] = useState();
  const [selectedClasse, setSelectedClasse] = useState("Toutes les classes"); // État pour suivre la classe sélectionnée

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getInscriptions();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [getInscriptions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const matricule = e.target.matricule.value;
    const note = e.target.note.value;

    let formData = new FormData();
    formData.append("matricule", matricule);
    formData.append("note", note);

    note > 0 && createConcours(formData);
    refreshData();
  };
  const columnsInscription = [
    {
      name: "Matricule",
      selector: (row) => row.matricule,
      sortable: true,
    },
    {
      name: "Nom Complet",
      selector: (row) => (
        <div className="d-flex align-items-center">
          <img
            src={row.photo}
            alt="profile"
            style={{
              width: 30,
              height: 30,
              borderRadius: "50%",
              marginRight: 5,
            }}
          />
          {row.nom + " " + row.prenom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group" role="group">
          <button
            onClick={() => handleAddNote(row.id)}
            className="btn btn-primary btn-sm me-1"
          >
            <i className="fas fa-plus"></i> Ajouter note
          </button>
        </div>
      ),
    },
  ];
  function handleAddNote(id) {
    getInscriptionsById(id).then((data) => {
      setDataConcours(data); // Mettez à jour records avec les données récupérées
    });
  }
  function handleFilter(event) {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const matriculeLower = row.matricule.toLowerCase();
      const nomLower = row.nom.toLowerCase();
      const prenomLower = row.prenom.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) ||
        prenomLower.includes(searchLower) ||
        matriculeLower.includes(searchLower)
      );
    });

    setRecords(newData);
  }
  const refreshData = async () => {
    try {
      const data = await getInscriptions();
      setOriginalRecords(data);
      setRecords(data);
    } catch (error) {
      console.error(error);
    }
  };
  function handleFilterByClasse(classe_nom) {
    setSelectedClasse(classe_nom);
    if (classe_nom === "Toutes les classes") {
      setRecords(originalRecords);
    } else {
      const newData = originalRecords.filter(
        (row) => row.classe_nom.toLowerCase() === classe_nom.toLowerCase()
      );
      setRecords(newData);
    }
  }
  return (
    <>
      <Sidebar />
      <div className="content-wrapper">
        <section className="content-header mt-3">
          <div className="container-fluid">
            <div className="row mb-2 mt-3">
              <div className="col-sm-6">
                <h1 className="display">Gestion des Concours</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <Link to="/dashboard" className="breadcrumb-item">
                    <a href="#">Tableau de bord</a>
                  </Link>
                  <li className="breadcrumb-item active">Concours</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Liste des élèves inscrit</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus"></i>
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="container-lg wow fadeIn "
                    data-wow-delay="0.1s "
                  >
                    <div className="row">
                      <div className="col-md-6 text-start d-flex align-items-center">
                        <ExportDropdown
                          id="dropdown-item-button"
                          title={`Trier par ${selectedClasse}`}
                          menualign="right"
                          variant="outline-primary btn btn-primary btn-sm mb-0"
                        >
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Toutes les classes")
                            }
                          >
                            Toutes les classes
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Antenor Firmin")
                            }
                          >
                            NSI Antenor Firmin
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Oswald Durand")
                            }
                          >
                            NSI Oswald Durand
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Jacques Stephen Alexis")
                            }
                          >
                            NSI Jacques Stephen Alexis
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Demesvar Delorme")
                            }
                          >
                            NSII Demesvar Delorme
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Jean Price Mars")
                            }
                          >
                            NSII Jean Price Mars
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => handleFilterByClasse("Emile Nau")}
                          >
                            NSII Emile Nau
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Etzer Vilaire")
                            }
                          >
                            NSIII Etzer Vilaire
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Catherine Flon")
                            }
                          >
                            NSIII Catherine Flon
                          </Dropdown.Item>
                        </ExportDropdown>
                      </div>

                      <div className="col-md-6 ">
                        <input
                          className="form-control mb-2"
                          type="text"
                          placeholder="Search"
                          onChange={handleFilter}
                        />
                      </div>

                      <div className="col-md-12 ">
                        <DataTable
                          columns={columnsInscription}
                          data={records}
                          fixedHeader
                          pagination
                          customStyles={customStyles}
                          paginationPerPage="5"
                          paginationRowsPerPageOptions={[5, 10, 20, 30, 50]}
                          paginationComponentOptions={{
                            rowsPerPageText: "Lignes par page:",
                            rangeSeparatorText: "sur",
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: "Tous",
                          }}
                          subHeader
                          noDataComponent="Aucune donnée trouvée"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Note Concours</h3>

                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus"></i>
                    </button>
                  </div>
                </div>

                <form
                  className="card-body"
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                >
                  <div className="form-group">
                    <label for="inputEstimatedBudget">Matricule</label>
                    <input
                      type="text"
                      id="inputEstimatedBudget"
                      readOnly
                      className="form-control"
                      value={dataConcours?.matricule || ""}
                      step="1"
                      name="matricule"
                    />
                  </div>
                  <div className="form-group">
                    <label for="inputSpentBudget">Nom Complet</label>
                    <input
                      type="text"
                      id="inputSpentBudget"
                      readOnly
                      className="form-control"
                      value={`${dataConcours?.nom || ""} ${
                        dataConcours?.prenom || ""
                      }`}
                      step="1"
                    />
                  </div>
                  <div className="form-group">
                    <label for="inputEstimatedDuration">CLasse</label>
                    <input
                      type="text"
                      id="inputEstimatedDuration"
                      readOnly
                      className="form-control"
                      value={dataConcours?.classe_nom || ""}
                      step="0.1"
                    />
                  </div>
                  <div className="form-group">
                    <label for="inputEstimatedDuration">Note</label>
                    <input
                      type="number"
                      id="inputEstimatedDuration"
                      required
                      className="form-control"
                      step="0.1"
                      name="note"
                    />
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary">
                      Enregistrer
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
