/* eslint-disable */
import React from 'react'
import { Helmet } from 'react-helmet'

const About = () => {
  return (
    <>
      <Helmet>
        <title>A propos de nous - Lycée François Cappoix de Limonade</title>
        <meta name="description" content="Découvrez le Lycée François Cappoix de Limonade, un établissement éducatif dédié à l'épanouissement des élèves. Apprenez-en plus sur notre équipe, nos valeurs et nos activités culturelles." />
        <meta name="keywords" content="lycée, éducation, développement des élèves, culture, apprenants, Limonade, François Cappoix" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="container-xxl py-5" id="about">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.3s">
              {/* Titre principal H1 */}
              <h1 className="display-5 mb-4">A propos de nous</h1>
              
              {/* Sous-titre H2 */}
              <h2 className="display fw-500 mb-4">En savoir plus sur notre travail et nos activités culturelles</h2>
              
              {/* Paragraphe optimisé pour le SEO */}
              <p>Lycée National François Cappoix de Limonade, un lieu où l'épanouissement et l'apprentissage s'unissent pour créer une expérience éducative inoubliable pour nos élèves. Depuis de nombreuses années, nous nous engageons à offrir un environnement chaleureux, stimulant et sûr pour les élèves, où ils peuvent explorer, grandir et s'épanouir.</p>
              
              <p className="mb-4">Notre équipe passionnée d'éducateurs dévoués est déterminée à cultiver l'amour de l'apprentissage chez chaque élève. Nous croyons en une approche holistique qui valorise leur développement cognitif, social, émotionnel et physique. En encourageant la curiosité naturelle de nos élèves, nous construisons les fondations solides nécessaires pour leur réussite future.</p>
              
              <div className="row g-4 align-items-center">
                <div className="col-sm-6">
                  <a className="btn btn-primary rounded-pill py-3 px-5" href="#">Lire plus</a>
                </div>
                <div className="col-sm-6">
                  <div className="d-flex align-items-center">
                    {/* Optionnel: Image de l'équipe ou du fondateur */}
                    {/* <img loading="lazy" className="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: '45px', height: '45px' }} /> */}
                    <div className="ms-3">
                      {/* <h6 className="text-primary mb-1">Nom du Fondateur</h6>
                      <small>Fondateur</small> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 about-img wow fadeInLeft" data-wow-delay="0.4s">
              <div className="row">
                <div className="col-12 text-center">
                  <img loading="lazy" className="img-fluid w-75 rounded-circle bg-light p-3" src='./img/about-1.png' alt="Lycée François Cappoix de Limonade - Vision et Mission" />
                </div>
                <div className="col-6 text-start wow fadeInUp" data-wow-delay="0.6s" style={{ marginTop: '-150px' }}>
                  <img loading="lazy" className="img-fluid w-100 rounded-circle bg-light p-3" src='./img/about-2.png' alt="Activités culturelles au Lycée François Cappoix" />
                </div>
                <div className="col-6 text-end wow fadeInUp" data-wow-delay="0.7s" style={{ marginTop: '-150px' }}>
                  <img loading="lazy" className="img-fluid w-100 rounded-circle bg-light p-3" src='./img/about-3.png' alt="Elèves du Lycée François Cappoix de Limonade" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
