/*eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { CourseContext } from "../../context/CourseContext";
import { DataNotesDetailsToken } from "../Data/DataNotes";
import Loader from "../../Includes/loader/loader";
import { AdminContext } from "../../context/AdminContext";
import AuthContext from "../../context/AuthContext";
import { Url1 } from "../../context/BaseUrl";

const Url = Url1;

function StudentResult() {
  const { getStudentTotalNotesByToken } = useContext(CourseContext);
  const [records, setRecords] = useState({});
  const [loading, setLoading] = useState(true);
  const { getAnneeAcStudent } = useContext(AdminContext);
  const { logoutUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const notesData = await getStudentTotalNotesByToken();
        const anneeAcData = await getAnneeAcStudent();
        setRecords((prevRecords) => ({
          ...notesData,
          anneeAc: anneeAcData,
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [getStudentTotalNotesByToken, getAnneeAcStudent]);

  const record = records || {};

  // Fonction pour déterminer la classe de la couleur en fonction de la moyenne
  const getAverageClass = (average) => {
    return average < 5 ? "text-danger font-weight-bold" : "font-weight-bold";
  };

  return (
    <>
      <nav className="navbar-light pl-3 pb-0 pr-3" style={{ display: "flex" }}>
        <ul className="navbar-nav">
          <li className="nav-item pt-2">
            <a className="nav-link" href="#" onClick={logoutUser} role="button">
              <img
                src="./img/log.png"
                className="me-2"
                alt="School Logo"
                style={{ width: "50px", height: "auto" }}
              />
              <span className="display-0 text-primary mt-3">LNFCL</span>
            </a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto p-0 m-0">
          <li className="nav-item">
            <h4 className="nav-link position-relative">
              <span className="start-0 translate-middle-y">
                <span className="fs-6" style={{ marginLeft: "-0.3rem" }}>
                  Année Académique
                </span>
                <br />
              </span>
              {record.anneeAc ? record.anneeAc[0].date : "Chargement..."}
              <button
                onClick={logoutUser}
                className="btn btn-danger btn-sm float-end mb-1 mt-0 ml-3"
              >
                <i className="fas fa-sign-out-alt"></i>{" "}
              </button>
            </h4>
          </li>
        </ul>
      </nav>
      <div className="content">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 mt-0 pt-0 ">
              <div className="col-sm-12 pt-0 mt-0"></div>
              <div className="col-sm-6 d-flex align-items-center text-end">
                <div className="col-sm-6 bg-success rounded-lg pt-0 pb-1 text-center display-0">
                  <i className="fas fa-list"></i> Gestion des Résultats
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="content wow fadeIn" data-wow-delay="0.05s">
          <div className="container-fluid">
            <div className="row pt-2">
              <div className="col-md-3">
                <div className="card card-primary card-outline">
                  <div className="card-header p-1 text-center fw-bold bg-primary">
                    Votre Profil
                  </div>
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="card-body box-profile">
                      <div className="text-center">
                        <img
                          className="profile-user-img img-fluid img-circle"
                          src={`${Url}${record.photo}` || ""}
                          alt="User profile"
                        />
                      </div>
                      <h3 className="profile-username text-center">
                        {record.nom + " " + record.prenom || ""}
                      </h3>
                      <p className="text-center mb-0 p-0">Matricule</p>
                      <p className="text-muted text-center m-0 p-0">
                        {record.matricule || ""}
                      </p>
                      <p className="text-center mb-0">Classe</p>
                      <p className="text-muted text-center ">
                        {record.niveau + " " + record.classe || ""}
                      </p>
                      <ul className="list-group list-group-unbordered mb-3">
                        Moyenne
                        <li className="list-group-item">
                          <b>1er Trimestre</b>{" "}
                          <a
                            className={`float-right ${getAverageClass(
                              record.average_notes?.["1er trimestre"] || 0
                            )}`}
                          >
                            {record.average_notes?.["1er trimestre"] || "N/A"}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>2e Trimestre</b>{" "}
                          <a
                            className={`float-right ${getAverageClass(
                              record.average_notes?.["2e trimestre"] || 0
                            )}`}
                          >
                            {record.average_notes?.["2e trimestre"] || "N/A"}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>3e Trimestre</b>{" "}
                          <a
                            className={`float-right ${getAverageClass(
                              record.average_notes?.["3e trimestre"] || 0
                            )}`}
                          >
                            {record.average_notes?.["3e trimestre"] || "N/A"}
                          </a>
                        </li>
                      </ul>
                      <a href="" className="btn btn-primary btn-block">
                        <b>Moyenne générale:</b>{" "}
                        <a
                          className={`float-right ${getAverageClass(
                            record.general_average || 0
                          )} text-white`}
                        >
                          {record.general_average || "N/A"}
                        </a>
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-9">
                <div className="card">
                  <div className="card-header p-1 text-center fw-bold bg-info mb-0">
                    <div className="row">
                      <div className="col-md-12 ">
                      <button className="btn btn-primary btn-sm float-start">
                          <i className="fas fa-list"></i> Notes
                        </button>
                        <button className="btn btn-warning btn-sm float-end">
                          <i className="fas fa-print"></i> Télécharger
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body m-0 p-0">
                    <DataNotesDetailsToken />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default StudentResult;
