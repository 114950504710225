/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { ExportDropdown, customStyles } from "./Utility";
import { StudentContext } from "../../context/StudentContext";
import { Dropdown } from "react-bootstrap";
import { ExportButton } from "./Data";
import jsPDFInvoiceTemplate, { OutputType } from "jspdf-invoice-template";
import 'jspdf-autotable'; // Importez jspdf-autotable
import jsPDF from 'jspdf';
import Loader from '../../Includes/loader/loader';
import { CourseContext } from '../../context/CourseContext';
import { AdminContext } from '../../context/AdminContext';
import { baseUrlStudent, Url1 } from '../../context/BaseUrl';
const swal = require('sweetalert2')

const baseUrl2 = Url1;
const baseUrlS = baseUrlStudent;


const generatePDF = async (data) => {
  const doc = new jsPDF();
  // Ajouter l'image en arrière-plan
  doc.addImage(`${baseUrl2}media/filig.png`, "PNG", 0, 0, 210, 297);

  // Logo
  doc.addImage(`${baseUrl2}media/header.png`, "PNG", 0, 0, 212, 48);

  // Ligne horizontale
  doc.setLineWidth(0.4);
  doc.line(0, 48, 210, 48);

  // Texte en gras "Fiche d'inscription"
  doc.setFont("times", "bold");
  doc.setFontSize(16);
  doc.text("Fiche d'inscription", 105, 55, { align: "center" });

  // Créer une table pour les informations d'inscription
  const tableData = [
    [{ content: "Matricule :", styles: { fontStyle: 'bold', halign: 'left' } }, { content: data.matricule, styles: { halign: 'left' } }],
    [{ content: "Nom :", styles: { fontStyle: 'bold', halign: 'left' } }, { content: data.nom, styles: { halign: 'left' } }],
    [{ content: "Prenom:", styles: { fontStyle: 'bold', halign: 'left' } }, { content: data.prenom, styles: { halign: 'left' } }],
    [{ content: "Adresse:", styles: { fontStyle: 'bold', halign: 'left' } }, { content: data.adresse, styles: { halign: 'left' } }],
    [{ content: "Téléphone:", styles: { fontStyle: 'bold', halign: 'left' } }, { content: data.telephone, styles: { halign: 'left' } }],
    // [{ content: "Email:", styles: { fontStyle: 'bold', halign: 'left' } }, { content: data.email, styles: { halign: 'left' } }]
  ];

  // Générer la table
  doc.autoTable({
    startY: 65,
    head: [""],
    body: tableData,
    margin: { left: 60 },
    styles: {
      cellPadding: 2,
      fontSize: 12,
      font: "times" // Appliquer la police Times New Roman à la table
    },
    columnStyles: {
      0: { cellWidth: 40 },
      1: { cellWidth: 'auto' }
    },
    theme: "plain"
  });

  // Texte "Année académique 2024-2025" en haut à droite de la table
  doc.setFont("times", "normal");
  doc.setFontSize(10);
  doc.text(`Année académique ${data.annee_academique}`, 105, 65, { align: "center" });

  // // Ligne pour la signature
  // doc.setLineWidth(0.4);
  // doc.line(150, 200, 200, 200); // Modifier les coordonnées Y selon vos besoins

  // // Texte "Signature" en bas à droite de la ligne
  // doc.text("Signature", 175, 205, { align: "center" });

  // const pdfBlob = doc.output('blob');
  // const formData = new FormData();
  // formData.append('pdf', pdfBlob, `${data.nom}_${data.prenom}_badge.pdf`);
  // formData.append('classe_nom', data.classe_nom);

  // try {
  //   const response = await axios.post(`${baseUrl}save-fiche-pdf/`, formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   });
  //   if (response.status === 200) {
  //     // Ouvrir le PDF dans une nouvelle fenêtre sans le télécharger à nouveau
  //     const url = URL.createObjectURL(pdfBlob);
  //     window.open(url, '_blank');

  //     // Cleanup
  //     setTimeout(() => {
  //       URL.revokeObjectURL(url);
  //     }, 100);
  //   }
  //   console.log(response.data);
  // } catch (error) {
  //   console.error('Erreur lors de l\'envoi du PDF:', error);
  // }
  // Sauvegarder le PDF
  doc.save(`Fiche-${data.matricule}-${data.annee_academique}.pdf`);

  // Ouvrir la fenêtre d'impression
  doc.autoPrint();
};

const exportPDF = async (data, classe, niveau, annee_academique) => {
  const doc = new jsPDF('p', 'pt', 'letter');

  // Add background image
  const img = new Image();
  img.src = '/img/filig.png';

  await new Promise((resolve) => {
    img.onload = resolve;
  });

  const canvas = document.createElement('canvas');
  canvas.width = 612;
  canvas.height = 792;
  const context = canvas.getContext('2d');
  context.drawImage(img, 0, 0, canvas.width, canvas.height);

  const imgData = canvas.toDataURL('image/png');

  // Add the background image to the PDF
  doc.addImage(imgData, 'PNG', 0, 0, 612, 792);

  // Header
  doc.addImage('/img/header.png', 'PNG', 0, 0, 612, 130);

  // Student Info
  doc.setFontSize(16);
  doc.setFont('times', 'bold');
  doc.text(`Liste : ${niveau} ${classe}`, 50, 150);
  doc.text(`Nombre d'élèves : ${data.length}`, 400, 150);

  // Sort data by concatenated 'nom' and 'prenom'
  const sortedData = data.sort((a, b) => {
    const fullNameA = `${a.nom} ${a.prenom}`.toLowerCase();
    const fullNameB = `${b.nom} ${b.prenom}`.toLowerCase();
    return fullNameA.localeCompare(fullNameB);
  });

  // Notes Table
  const tableColumn = ["#", "Nom", "Prenom", "", ""];
  const tableRows = sortedData.map((note, index) => [
    index + 1,
    note.nom,
    note.prenom,
    "",
    ""
  ]);

  doc.autoTable({
    startY: 170,
    head: [tableColumn],
    body: tableRows,
    theme: 'grid',
    styles: {
      font: 'times',
      fontSize: 12,
      cellPadding: 2,
      overflow: 'linebreak'
    },
    headStyles: { halign: 'center', valign: 'middle', fillColor: [1, 80, 133], textColor: [255, 255, 255] },
    bodyStyles: {
      valign: 'middle',
      textColor: [22, 22, 22],
      halign: 'center'
    },
    columnStyles: {
      0: { cellWidth: 30, halign: 'center' },
      1: { cellWidth: 190, halign: 'left', fontStyle: 'bold' },
      2: { cellWidth: 190, halign: 'left', fontStyle: 'bold' },
    }
  });

  doc.save(`${classe}_Liste.pdf`);
}

const DataInscription = () => {
  const { getInscriptions, deleteInscriptions } = useContext(StudentContext); // Récupérez la fonction getInscriptions du contexte d'authentification
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [records1, setRecords1] = useState([]);
  const [selectedClasse, setSelectedClasse] = useState('Toutes les classes'); // État pour suivre la classe sélectionnée
  const [loading, setLoading] = useState(true);
  const { getAnneeAc } = useContext(AdminContext);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getInscriptions();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        const data1 = await getAnneeAc();
        setRecords1(data1);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getInscriptions]);
  if (loading) {
    return <Loader />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsInscription = [
    {
      name: "Nom Complet",
      selector: (row) => (
        <div className="d-flex align-items-center">
          <img
            src={row.photo}
            alt="Photo"
            style={{ width: 30, height: 30, borderRadius: "50%", marginRight: 5 }}
          />
          {row.nom + " " + row.prenom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Matricule",
      selector: (row) => row.matricule,
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Classe",
      selector: (row) => (
        <div className="d-flex align-items-start">
          {row.niveau + " " + row.classe_nom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group" role="group">
          <Link to='/inscription-profile/' state={{ student_id: row.id }}
            className="btn btn-warning btn-sm me-1">
            <i className="fas fa-edit"></i>
          </Link>
          <button onClick={() => handlePrint(row)} className="btn btn-primary btn-sm me-1">
            <i className="fas fa-print"></i>
          </button>
          <button onClick={() => handleDelete(row.id)} className="btn btn-danger btn-sm ">
            <i className="fas fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const record = records1.length > 0 ? records1[0] : null;

  const handlePrint = async (student) => {
    const data = {
      matricule: student.matricule,
      nom: student.nom,
      prenom: student.prenom,
      adresse: student.adresse,
      telephone: student.telephone,
      annee_academique: record.date,
      classe_nom: student.classe_nom,
    };

    // Appel à la fonction generatePDF avec les données de l'étudiant
    await generatePDF(data);
  };

  function handleFilter(event) {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const nomLower = row.nom.toLowerCase();
      const prenomLower = row.prenom.toLowerCase();
      const searchLower = value.toLowerCase();
      return nomLower.includes(searchLower) || prenomLower.includes(searchLower);
    });

    setRecords(newData);
  }
  function handleDelete(id) {
    swal.fire({
      title: "Êtes-vous sûr(e) de vouloir supprimer cet élève?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet élève!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
      cancelButtonText: "Annuler",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteInscriptions(id); // Assuming userId is defined
        swal.fire("Supprimé!", "Votre élève a été supprimé avec succès.", "success");
        await refreshData();
      }
    });
  }
  const refreshData = async () => {
    try {
      const data = await getInscriptions();
      setOriginalRecords(data);
      setRecords(data);
    } catch (error) {
      console.error(error);
    }
  };
  function handleFilterByClasse(classe_nom) {
    setSelectedClasse(classe_nom);
    if (classe_nom === 'Toutes les classes') {
      setRecords(originalRecords);
    } else {
      const newData = originalRecords.filter((row) => row.classe_nom.toLowerCase() === classe_nom.toLowerCase());
      setRecords(newData);

      // if (newData.length === 0) {
      //   swal.fire({
      //     title: 'Classe vide',
      //     text: `Aucune donnée trouvée pour la classe ${classe_nom}`,
      //     icon: 'warning',
      //     toast: true,
      //     timer: 3000,
      //     position: 'top-right',
      //     timerProgressBar: true,
      //     showConfirmButton: false,
      //   });
      // }
    }
  }
  const handleExport = (type) => {
    // Obtenez les données à exporter en fonction de la classe sélectionnée
    const dataToExport = selectedClasse === 'Toutes les classes' ? records : originalRecords.filter((row) => row.classe_nom.toLowerCase() === selectedClasse.toLowerCase());
    const classe = selectedClasse;

    if (dataToExport.length === 0) {
      swal.fire({
        title: 'Table vide',
        text: 'Aucune donnée à exporter',
        icon: 'warning',
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    // Vérifiez que 'record' n'est pas null avant d'essayer d'accéder à 'record.annee_academique'
    if (!record) {
      swal.fire({
        title: 'Vide',
        icon: 'warning',
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    // Logique pour l'export selon le type (copy, pdf, excel, csv)
    switch (type) {
      case 'copy':
        // Logique pour la copie
        break;
      case 'pdf':
        if (selectedClasse === 'Toutes les classes') {
          swal.fire({
            title: "Veuillez sélectionner une classe",
            icon: "info",
            confirmButtonText: "OK",
          });
        } else {
          exportPDF(dataToExport, classe, record.annee_academique); // Exporter en PDF
        }
        break;
      case 'excel':
        // Logique pour l'Excel
        break;
      case 'csv':
        // Logique pour le CSV
        break;
      default:
        break;
    }
  };



  return (
    <>
      <div className="container-lg wow fadeIn " data-wow-delay="0.1s ">
        <div className="row">
          <div className="col-md-6 text-start d-flex align-items-center">
            {/* <ExportButton onClick={() => handleExport('copy')}>Copy</ExportButton> */}
            <ExportButton onClick={() => handleExport('pdf')}>Exporter en PDF</ExportButton>
            {/* <ExportButton onClick={() => handleExport('excel')}>Excel</ExportButton> */}
            {/* <ExportButton onClick={() => handleExport('csv')}>CSV</ExportButton> */}
            <ExportDropdown id="dropdown-item-button" title={`Trier par ${selectedClasse}`} menualign="right" variant="outline-primary ml-1 mt-1 btn btn-primary btn-sm mb-0">
              <Dropdown.ItemText>Selectionner la classe</Dropdown.ItemText>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Toutes les classes')}>Toutes les classes</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Antenor Firmin')}>NSI Antenor Firmin</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Oswald Durand')}>NSI Oswald Durand</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Jacques Stephen Alexis')}>NSI Jacques Stephen Alexis</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Demesvar Delorme')}>NSII Demesvar Delorme</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Jean Price Mars')}>NSII Jean Price Mars</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Emile Nau')}>NSII Emile Nau</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Etzer Vilaire')}>NSIII Etzer Vilaire</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Catherine Flon')}>NSIII Catherine Flon</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Jean Paul Sartre')}>NSIV Jean Paul Sartre</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('René Descartes')}>NSIV René Descartes</Dropdown.Item>
            </ExportDropdown>
          </div>

          <div className="col-md-6">
            <input
              className="form-control mt-2"
              type="text"
              placeholder="Rechercher par Nom, Prenom ou Matricule"
              onChange={handleFilter}
            />
          </div>

          <div className="col-md-12 " >
            <DataTable
              columns={columnsInscription}
              data={records}
              fixedHeader
              pagination
              customStyles={customStyles}
              paginationPerPage="10"
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Lignes par page:",
                rangeSeparatorText: "sur",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "Tous",
              }}
              subHeader
              noDataComponent="Aucune donnée trouvée"
            />
          </div>
        </div>
      </div>

    </>
  );
};
const DataInscriptionC = () => {
  const { getInscriptions, getInscriptionsById } = useContext(StudentContext);
  const { StudentsWithGeneralAverage } = useContext(CourseContext);
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [selectedClasse, setSelectedClasse] = useState('Toutes les classes'); // État pour suivre la classe sélectionnée
  const currentYear = new Date().getFullYear();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await StudentsWithGeneralAverage();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [StudentsWithGeneralAverage]);
  if (loading) {
    return <Loader />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsInscription = [
    {
      name: "Matricule",
      selector: (row) => row.matricule,
      sortable: true,
    },
    {
      name: "Classe",
      selector: (row) => (
        <div className="d-flex align-items-start">
          {row.niveau + " " + row.classe}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Moyenne Generale",
      selector: (row) => row.general_average,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group" role="group">
          <button onClick={() => handleClickPrint(row.id)} className="btn btn-success btn-sm me-1">
            <i className="fas fa-sync-alt"></i> Reinscrire
          </button>
        </div>
      ),
    },
  ];
  // Dans la fonction handleClickPrint
  const handleClickPrint = async (userId) => {
    try {
      const userData = await getInscriptionsById(userId); // Récupérer les données de l'étudiant
      generatePDF(userData); // Appeler generatePDF avec les données de l'étudiant
    } catch (error) {
      console.error(error);
    }
  };

  const generatePDF = async (data) => {
    const doc = new jsPDF();
    // Ajouter l'image en arrière-plan
    doc.addImage(`${baseUrl2}media/filig.png`, "PNG", 0, 0, 210, 297);

    // Logo
    doc.addImage(`${baseUrl2}media/header.png`, "PNG", 0, 0, 212, 48);

    // Ligne horizontale
    doc.setLineWidth(0.4);
    doc.line(0, 48, 210, 48);

    // Texte en gras "Fiche d'inscription"
    doc.setFont("times", "bold");
    doc.setFontSize(16);
    doc.text("Fiche d'inscription", 105, 55, { align: "center" });

    // Créer une table pour les informations d'inscription
    const tableData = [
      [{ content: "Matricule :", styles: { fontStyle: 'bold', halign: 'left' } }, { content: data.matricule, styles: { halign: 'left' } }],
      [{ content: "Nom :", styles: { fontStyle: 'bold', halign: 'left' } }, { content: data.nom, styles: { halign: 'left' } }],
      [{ content: "Prenom:", styles: { fontStyle: 'bold', halign: 'left' } }, { content: data.prenom, styles: { halign: 'left' } }],
      [{ content: "Adresse:", styles: { fontStyle: 'bold', halign: 'left' } }, { content: data.adresse, styles: { halign: 'left' } }],
      [{ content: "Téléphone:", styles: { fontStyle: 'bold', halign: 'left' } }, { content: data.telephone, styles: { halign: 'left' } }],
      [{ content: "Email:", styles: { fontStyle: 'bold', halign: 'left' } }, { content: data.email, styles: { halign: 'left' } }]
    ];

    // Générer la table
    doc.autoTable({
      startY: 65,
      head: [""],
      body: tableData,
      margin: { left: 60 },
      styles: {
        cellPadding: 2,
        fontSize: 12,
        font: "times" // Appliquer la police Times New Roman à la table
      },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 'auto' }
      },
      theme: "plain"
    });

    // Texte "Année académique 2024-2025" en haut à droite de la table
    doc.setFont("times", "normal");
    doc.setFontSize(10);
    doc.text("Année académique 2024-2025", 105, 65, { align: "center" });

    // Ligne pour la signature
    doc.setLineWidth(0.4);
    doc.line(150, 200, 200, 200); // Modifier les coordonnées Y selon vos besoins

    // Texte "Signature" en bas à droite de la ligne
    doc.text("Signature", 175, 205, { align: "center" });

    // Sauvegarder le PDF
    doc.save(`Insc-${data.matricule}-${currentYear}.pdf`);

    // Ouvrir la fenêtre d'impression
    doc.autoPrint();
  };

  function handleFilter(event) {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const nomLower = row.matricule.toLowerCase();
      const prenomLower = row.prenom.toLowerCase();
      const searchLower = value.toLowerCase();
      return nomLower.includes(searchLower) || prenomLower.includes(searchLower);
    });
    setRecords(newData);
  }
  const refreshData = async () => {
    try {
      const data = await getInscriptions();
      setOriginalRecords(data);
      setRecords(data);
    } catch (error) {
      console.error(error);
    }
  };
  function handleFilterByClasse(classe_nom) {
    setSelectedClasse(classe_nom);
    if (classe_nom === 'Toutes les classes') {
      setRecords(originalRecords);
    } else {
      const newData = originalRecords.filter((row) => row.classe_nom.toLowerCase() === classe_nom.toLowerCase());
      setRecords(newData);
    }
  }
  return (
    <>
      <div className="container-lg wow fadeIn " data-wow-delay="0.1s ">
        <div className="row">
          <div className="col-md-6 mt-1 text-start d-flex align-items-center">
            <ExportDropdown id="dropdown-item-button" title={`Trier par ${selectedClasse}`} menualign="right" variant="outline-primary ml-1 mt-1 btn btn-primary btn-sm mb-0">
              <Dropdown.ItemText>Selectionner la classe</Dropdown.ItemText>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Toutes les classes')}>Toutes les classes</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Antenor Firmin')}>NSI Antenor Firmin</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Oswald Durand')}>NSI Oswald Durand</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Jacques Stephen Alexis')}>NSI Jacques Stephen Alexis</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Demesvar Delorme')}>NSII Demesvar Delorme</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Jean Price Mars')}>NSII Jean Price Mars</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Emile Nau')}>NSII Emile Nau</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Etzer Vilaire')}>NSIII Etzer Vilaire</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Catherine Flon')}>NSIII Catherine Flon</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Jean Paul Sartre')}>NSIV Jean Paul Sartre</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('René Descartes')}>NSIV René Descartes</Dropdown.Item>
            </ExportDropdown>
          </div>

          <div className="col-md-6 text-end">

          </div>

          <div className="col-md-6 " >
            <input
              className="form-control mt-1"
              type="text"
              placeholder="Search"
              onChange={handleFilter}
            />
            <DataTable
              columns={columnsInscription}
              data={records}
              fixedHeader
              pagination
              customStyles={customStyles}
              paginationPerPage="10"
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Lignes par page:",
                rangeSeparatorText: "sur",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "Tous",
              }}
              subHeader
              noDataComponent="Aucune donnée trouvée"
            />
          </div>
          <div className="col-md-6 mt-1" >
            <div className='card'>
              s
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

const DataStudent = () => {
  const { getStudents, deleteStudent } = useContext(StudentContext); // Récupérez la fonction getInscriptions du contexte d'authentification
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [records1, setRecords1] = useState([]);
  const [selectedClasse, setSelectedClasse] = useState('Toutes les classes'); // État pour suivre la classe sélectionnée
  const [loading, setLoading] = useState(true);
  const { getAnneeAc } = useContext(AdminContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getStudents();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        const data1 = await getAnneeAc();
        setRecords1(data1);
        setRecords(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getStudents]);
  if (loading) {
    return <Loader />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsStudent = [
    {
      name: "Nom Complet",
      selector: (row) => (
        <div className="d-flex align-items-center">
          <img
            src={row.photo}
            alt="Photo"
            style={{ width: 30, height: 30, borderRadius: "50%", marginRight: 5 }}
          />
          {row.nom + " " + row.prenom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Matricule",
      selector: (row) => row.matricule,
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Classe",
      selector: (row) => (
        <div className="d-flex align-items-start">
          {row.niveau + " " + row.classe_nom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group" role="group">
          <Link to="/student-profile/" state={{ student_id: row.id }}
            className="btn btn-warning btn-sm me-1">
            <i className="fas fa-edit"></i>
          </Link>
          <button onClick={() => handlePrint(row)} className="btn btn-primary btn-sm me-1">
            <i className="fas fa-print"></i>
          </button>
          <button onClick={() => handleDelete(row.id)} className="btn btn-danger btn-sm ">
            <i className="fas fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];
  const record = records1.length > 0 ? records1[0] : null;
  const handlePrint = async (student) => {
    const data = {
      matricule: student.matricule,
      nom: student.nom,
      prenom: student.prenom,
      adresse: student.adresse,
      telephone: student.telephone,
      annee_academique: record.date,
      classe_nom: student.classe_nom,
    };

    // Appel à la fonction generatePDF avec les données de l'étudiant
    await generatePDF(data);
  };

  function handleFilter(event) {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const matriculeLower = row.matricule.toLowerCase();
      const nomLower = row.nom.toLowerCase();
      const prenomLower = row.prenom.toLowerCase();
      const searchLower = value.toLowerCase();
      return nomLower.includes(searchLower) || prenomLower.includes(searchLower) ||
        matriculeLower.includes(searchLower);
    });

    setRecords(newData);
  }
  function handleDelete(id) {
    swal.fire({
      title: "Êtes-vous sûr(e) de vouloir supprimer cet élève?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
      cancelButtonText: "Annuler",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteStudent(id); // Assuming userId is defined
        swal.fire("Supprimé!", "Votre élève a été supprimé avec succès.", "success");
        await refreshData();
      }
    });
  }
  const refreshData = async () => {
    try {
      const data = await getStudents();
      setOriginalRecords(data);
      setRecords(data);
    } catch (error) {
      console.error(error);
    }
  };
  function handleFilterByClasse(classe_nom) {
    setSelectedClasse(classe_nom);
    if (classe_nom === 'Toutes les classes') {
      setRecords(originalRecords);
    } else {
      const newData = originalRecords.filter((row) => row.classe_nom.toLowerCase() === classe_nom.toLowerCase());
      setRecords(newData);
    }
  }
  const handleExport = (type) => {
    const dataToExport = selectedClasse === 'Toutes les classes' ? records : originalRecords.filter((row) => row.classe_nom.toLowerCase() === selectedClasse.toLowerCase());
    const classe = selectedClasse;
    const niveau = dataToExport.length > 0 ? dataToExport[0].niveau : ''; // Récupérer le niveau de la classe

    if (dataToExport.length === 0) {
      swal.fire({
        title: 'Table vide',
        text: 'Aucune donnée à exporter',
        icon: 'warning',
        toast: true,
        timer: 3000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    switch (type) {
      case 'pdf':
        if (selectedClasse === 'Toutes les classes') {
          swal.fire({
            title: "Veuillez sélectionner une classe",
            icon: "info",
            confirmButtonText: "OK",
          });
        } else {
          exportPDF(dataToExport, classe, niveau, record.annee_academique); // Passer le niveau ici
        }
        break;
      // autres cas...
    }
  };
  // Fonction d'export PDF pour chaque salle
const exportPDFExam = async (salleData) => {
  const doc = new jsPDF('p', 'pt', 'letter');

  // Ajouter une image de fond
  const img = new Image();
  img.src = '/img/filig.png';

  await new Promise((resolve) => {
    img.onload = resolve;
  });

  const canvas = document.createElement('canvas');
  canvas.width = 612;
  canvas.height = 792;
  const context = canvas.getContext('2d');
  context.drawImage(img, 0, 0, canvas.width, canvas.height);
  const imgData = canvas.toDataURL('image/png');
  doc.addImage(imgData, 'PNG', 0, 0, 612, 792);

  // Ajouter le header
  doc.addImage('/img/header.png', 'PNG', 0, 0, 612, 130);

  // Informations de la salle et nombre d'étudiants
  doc.setFontSize(16);
  doc.setFont('times', 'bold');
  doc.text(`Salle : ${salleData.salle}`, 50, 150);
  doc.text(`Nombre d'élèves : ${salleData.nombre_etudiants}`, 400, 150);
  doc.text(`Vacation : ${salleData.vacation}`, 220, 160);

  // Trier les étudiants par nom complet
  const sortedData = salleData.etudiants.sort((a, b) => {
    const fullNameA = `${a.nom} ${a.prenom}`.toLowerCase();
    const fullNameB = `${b.nom} ${b.prenom}`.toLowerCase();
    return fullNameA.localeCompare(fullNameB);
  });

  // Table des notes avec autoTable
  const tableColumn = ["#", "Nom", "Prenom", "Classe"];
  const tableRows = sortedData.map((student, index) => [
    index + 1,
    student.nom,
    student.prenom,
    `${student.classe.niveau} ${student.classe.classe}`
  ]);

  doc.autoTable({
    startY: 170,
    head: [tableColumn],
    body: tableRows,
    theme: 'grid',
    styles: {
      font: 'times',
      fontSize: 12,
      cellPadding: 2,
      overflow: 'linebreak'
    },
    headStyles: { halign: 'center', valign: 'middle', fillColor: [1, 80, 133], textColor: [255, 255, 255] },
    bodyStyles: {
      valign: 'middle',
      textColor: [22, 22, 22],
      halign: 'center'
    },
    columnStyles: {
      0: { cellWidth: 30, halign: 'center' },
      1: { cellWidth: 150, halign: 'left', fontStyle: 'bold' },
      2: { cellWidth: 190, halign: 'left', fontStyle: 'bold' },
    }
  });

  // Sauvegarde du PDF
  doc.save(`${salleData.vacation}_${salleData.salle}_Liste.pdf`);
};

// Fonction pour gérer l'exportation pour chaque salle
const handleExportExam = async (type) => {
  if (type === 'pdf') {
    // Appeler l'API pour obtenir la liste des salles d'examen
    const response = await fetch(`${baseUrlS}exam_list/`);
    const examData = await response.json();

    // Générer un PDF pour chaque salle d'examen dans AM et PM
    for (const salleAM of examData.AM) {
      await exportPDFExam({ ...salleAM, vacation: 'AM' });
    }
    for (const sallePM of examData.PM) {
      await exportPDFExam({ ...sallePM, vacation: 'PM' });
    }
  }
};

  return (
    <>
      <div className="container-lg wow fadeIn " data-wow-delay="0.1s ">
        <div className="row">
          <div className="col-md-6 text-start d-flex align-items-center">
            {/* <ExportButton onClick={() => handleExport('copy')}>Copy</ExportButton> */}
            <ExportButton onClick={() => handleExport('pdf')}>Exporter en PDF</ExportButton>
            <ExportButton onClick={() => handleExportExam('pdf')}>Liste Examen</ExportButton>
            {/* <ExportButton onClick={() => handleExport('excel')}>Excel</ExportButton> */}
            {/* <ExportButton onClick={() => handleExport('csv')}>CSV</ExportButton> */}
            <ExportDropdown id="dropdown-item-button" title={`Trier par ${selectedClasse}`} menualign="right" variant="outline-primary ml-1 mt-1 btn btn-primary btn-sm mb-0">
              <Dropdown.ItemText>Selectionner la classe</Dropdown.ItemText>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Toutes les classes')}>Toutes les classes</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Antenor Firmin')}>NSI Antenor Firmin</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Oswald Durand')}>NSI Oswald Durand</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Jacques Stephen Alexis')}>NSI Jacques Stephen Alexis</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Demesvar Delorme')}>NSII Demesvar Delorme</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Jean Price Mars')}>NSII Jean Price Mars</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Emile Nau')}>NSII Emile Nau</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Etzer Vilaire')}>NSIII Etzer Vilaire</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Catherine Flon')}>NSIII Catherine Flon</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Jean Paul Sartre')}>NSIV Jean Paul Sartre</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => handleFilterByClasse('René Descartes')}>NSIV René Descartes</Dropdown.Item>
            </ExportDropdown>
          </div>

          <div className="col-md-6 ">
            <input
              className="form-control mt-2"
              type="text"
              placeholder="Rechercher par Nom, Prenom ou Matricule"
              onChange={handleFilter}
            />
          </div>

          <div className="col-md-12 " >
            <DataTable
              columns={columnsStudent}
              data={records}
              fixedHeader
              pagination
              customStyles={customStyles}
              paginationPerPage="10"
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Lignes par page:",
                rangeSeparatorText: "sur",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "Tous",
              }}
              subHeader
              noDataComponent="Aucune donnée trouvée"
            />
          </div>
        </div>
      </div>

    </>
  );
};

const DataClasses = () => {
  const { getClasses } = useContext(StudentContext); // Récupérez la fonction getInscriptions du contexte d'authentification
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  //const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [selectedClasse, setSelectedClasse] = useState('Toutes les classes'); // État pour suivre la classe sélectionnée
  // const [records, setRecords] = useState(DataInscription);
  const currentYear = new Date().getFullYear();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getClasses();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getClasses]);
  if (loading) {
    return <Loader />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsStudent = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      maxWidth: '50px',
    },
    {
      name: "Niveau",
      selector: (row) => row.niveau,
      sortable: true,
    },
    {
      name: "Classe",
      selector: (row) => (
        <div className="d-flex align-items-center">

          {row.classe}
        </div>
      ),
      sortable: true,
    },
  ];

  function handleFilter(event) {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const matriculeLower = row.niveau.toLowerCase();
      const nomLower = row.classe.toLowerCase();
      const searchLower = value.toLowerCase();
      return nomLower.includes(searchLower) || matriculeLower.includes(searchLower);
    });

    setRecords(newData);
  }
  return (
    <>
      <div className="card-header">
        <div className="card-tools p-0 m-0 col-12">
          <input
            className="card m-0 col-12"
            type="text"
            placeholder="Rechercher une classe"
            onChange={handleFilter}
          />
        </div>
      </div>

      <div className="card-body p-0" >
        <DataTable
          columns={columnsStudent}
          data={records}
          fixedHeader
          pagination
          customStyles={customStyles}
          paginationPerPage="10"
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          paginationComponentOptions={{
            rowsPerPageText: "Lignes par page:",
            rangeSeparatorText: "sur",
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: "Tous",
          }}
          subHeader
          noDataComponent="Aucune donnée trouvée"
        />

      </div>

    </>
  );
};

export {
  DataInscription,
  DataInscriptionC,
  DataStudent,
  DataClasses,
};