import { useContext, useEffect, useState } from "react";
import Footer from "../../Includes/Footer";
import Sidebar from "../../Includes/Sidebar";
import { Link } from "react-router-dom";
import { ExportDropdown, customStyles } from "../Data/Utility";
import { Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { StudentContext } from "../../context/StudentContext";
import Loader from "../../Includes/loader/loader";
import { CourseContext } from "../../context/CourseContext";

export default function Reinscription() {
  const { createReinscription, getClasses } = useContext(StudentContext);
  const { StudentsWithGeneralAverage, fetchStudentData } =
    useContext(CourseContext);
  const [originalRecords, setOriginalRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [selectedClasse, setSelectedClasse] = useState("Toutes les classes");
  const [loading, setLoading] = useState(true);
  const [filteredClasses, setFilteredClasses] = useState([]);
  // const [studentData, setStudentData] = useState(null);
  const [formValues, setFormValues] = useState({
    matricule: "",
    nomComplet: "",
    niveau: "",
    classe: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await StudentsWithGeneralAverage();
        setOriginalRecords(data);
        setRecords(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [StudentsWithGeneralAverage]);

  const columnsInscription = [
    {
      name: "Matricule",
      selector: (row) => row.matricule,
      sortable: true,
    },
    {
      name: "Classe",
      selector: (row) => (
        <div className="d-flex align-items-start">
          {row.niveau + " " + row.classe}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Moyenne Generale",
      selector: (row) => row.general_average.general_average,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group" role="group">
          <button
            onClick={() => handleClick(row.id)}
            className="btn btn-success btn-sm me-1"
          >
            <i className="fas fa-sync-alt"></i>
          </button>
        </div>
      ),
    },
  ];

  const handleClick = async (studentId) => {
    try {
      console.log("Student ID:", studentId);
      const studentWithGeneralAverage = await fetchStudentData(studentId);
      console.log(
        "Student data with general average:",
        studentWithGeneralAverage
      );
      // setStudentData(studentWithGeneralAverage);
      setFormValues({
        matricule: studentWithGeneralAverage.matricule,
        nomComplet: `${studentWithGeneralAverage.nom} ${studentWithGeneralAverage.prenom}`,
        niveau: studentWithGeneralAverage.niveau,
        classe: "",
      });

      const moyenne = parseFloat(studentWithGeneralAverage.general_average);
      const availableClasses = await getAvailableClasses(
        moyenne,
        studentWithGeneralAverage.general_average.next_niveau
      );
      setFilteredClasses(availableClasses);
    } catch (error) {
      console.error(error);
    }
  };

  const getAvailableClasses = async (moyenne, niveau) => {
    const classes = await getClasses();
    if (moyenne > 5) {
      // Filtrer les classes pour le niveau suivant
      return classes.filter((c) => c.niveau === getNextNiveau(niveau));
    } else {
      // Garder les classes pour le niveau actuel
      return classes.filter((c) => c.niveau === niveau);
    }
  };

  const getNextNiveau = (currentNiveau) => {
    const niveaux = ["NSI", "NSII", "NSIII", "NSIV"];
    const index = niveaux.indexOf(currentNiveau);
    return index >= 0 && index < niveaux.length - 1
      ? niveaux[index + 1]
      : currentNiveau;
  };

  const handleFilter = (event) => {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords);
      return;
    }
    const newData = originalRecords.filter((row) => {
      const matriculeLower = row.matricule.toLowerCase();
      const searchLower = value.toLowerCase();
      return matriculeLower.includes(searchLower);
    });
    setRecords(newData);
  };

  const handleFilterByClasse = (classe_nom) => {
    setSelectedClasse(classe_nom);
    if (classe_nom === "Toutes les classes") {
      setRecords(originalRecords);
    } else {
      const newData = originalRecords.filter(
        (row) => row.classe_nom.toLowerCase() === classe_nom.toLowerCase()
      );
      setRecords(newData);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const matricule = e.target.matricule.value;
    const classe = e.target.classe.value;

    let formData = new FormData();
    formData.append("matricule", matricule);
    formData.append("classe", classe);

    setLoading(true);
    try {
      classe.length > 0 && createReinscription(formData);
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Remettre loading à false après la fin du processus
    }
    // refreshData()
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <div className="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2 mt-3">
                <div className="col-sm-6 d-flex align-items-center">
                  <div className="bg-blue rounded-lg p-2 text-center">
                    <i className="fas fa-sync-alt"></i>
                  </div>
                  <div className="ml-2">
                    <h3 className="display fw-12">
                      Gestion des Reinscriptions
                    </h3>
                  </div>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item active">Reinscriptions</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="clearfix hidden-md-up"></div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">
                        <ExportDropdown
                          id="dropdown-item-button"
                          title={`Trier par ${selectedClasse}`}
                          menualign="right"
                          variant="outline-primary btn btn-primary btn-sm m-0"
                        >
                          <Dropdown.ItemText>
                            Selectionner la classe
                          </Dropdown.ItemText>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Toutes les classes")
                            }
                          >
                            Toutes les classes
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Antenor Firmin")
                            }
                          >
                            NSI Antenor Firmin
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Oswald Durand")
                            }
                          >
                            NSI Oswald Durand
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Jacques Stephen Alexis")
                            }
                          >
                            NSI Jacques Stephen Alexis
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Demesvar Delorme")
                            }
                          >
                            NSII Demesvar Delorme
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Jean Price Mars")
                            }
                          >
                            NSII Jean Price Mars
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => handleFilterByClasse("Emile Nau")}
                          >
                            NSII Emile Nau
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Etzer Vilaire")
                            }
                          >
                            NSIII Etzer Vilaire
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleFilterByClasse("Catherine Flon")
                            }
                          >
                            NSIII Catherine Flon
                          </Dropdown.Item>
                        </ExportDropdown>
                      </h3>

                      <div className="card-tools">
                        <Link
                          to="/students"
                          className="btn btn-success btn-sm fw-bold"
                        >
                          <i className="fas fa-list"></i> Liste des élèves
                        </Link>
                      </div>
                    </div>

                    <div className="card-body p-0 m-0">
                      <div className="row">
                        <div className="col-md-6 mt-1 text-start d-flex align-items-center"></div>
                        <div className="col-md-6 mt-2 text-end">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Rechercher par matricule"
                            onChange={handleFilter}
                          />
                        </div>
                      </div>
                      {loading ? (
                        <Loader />
                      ) : (
                        <DataTable
                          columns={columnsInscription}
                          data={records}
                          fixedHeader
                          pagination
                          customStyles={customStyles}
                          paginationPerPage={10}
                          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                          paginationComponentOptions={{
                            rowsPerPageText: "Lignes par page:",
                            rangeSeparatorText: "sur",
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: "Tous",
                          }}
                          subHeader
                          noDataComponent="Aucune donnée trouvée"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Reinscription</h3>
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label htmlFor="matricule">Matricule</label>
                            <input
                              type="text"
                              className="form-control"
                              id="matricule"
                              name="matricule"
                              value={formValues.matricule}
                              onChange={handleChange}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="nomComplet">Nom Complet</label>
                            <input
                              type="text"
                              className="form-control"
                              id="nomComplet"
                              name="nomComplet"
                              value={formValues.nomComplet}
                              onChange={handleChange}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="niveau">Niveau</label>
                            <input
                              type="text"
                              className="form-control"
                              id="niveau"
                              name="niveau"
                              value={formValues.niveau}
                              onChange={handleChange}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="classe">Classe</label>
                            <select
                              id="classe"
                              name="classe"
                              className="form-control"
                              required
                              value={formValues.classe}
                              onChange={handleChange}
                            >
                              <option value="">Choisir une classe</option>
                              {filteredClasses.map((classe) => (
                                <option key={classe.id} value={classe.classe}>
                                  {classe.niveau} {classe.classe}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              <i className="fas fa-spinner fa-spin"></i>{" "}
                              Enregistrement en cours...
                            </>
                          ) : (
                            <>
                              <i className="fas fa-save"></i> Enregistrer
                            </>
                          )}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}
