/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../context/AuthContext';
import { useLocation } from 'react-router-dom';
import Sidebar from '../../Includes/Sidebar';
import { Link } from "react-router-dom";
const swal = require("sweetalert2");

function Profile() {
    const { getUserById ,updateUser } = useContext(AuthContext);
    const [records, setRecords] = useState();
    const location = useLocation();
    const { user_id } = location.state || {};
    const [profileImage, setProfileImage] = useState(null);
    const handleImageChange = (event) => {
      const file = event.target.files && event.target.files[0];
      if (file) {
        setProfileImage(file); 
      }
    };
    useEffect(() => {
      const userId = user_id;
      getUserById(userId).then((data) => {
        setRecords(data); // Mettez à jour records avec les données récupérées
      });
    }, [getUserById, user_id]); 
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
      if (profileImage) {
        formData.append("image", profileImage);
      }
  
      const password = formData.get("password");
      const confirmPassword = formData.get("confirmPassword");
  
      if (password !== confirmPassword) {
        swal.fire({
          title: "Les mots de passe ne correspondent pas",
          icon: "error",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        return;
      }
  
      // Supprimez les champs de mot de passe non nécessaires
      formData.delete("confirmPassword");
  
      // Enregistrez l'utilisateur avec le mot de passe non crypté
      updateUser(records.id, formData);
    };
  
  return (
    <>
    <Sidebar />
        <section className="content-wrapper wow fadeIn " data-wow-delay="0.05s ">
      <div className="container-fluid">
        <div className="row">
        <div className="col-md-12 bg-white">

                <ol className="breadcrumb float-sm-end bg-white mt-3">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Acceuil</Link>
                  </li>
                  <li className="breadcrumb-item">
                    {records && records.role === "Admin" ? (
                      <Link to="/users">Utilisateurs</Link>
                    ) : (
                      <span className="text-primary">Utilisateurs</span>
                    )}
                  </li>

                  <li className="breadcrumb-item active">Profil</li>
                </ol>
            </div>
          <div className="col-md-3">

            <div className="card card-primary card-outline">
              <div className="card-body box-profile">
                <div className="text-center">
                  <img className="profile-user-img img-fluid img-circle"
                       src={records?.image || ""}
                       alt="User profile"/>
                </div>

                <h3 className="profile-username text-center">{records?.first_name + " " + records?.last_name || ""}</h3>

                <p className="text-center mb-0">Nom Utilisateur</p>
                <p className="text-muted text-center ">{records?.username || ""}</p>

                <ul className="list-group list-group-unbordered mb-3">
                  <li className="list-group-item">
                    <b>Role</b> <a className="float-right">{records?.role || ""}</a>
                  </li>
                  <li className="list-group-item">
                    <b>{records?.email || ""}</b> 
                  </li>
                  <li className="list-group-item">
                    <b>{records?.role || ""}</b> 
                  </li>
                </ul>

                <a href="" className="btn btn-primary btn-block"><b>-</b></a>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="card">
              <div className="card-header p-2">
                <ul className="nav nav-pills">
                  <li className="nav-item"><a className="nav-link active" href="#settings" data-toggle="tab">Personnaliser votre Profil</a></li>
                  {/* <li className="nav-item"><a className="nav-link " href="#timeline" data-toggle="tab">Timeline</a></li> */}
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content">
                  <div className="tab-pane" id="settings">
                   
                  </div>
                  <div className="active tab-pane" id="activity">
                  <form className="form-horizontal"
              encType="multipart/form-data"
              onSubmit={handleSubmit}>
                      <div className="form-group row">
                        <label for="inputName" className="col-sm-2 col-form-label">Nom</label>
                        <div className="col-sm-10">
                          <input type="text" className="form-control"
                           id="inputName" value={records?.first_name || ""}
                           onChange={(e) =>
                             setRecords({ ...records, first_name: e.target.value })
                           }
                           placeholder="Nom"
                           required
                           name="first_name"
                           />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="inputName" className="col-sm-2 col-form-label">Prenom</label>
                        <div className="col-sm-10">
                          <input type="text" className="form-control" id="inputName" 
                          value={records?.last_name || ""}
                      onChange={(e) =>
                        setRecords({ ...records, last_name: e.target.value })
                      }
                      placeholder="Prenom"
                      required
                      name="last_name"/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="inputEmail" className="col-sm-2 col-form-label">Email</label>
                        <div className="col-sm-10">
                          <input type="email" className="form-control" id="inputEmail" 
                          onChange={(e) =>
                            setRecords({ ...records, email: e.target.value })
                          }
                          value={records?.email || ""}
                          placeholder="Email"
                          required
                          name="email"/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="inputName2" className="col-sm-2 col-form-label">Nom Utilisateur</label>
                        <div className="col-sm-10">
                          <input type="text" className="form-control" id="inputName2" 
                           onChange={(e) =>
                            setRecords({ ...records, username: e.target.value })
                          }
                          value={records?.username || ""}
                          placeholder="Nom Utilisateur"
                          required
                          name="username"/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="inputName2" className="col-sm-2 col-form-label">Mot de passe</label>
                        <div className="col-sm-10">
                          <input type="text" className="form-control" id="inputName2" 
                          
                          placeholder="Mot de passe"
                          name="password"/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="inputName2" className="col-sm-2 col-form-label">Confirmer Mot de passe</label>
                        <div className="col-sm-10">
                          <input type="text" className="form-control" id="inputName2" 
                          
                          placeholder="Confirmer le Mot de passe"
                          name="confirmPassword"/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="inputSkills" className="col-sm-2 col-form-label">Photo</label>
                        <div className="col-sm-10">
                          <input type="file" className="custom-file-input" id="inputSkills"
                          accept="image/*"
                          value={records?.image.url || ""}
                          name="image"
                          onChange={handleImageChange}/>
                            <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        {profileImage
                          ? "Image sélectionnée"
                          : "Sélectionner une image"}
                      </label>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="offset-sm-2 col-sm-10">
                          <div className="checkbox">
                            <label>
                              
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="offset-sm-2 col-sm-10">
                          <button type="submit" className="btn btn-primary float-end">Enregistrer</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="tab-pane" id="timeline">
                    <div className="timeline timeline-inverse">
                      <div className="time-label">
                        <span className="bg-danger">
                          10 Feb. 2014
                        </span>
                      </div>
                      <div>
                        <i className="fas fa-envelope bg-primary"></i>

                        <div className="timeline-item">
                          <span className="time"><i className="far fa-clock"></i> 12:05</span>

                          <h3 className="timeline-header"><a href="#">Support Team</a> sent you an email</h3>

                          <div className="timeline-body">
                            Etsy doostang zoodles disqus groupon greplin oooj voxy zoodles,
                            weebly ning heekya handango imeem plugg dopplr jibjab, movity
                            jajah plickers sifteo edmodo ifttt zimbra. Babblely odeo kaboodle
                            quora plaxo ideeli hulu weebly balihoo...
                          </div>
                          <div className="timeline-footer">
                            <a href="#" className="btn btn-primary btn-sm">Read more</a>
                            <a href="#" className="btn btn-danger btn-sm">Delete</a>
                          </div>
                        </div>
                      </div>
                      <div>
                        <i className="fas fa-user bg-info"></i>

                        <div className="timeline-item">
                          <span className="time"><i className="far fa-clock"></i> 5 mins ago</span>

                          <h3 className="timeline-header border-0"><a href="#">Sarah Young</a> accepted your friend request
                          </h3>
                        </div>
                      </div>
                      <div>
                        <i className="fas fa-comments bg-warning"></i>

                        <div className="timeline-item">
                          <span className="time"><i className="far fa-clock"></i> 27 mins ago</span>

                          <h3 className="timeline-header"><a href="#">Jay White</a> commented on your post</h3>

                          <div className="timeline-body">
                            Take me to your leader!
                            Switzerland is small and neutral!
                            We are more like Germany, ambitious and misunderstood!
                          </div>
                          <div className="timeline-footer">
                            <a href="#" className="btn btn-warning btn-flat btn-sm">View comment</a>
                          </div>
                        </div>
                      </div>
                      <div className="time-label">
                        <span className="bg-success">
                          3 Jan. 2014
                        </span>
                      </div>
                      <div>
                        <i className="fas fa-camera bg-purple"></i>

                        <div className="timeline-item">
                          <span className="time"><i className="far fa-clock"></i> 2 days ago</span>

                          <h3 className="timeline-header"><a href="#">Mina Lee</a> uploaded new photos</h3>

                          <div className="timeline-body">
                            <img src="https://placehold.it/150x100" alt="..."/>
                            <img src="https://placehold.it/150x100" alt="..."/>
                            <img src="https://placehold.it/150x100" alt="..."/>
                            <img src="https://placehold.it/150x100" alt="..."/>
                          </div>
                        </div>
                      </div>
                      <div>
                        <i className="far fa-clock bg-gray"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </section>
    </>
  )
}

export default Profile