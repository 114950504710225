/* eslint-disable */
import React from 'react';
import { Helmet } from 'react-helmet';

function FooterHome() {
  return (
    <>
      <Helmet>
        {/* Optimisation SEO - Ajout de balises meta */}
        <meta name="description" content="Footer de LNFCL avec des informations de contact, des liens rapides, une galerie de photos et un formulaire d'inscription." />
        <meta name="keywords" content="footer, contact, liens rapides, inscription, galerie, LNFCL" />
        <meta name="robots" content="index, follow" />
        <title>LNFCL - Lycée National François Cappoix de Limonade</title>
      </Helmet>

      <div className="container-fluid bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Entrer en contact</h3>
              <address>
                <p className="mb-2">
                  <i className="fa fa-map-marker-alt me-3" aria-hidden="true"></i>
                  Route Nationale #6, LaHatte, Limonade, Haiti
                </p>
                <p className="mb-2">
                  <i className="fa fa-phone-alt me-3" aria-hidden="true"></i>
                  <a href="tel:+50937091527" className="text-white-50">+509 3709-1527 | 3758-9536 | 3219-3131</a>
                </p>
                <p className="mb-2">
                  <i className="fa fa-envelope me-3" aria-hidden="true"></i>
                  <a href="mailto:info.lnfcl@gmail.com" className="text-white-50">info.lnfcl@gmail.com</a>
                </p>
              </address>
              <div className="d-flex pt-2">
                <a className="btn btn-outline-light btn-social" href="https://twitter.com" aria-label="Twitter" title="Twitter">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="https://facebook.com" aria-label="Facebook" title="Facebook">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="https://youtube.com" aria-label="YouTube" title="YouTube">
                  <i className="fab fa-youtube"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="https://linkedin.com" aria-label="LinkedIn" title="LinkedIn">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Liens rapides</h3>
              <a className="btn btn-link text-white-50" href="#about" title="À Propos">À Propos</a>
              <a className="btn btn-link text-white-50" href="#contact" title="Contacts">Contacts</a>
              <a className="btn btn-link text-white-50" href="#serv" title="Nos Services">Nos Services</a>
              <a className="btn btn-link text-white-50" href="#" title="Politique de confidentialité">Politique de confidentialité</a>
              <a className="btn btn-link text-white-50" href="#" title="Termes et conditions">Termes et conditions</a>
            </div>
            <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Galerie de photos</h3>
              <div className="row g-2 pt-2">
                {['gal-1', 'gal-2', 'gal-4', 'gal-3', 'gal-6', 'gal-5'].map((img, index) => (
                  <div className="col-4" key={index}>
                    <img className="img-fluid rounded bg-light p-1" src={`./img/${img}.png`} alt={`Galerie image ${index + 1}`} />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Inscription</h3>
              <p>Inscrivez-vous pour accéder à plus de fonctionnalités</p>
              <div className="position-relative mx-auto" style={{ maxWidth: '400px' }}>
                <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="email" placeholder="Votre email" aria-label="Email" />
                <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">S'inscrire</button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy; <a className="border-bottom" href="https://lnfcl.com" title="Visitez notre site lnfcl">lnfcl.com</a>, Tous droits réservés.
                Conçu par <a className="border-bottom" href="https://herntz.netlify.app/" target="_blank" rel="noopener noreferrer">H-DESIGN</a>
                <br />Distribué par: <a className="border-bottom" href="#" target="_blank" rel="noopener noreferrer">Kolabo Tech</a>
              </div>
              <div className="col-md-6 text-center text-md-end">
                <div className="footer-menu">
                  <a href="#home" title="Accueil">Accueil</a>
                  <a href="#cookies" title="Cookies">Cookies</a>
                  <a href="#help" title="Aide">Aide</a>
                  <a href="#faq" title="FAQ">FAQs</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterHome;
