/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { ExportDropdown, customStyles } from "./Utility";
import { Dropdown } from "react-bootstrap";
import { CourseContext } from '../../context/CourseContext';
import Loader from '../../Includes/loader/loader';
const swal = require('sweetalert2');

const DataCourses = () => {
    const { getCourses, deleteCourses } = useContext(CourseContext); // Récupérez la fonction getCourses du contexte d'authentification
    const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
    const [records, setRecords] = useState([]);
    const [selectedClasse, setSelectedClasse] = useState('Toutes les classes'); 
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCourses();
                setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
                setRecords(data);
                setLoading(false); 
              } catch (error) {
                setLoading(false);
                console.error(error);
            }
        };
        fetchData();
    }, [getCourses]);
    if (loading) {
        return <Loader />; // Affiche le loader tant que les données sont en cours de chargement
    }
    const columnsCourses = [
        {
            name: "Code",
            selector: (row) => row.code,
            sortable: true,
        },
        {
            name: "Matière",
            selector: (row) => row.nom,
            sortable: true,
        },
        {
            name: "Classe",
            selector: (row) => (
                <div className="d-flex align-items-start">
                    {row.niveau}
                </div>
            ),
            sortable: true,
        },
        {
            name: "Pondération",
            selector: (row) => row.ponderation,
            sortable: true,
        },
        {
            name: "Nombre d'heures",
            selector: (row) => row.heures_credit,
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="btn-group" role="group">
                    <Link to="/course-detail/" state={{course_id: row.id }}
                     className="btn btn-success btn-sm me-1">
                        <i className="fas fa-eye"></i>
                    </Link>

                    <button onClick={() => handleDelete(row.id)} className="btn btn-danger btn-sm">
                        <i className="fas fa-trash"></i>
                    </button>
                </div>
            ),
        },
    ];

    const handleDelete = (id) => {
        swal.fire({
            title: "Êtes-vous sûr(e) de vouloir supprimer ce cours?",
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonColor: "#3085d6",
            cancelButtonText: "Annuler",
            confirmButtonText: "Oui, supprimer!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteCourses(id); // Assuming userId is defined
                swal.fire("Supprimé!", "Le cours a été supprimé avec succès.", "success");
                await refreshData();
            }
        });
    };

    const handleFilter = (event) => {
        const { value } = event.target;
        if (value === "") {
            setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
            return;
        }
        const newData = originalRecords.filter((row) => {
            const nomLower = row.nom.toLowerCase();
            const codeLower = row.code.toLowerCase();
            const searchLower = value.toLowerCase();
            return nomLower.includes(searchLower) || codeLower.includes(searchLower);
        });

        setRecords(newData);
    };

    const refreshData = async () => {
        try {
            const data = await getCourses();
            setOriginalRecords(data);
            setRecords(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleFilterByClasse = (niveau) => {
        setSelectedClasse(niveau);
        if (niveau === 'Toutes les classes') {
            setRecords(originalRecords);
        } else {
            const newData = originalRecords.filter((row) => row.niveau.toLowerCase() === niveau.toLowerCase());
            setRecords(newData);
        }
    };

    return (
        <>
            <div className="container-lg wow fadeIn" data-wow-delay="0.1s">
                <div className="row">
                    <div className="col-md-8 text-start d-flex align-items-center">
                        <ExportDropdown
                            id="dropdown-item-button"
                            title={`Trier par ${selectedClasse}`}
                            menualign="right"
                            variant="outline-primary btn btn-primary btn-sm mb-0"
                        >
                            <Dropdown.ItemText>Selectionner la classe</Dropdown.ItemText>
                            <Dropdown.Item as="button" onClick={() => handleFilterByClasse('Toutes les classes')}>Toutes les classes</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleFilterByClasse('NSI')}>NSI</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleFilterByClasse('NSII')}>NSII</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleFilterByClasse('NSIII')}>NSIII</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => handleFilterByClasse('NSIV')}>NSIV</Dropdown.Item>
                        </ExportDropdown>
                    </div>

                    <div className="col-md-4 p-2">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Search"
                            onChange={handleFilter}
                        />
                    </div>

                    <div className="col-md-12">
                   
                            <DataTable
                                columns={columnsCourses}
                                data={records}
                                fixedHeader
                                pagination
                                customStyles={customStyles}
                                paginationPerPage="10"
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                                paginationComponentOptions={{
                                    rowsPerPageText: "Lignes par page:",
                                    rangeSeparatorText: "sur",
                                    noRowsPerPage: false,
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: "Tous",
                                }}
                                subHeader
                                noDataComponent="Aucune donnée trouvée"
                            />
                    </div>
                </div>
            </div>
        </>
    );
};

export default DataCourses;
