import  { useState } from 'react'
import Sidebar from '../../Includes/Sidebar';
import { Button, Modal } from 'react-bootstrap';
import Footer from '../../Includes/Footer';
import { Link } from 'react-router-dom';
import DataCourses from '../Data/DataCourses';
import FormCourses from '../Data/FormCourses';

function Courses() {
  const [showModal, setShowModal] = useState(false);
 
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the form from submitting
    setShowModal(true); // Show the modal
  };

  const closeModal = () => {
    setShowModal(false); // Hide the modal
  };

  return (
   
        <div className="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
        <div className="wrapper">
          <Sidebar />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2 mt-3">
                  <div className="col-sm-6">
                    <h1 className="m-0">Gestion des Matières</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item"><Link to="/dashboard">Tableau de bord</Link></li>
                      <li className="breadcrumb-item active">Clients</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
  
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="clearfix hidden-md-up"></div>
                </div>
  
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Liste des Matières</h3>
  
                        <div className="card-tools">
                          <button
                            type="button"
                            onClick={handleSubmit}
                            className="btn btn-success btn-sm"
                          >
                            <i className="fas fa-user-plus"></i> Nouveau Cours
                          </button>
                        </div>
                      </div>
  
                      <div className="card-body p-0 m-0">
                      <DataCourses/>
                      </div>
  
                      <div className="card-footer text-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
               <Modal className='mt-0' show={showModal} onHide={closeModal} size="lg" top>
         <Modal.Header closeButton>
           <Modal.Title>Formulaire d'enregistrement</Modal.Title>
         </Modal.Header>
         <Modal.Body>
           <div className="card">
             <div className="card-body">
                     <FormCourses/>
             </div>
           </div>
         </Modal.Body>
         <Modal.Footer>
           <Button variant="btn btn-danger btn-sm" onClick={closeModal}><i className='fas fa-trash'></i> Fermer</Button>
           </Modal.Footer>
       </Modal>
          </div>
        </div>
      </div>
  )
}

export default Courses