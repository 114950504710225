import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Includes/Footer";
import Sidebar from "../../Includes/Sidebar";
import { useLocation } from "react-router-dom";
import { StudentContext } from "../../context/StudentContext";
import Loader from "../../Includes/loader/loader";
import { Url1 } from "../../context/BaseUrl";

const baseUrl = Url1;
function StudentArchivedprofile() {
  const { getStudentsArchivedProfile } = useContext(StudentContext);
  const [records, setRecords] = useState();
  const location = useLocation();
  const { year, student_id } = location.state || {};
  // eslint-disable-next-line
  const [profileImageView, setProfileImageView] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userId = student_id;
    getStudentsArchivedProfile(year, userId).then((data) => {
      console.log(data); // Ajoutez ceci pour vérifier les données
      setRecords(data);
      setLoading(false); // Assurez-vous de définir loading sur false après avoir récupéré les données
    });
  }, [getStudentsArchivedProfile, student_id, year]);

  return (
    <div className="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2 mt-3">
                <div className="col-sm-6 d-flex align-items-center">
                  <div className="bg-blue rounded-lg p-2 text-center">
                    <i className="fas fa-user"></i>
                  </div>
                  <div className="ml-2">
                    <h3 className="display fw-12">Detail Élève</h3>
                  </div>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      <Link to="/students">Élèves</Link>
                    </li>
                    <li className="breadcrumb-item active">Detail</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="clearfix hidden-md-up"></div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Informations de l'élève</h3>

                      <div className="card-tools">
                        <Link className="btn btn-success btn-sm" to="/students">
                          <i className="fas fa-list"></i> Liste des élèves
                        </Link>
                      </div>
                    </div>

                    <div className="card-body p-0 m-0">
                      {loading ? (
                        <Loader />
                      ) : (
                        <form
                          className="forms-sample"
                          encType="multipart/form-data"
                        >
                          <div className="row">
                            <div className="card-header">
                              <img
                                src={
                                  profileImageView ||
                                  (records && `${baseUrl}${records.photo}`) ||
                                  ""
                                }
                                id="profil"
                                alt="profil"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  borderRadius: "50px",
                                }}
                              />
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="name">Nom</label>
                                    <input
                                      readOnly
                                      type="text"
                                      value={records?.nom || ""}
                                      onChange={(e) =>
                                        setRecords({
                                          ...records,
                                          nom: e.target.value,
                                        })
                                      }
                                      className="form-control"
                                      id="name"
                                      placeholder="Nom de l'élève"
                                      name="nom"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="prenom">Prénom</label>
                                    <input
                                      readOnly
                                      type="text"
                                      value={records?.prenom || ""}
                                      className="form-control"
                                      id="name"
                                      placeholder="Nom de l'élève"
                                      name="prenom"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="exampleSelectGender">
                                      Sexe
                                    </label>
                                    <select
                                      className="form-control"
                                      id="exampleSelectGender"
                                      name="sexe"
                                      value={records?.sexe || ""}
                                    >
                                      <option selected readOnly></option>
                                      <option value={"M"}>Masculin</option>
                                      <option value={"F"}>Feminin</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="date">Date naissance</label>
                                    <input
                                      readOnly
                                      type="date"
                                      className="form-control"
                                      id="date"
                                      value={records?.date_naissance || ""}
                                      placeholder="Date Naissance"
                                      name="date_naissance"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="Adresse">
                                      Lieu de Naissance
                                    </label>
                                    <input
                                      readOnly
                                      type="test"
                                      className="form-control"
                                      id="Adresse"
                                      value={records?.lieu_naissance || ""}
                                      placeholder="Adresse"
                                      name="lieu_naissance"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="Adresse">
                                      N˚ d'ordre 9ème AF
                                    </label>
                                    <input
                                      readOnly
                                      type="test"
                                      className="form-control"
                                      id="Adresse"
                                      value={records?.no_ordre || ""}
                                      placeholder="Adresse"
                                      name="no_ordre"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="exampleSelectGender">
                                      Niveau
                                    </label>
                                    <select
                                      className="form-control"
                                      id="exampleSelectGender"
                                      name="niveau"
                                      value={records?.niveau || ""}
                                    >
                                      <option value="" disabled selected>
                                        Choisir un Niveau
                                      </option>
                                      <option value={"NSI"}>NSI</option>
                                      <option value={"NSII"}>NSII</option>
                                      <option value={"NSIII"}>NSIII</option>
                                      <option value={"NSIV"}>NSIV</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="exampleSelectGender">
                                      Classe
                                    </label>
                                    <select
                                      className="form-control"
                                      id="exampleSelectGender"
                                      name="classe"
                                      value={records?.classe || ""}
                                    >
                                      <option
                                        value={records?.classe_nom || ""}
                                        disabled
                                        selected
                                      >
                                        {records?.classe_nom || ""}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="Téléphone">
                                      École l'élève a fait 9e
                                    </label>
                                    <input
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="Téléphone"
                                      value={records?.ecole_9e || ""}
                                      placeholder="Ecole ou l'élève a fait 9e"
                                      name="ecole_9e"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="Téléphone">
                                      Année Réussite 9e
                                    </label>
                                    <input
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="Téléphone"
                                      value={records?.annee_9e || ""}
                                      placeholder="Année de réussite 9e"
                                      name="annee_9e"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="Téléphone">
                                      Dernier Etablissement
                                    </label>
                                    <input
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="Téléphone"
                                      value={
                                        records?.dernier_etablissement || ""
                                      }
                                      placeholder="Dernière Etablissement"
                                      name="dernier_etablissement"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card">
                            <div className="card-header">
                              Informations Parent
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail3">
                                      Nom du père
                                    </label>
                                    <input
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="exampleInputEmail3"
                                      placeholder="Email"
                                      value={records?.nom_pere || ""}
                                      name="nom_pere"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail3">
                                      Nom de la mère
                                    </label>
                                    <input
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="exampleInputEmail3"
                                      placeholder="Email"
                                      value={records?.nom_mere || ""}
                                      name="nom_mere"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="Téléphone">
                                      Personne Responsable
                                    </label>
                                    <input
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="Téléphone"
                                      placeholder="Parent"
                                      value={records?.parent || ""}
                                      name="parent"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label htmlFor="Téléphone">Adresse</label>
                                    <input
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="Téléphone"
                                      placeholder="Téléphone"
                                      value={records?.adresse || ""}
                                      name="adresse"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="Téléphone">Téléphone</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        +509
                                      </span>
                                    </div>
                                    <input
                                      readOnly
                                      type="number"
                                      className="form-control"
                                      id="Téléphone"
                                      placeholder="Téléphone"
                                      value={records?.telephone || ""}
                                      name="telephone"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>

                    <div className="card-footer text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default StudentArchivedprofile;
