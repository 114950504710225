import  { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Footer from '../../Includes/Footer';
import Sidebar from '../../Includes/Sidebar';
import { FormUsers } from '../Data/Form';
import Data from '../Data/Data';


function User() {
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (event) => {
     event.preventDefault(); // Prevent the form from submitting
     setShowModal(true); // Show the modal
   };
 
  const closeModal = () => {
     setShowModal(false); // Hide the modal
  };


  return (
    <>
    <Sidebar />
    <div className="content-wrapper wow fadeIn " data-wow-delay="0.05s ">
        <section className="content-header mt-3">
          <div className="container-fluid">
            <div className="row mb-2 mt-3">
              <div className="col-sm-6 d-flex align-items-center">
                <div className="bg-blue rounded-lg p-2 text-center">
                  <i className='fas fa-users-cog'></i>
                </div>
                <div className="ml-2"><h3 className='display fw-12'>Gestion des Utilisateurs</h3></div>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/dashboard">Tableau de bord</Link></li>
                  <li className="breadcrumb-item active">Utilisateurs</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid">
                    <div className="card">
                        <div className="col-12">
                            <div className="card-header">
                                <h2 className="card-title display">Liste des Utilisateurs</h2>
                            </div>
                            <div className="card col-3 m-2 ml-auto border-0 p-0 order-2 order-md-1">
                                <button className="btn btn-primary p-1 fw-bold" onClick={handleSubmit}>
                                    <i className="fas fa-user-plus"> </i>
                                    <span> </span>Nouveau Utilisateur
                                </button>
                            </div>
                               <Data />
                                    
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal className='mt-0' show={showModal} onHide={closeModal} size="lg" top="true">
                <Modal.Header closeButton>
                    <Modal.Title>Formulaire d'enregistrement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        <div className="card-body">
                            <FormUsers />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-danger" onClick={closeModal}><i className='fas fa-trash'></i> Fermer</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default User;