import { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../Includes/Footer";
import Sidebar from "../../Includes/Sidebar";
import AuthContext from "../../context/AuthContext";
import { TeacherContext } from "../../context/TeacherContext";
import Loader from "../../Includes/loader/loader";

function TeacherEdit() {
  const { getTeacherById, updateTeachers } = useContext(TeacherContext);
  const { user } = useContext(AuthContext);
  const [records, setRecords] = useState();
  const location = useLocation();
  const { teacher_id } = location.state || {};
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageView, setProfileImageView] = useState(null);
  const [loading1, setLoading1] = useState(true);
  const [loading, setLoading] = useState(false); // État de chargement

  useEffect(() => {
    const userId = teacher_id;
    getTeacherById(userId).then((data) => {
      setRecords(data);
      setLoading1(false);
    });
  }, [getTeacherById, teacher_id]);

  const handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setProfileImage(file); // Mettre à jour profileImage avec le fichier
      const imageUrl = URL.createObjectURL(file);
      setProfileImageView(imageUrl);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("created_by", user.user_id);
    if (profileImage) {
      formData.append("photo", profileImage);
    }
    setLoading(true);
    try {
      updateTeachers(records.id, formData);
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Remettre loading à false après la fin du processus
    }
  };

  return (
    <div className="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2 mt-3">
                <div className="col-sm-6 d-flex align-items-center">
                  <div className="bg-blue rounded-lg p-2 text-center">
                    <i className="fas fa-user"></i>
                  </div>
                  <div className="ml-2">
                    <h3 className="display fw-12">Profil Professeur</h3>
                  </div>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      <Link to="/teacher">Professeurs</Link>
                    </li>
                    <li className="breadcrumb-item active">Profil</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="clearfix hidden-md-up"></div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">
                        Modifier les information du Professeur
                      </h3>

                      <div className="card-tools">
                        <Link
                          to="/teacher"
                          className="btn btn-primary btn-sm rounded-lg"
                        >
                          <i className="fa fa-arrow-left"></i>
                        </Link>
                      </div>
                    </div>

                    <div className="card-body p-3 m-0">
                      {loading1 ? (
                        <Loader />
                      ) : (
                        <form
                          onSubmit={handleSubmit}
                          className="forms-sample"
                          encType="multipart/form-data"
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <label htmlFor="profil">Photo</label>
                              <div className="form-group">
                                <img
                                  src={
                                    profileImageView ||
                                    (records && records.photo) ||
                                    ""
                                  }
                                  id="profil"
                                  alt="profil"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                    borderRadius: "50px",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="name">Nom</label>
                                <input
                                  type="text"
                                  value={records?.nom || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      nom: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  id="name"
                                  placeholder="Nom de l'élève"
                                  name="nom"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="prenom">Prénom</label>
                                <input
                                  type="text"
                                  value={records?.prenom || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      prenom: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  id="prenom"
                                  placeholder="Prenom de l'élève"
                                  name="prenom"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail3">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  value={records?.email || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      email: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  id="exampleInputEmail3"
                                  placeholder="Email"
                                  name="email"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="exampleSelectGender">
                                  Sexe
                                </label>
                                <select
                                  className="form-control"
                                  id="exampleSelectGender"
                                  name="sexe"
                                  value={records?.sexe || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      sexe: e.target.value,
                                    })
                                  }
                                >
                                  <option selected readonly></option>
                                  <option value={"M"}>Masculin</option>
                                  <option value={"F"}>Feminin</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="date">Date naissance</label>
                                <input
                                  type="date"
                                  value={records?.date_naissance || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      date_naissance: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  id="date"
                                  placeholder="Date Naissance"
                                  name="date_naissance"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="Adresse">Adresse</label>
                                <input
                                  type="text"
                                  value={records?.adresse || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      adresse: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  id="Adresse"
                                  placeholder="Adresse"
                                  name="adresse"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="Téléphone">Téléphone</label>
                                <input
                                  type="text"
                                  value={records?.telephone || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      telephone: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  id="Téléphone"
                                  placeholder="Téléphone"
                                  name="telephone"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="Téléphone">CIN | NIF</label>
                                <input
                                  type="text"
                                  value={records?.nif_cin || ""}
                                  onChange={(e) =>
                                    setRecords({
                                      ...records,
                                      nif_cin: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  id="Téléphone"
                                  name="nif_cin"
                                />
                              </div>
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="exampleInputFile">
                                Fichier importer
                              </label>
                              <div className="input-group">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="exampleInputFile"
                                    accept="image/*"
                                    name="image"
                                    onChange={handleImageChange}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="exampleInputFile"
                                  >
                                    {profileImage
                                      ? "Image sélectionnée"
                                      : "Sélectionner une image"}
                                  </label>
                                </div>
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    Importer
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mt-4 text-right">
                              <div className="form-group pt-1">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-sm"
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <>
                                      <i className="fas fa-spinner fa-spin"></i>{" "}
                                      Enregistrement en cours...
                                    </>
                                  ) : (
                                    <>
                                      <i className="fas fa-save"></i>{" "}
                                      Enregistrer
                                    </>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>

                    <div className="card-footer text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default TeacherEdit;
