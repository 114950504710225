/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Includes/Sidebar";
import { Link } from "react-router-dom";
import { CourseContext } from "../../context/CourseContext";
import Loader from "../../Includes/loader/loader";
import DataTable from "react-data-table-component";
import "jspdf-autotable";
import jsPDF from "jspdf";
import axios from "axios";
import { useLocation } from "react-router-dom";

// const baseUrl = 'http://127.0.0.1:8000/api/students/';

function DetailsNoteArchived() {
  const { StudentsArchivedTotalNote } = useContext(CourseContext);
  const [studentData, setStudentData] = useState(null);
  const [studentData1, setStudentData1] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { year, matricule } = location.state || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await StudentsArchivedTotalNote(year, matricule);
        console.log("Data received from backend:", data);
        setStudentData(data[0]); // Accédez au premier élément du tableau
        setStudentData1(data); // Accédez au premier élément du tableau
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [StudentsArchivedTotalNote, year, matricule]);

  const generateStudentPDF = async (studentData, date) => {
    console.log(year);
    console.log(studentData);

    const doc = new jsPDF("p", "pt", "letter"); // 'letter' sets the size to 8.5 x 11 inches

    // Add background image
    const img = new Image();
    img.src = "/img/filig.png";

    // Wait for the image to load
    await new Promise((resolve) => {
      img.onload = resolve;
    });

    // Draw the background image using html2canvas
    const canvas = document.createElement("canvas");
    canvas.width = 612; // Letter width in points
    canvas.height = 792; // Letter height in points
    const context = canvas.getContext("2d");
    context.drawImage(img, 0, 0, canvas.width, canvas.height);

    const imgData = canvas.toDataURL("image/png");

    // Add the background image to the PDF
    doc.addImage(imgData, "PNG", 0, 0, 612, 792);

    // Header
    doc.addImage("/img/header.png", "PNG", 0, 0, 612, 130);

    // Student Info
    doc.setFontSize(12);
    doc.setFont("times", "bold");
    doc.text(`Nom : ${studentData.nom}`, 40, 150);
    doc.text(`Prenom : ${studentData.prenom}`, 40, 170);
    doc.text(`Année Académique : ${date}`, 200, 195);
    doc.text(`Matricule : ${studentData.matricule}`, 350, 150);
    doc.text(
      `Classe : ${studentData.niveau} ${studentData.classe_nom}`,
      350,
      170
    );

    // Notes Table
    const tableColumn = [
      "Matières",
      "Max",
      "Controle 1",
      "Controle 2",
      "Controle 3",
    ];
    const tableRows = studentData.notes.map((note) => [
      note.course_name,
      note.ponderation,
      note.notes["1er trimestre"],
      note.notes["2e trimestre"],
      note.notes["3e trimestre"],
    ]);

    // Adding a smaller, gray-filled line before the totals and averages
    const customRow = [
      {
        content: "",
        colSpan: 5,
        styles: { fillColor: [200, 200, 200], fontSize: 5, lineWidth: 0 },
      },
    ];
    tableRows.push(customRow);

    // Adding totals and averages to the table
    tableRows.push([
      {
        content: "Total",
        styles: {
          halign: "right",
          fontStyle: "bold",
          cellPadding: { right: 5 },
        },
      },
      {
        content: studentData.total_pond_1er_trimestre,
        styles: { fontStyle: "bold" },
      },
      {
        content: studentData.total_notes_1er_trimestre,
        styles: { fontStyle: "bold" },
      },
      {
        content: studentData.total_notes_2e_trimestre,
        styles: { fontStyle: "bold" },
      },
      {
        content: studentData.total_notes_3e_trimestre,
        styles: { fontStyle: "bold" },
      },
    ]);

    tableRows.push([
      {
        content: "Moyenne",
        styles: {
          halign: "right",
          fontStyle: "bold",
          cellPadding: { right: 5 },
        },
      },
      { content: "10", styles: { fontStyle: "bold" } },
      {
        content: studentData.average_1er_trimestre,
        styles: { fontStyle: "bold" },
      },
      {
        content: studentData.average_2e_trimestre,
        styles: { fontStyle: "bold" },
      },
      {
        content: studentData.average_3e_trimestre,
        styles: { fontStyle: "bold" },
      },
    ]);

    doc.autoTable({
      startY: 200,
      head: [tableColumn],
      body: tableRows,
      theme: "grid",
      styles: {
        font: "times",
        fontSize: 12, // Reduce the font size
        cellPadding: 2, // Adjust cell padding if necessary
      },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [1, 80, 133],
        textColor: [255, 255, 255],
      },
      bodyStyles: {
        valign: "middle",
        textColor: [22, 22, 22],
        halign: "center", // Center align text in all body cells
      },
      columnStyles: {
        0: { halign: "left", fontStyle: "bold" }, // Align the first column to the left
        1: { halign: "center" }, // Align the second column to the center
        2: { halign: "center" }, // Align the third column to the center
        3: { halign: "center" }, // Align the fourth column to the center
        4: { halign: "center" }, // Align the fifth column to the center
      },
      margin: { right: 220 },
    });

    // const finalY = doc.lastAutoTable.finalY;

    // Interval Table
    const intervalTableColumn = ["Intervalle", "Niveau", "Appreciations"];
    const intervalTableRows = [
      ["100-90", "5", "T-D"],
      ["89-70", "4", "D"],
      ["69-70", "3", "P."],
      ["54-30", "2", "P-D"],
      ["Moins de 30", "1", "TPD"],
    ];

    doc.autoTable({
      startY: 200,
      head: [intervalTableColumn],
      body: intervalTableRows,
      theme: "grid",
      styles: {
        font: "times",
        fontSize: 10, // Reduce the font size
        cellPadding: 1, // Adjust cell padding if necessary
      },
      headStyles: {
        fillColor: [1, 80, 133],
        fontStyle: "bold",
        textColor: [255, 255, 255],
      },
      bodyStyles: { textColor: [22, 22, 22] }, // Center align text
      margin: { left: 395 },
    });

    // const finalY2 = Math.max(doc.lastAutoTable.finalY, finalY);

    // General Average Table
    const MoyenneGTableColumn = ["Moyenne Generale", ""];
    const MoyenneGTableRows = [
      ["1er Controle", studentData.average_1er_trimestre],
      ["2e Controle", studentData.average_2e_trimestre],
      ["3e Controle", studentData.average_3e_trimestre],
      ["Moyenne de l'année", studentData.moyenne_generale],
      ["Décision de l'année", studentData.decision_annee],
    ];

    doc.autoTable({
      startY: 287,
      head: [MoyenneGTableColumn],
      body: MoyenneGTableRows,
      theme: "grid",
      styles: {
        font: "times",
        fontSize: 10, // Reduce the font size
        cellPadding: 1, // Adjust cell padding if necessary
      },
      headStyles: {
        halign: "center",
        valign: "middle",
        fontStyle: "bold",
        fillColor: [1, 80, 133],
        textColor: [255, 255, 255],
      },
      bodyStyles: { textColor: [22, 22, 22] }, // Center align text
      margin: { left: 395 },
    });

    // const finalY4 = Math.max(doc.lastAutoTable.finalY, finalY);

    // Decision and Notes
    const notesTableColumn1 = ["Valeurs des Notes"];

    doc.autoTable({
      startY: 373,
      head: [notesTableColumn1],
      theme: "grid",
      styles: {
        font: "times",
        fontSize: 10, // Reduce the font size
        cellPadding: 1, // Adjust cell padding if necessary
      },
      headStyles: {
        halign: "center",
        valign: "middle",
        fontStyle: "bold",
        fillColor: [1, 80, 133],
        textColor: [255, 255, 255],
      },
      margin: { left: 395 },
    });

    const notesTableRows = [
      ["T-D", "Très Developper", "D", "Developper"],
      ["P.", "Passable", "P-D", "Peu Developper"],
      ["TPD", "Très Peu Developper", "", ""],
    ];

    doc.autoTable({
      startY: 386,
      body: notesTableRows,
      theme: "grid",
      styles: {
        font: "times",
        fontSize: 10, // Reduce the font size
        cellPadding: 1, // Adjust cell padding if necessary
      },
      bodyStyles: { textColor: [22, 22, 22] }, // Center align text
      margin: { left: 395 },
    });

    // const finalY3 = doc.lastAutoTable.finalY;

    // Adding the header "Décision de l'année"
    doc.autoTable({
      startY: 453, // Positioning the table below the last section
      head: [["Décision de l'année"]],
      body: [
        ["Promu (e)", ""],
        ["Promu (e) Ailleurs", ""],
        ["Doublé Ailleurs", ""],
      ],
      theme: "grid",
      styles: {
        font: "times",
        fontSize: 10, // Adjust font size
        cellPadding: 1, // Adjust cell padding
      },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [1, 80, 133],
        textColor: [255, 255, 255],
      },
      bodyStyles: {
        textColor: [22, 22, 22],
      },
      margin: { left: 395 },
    });

    // Drawing the checkboxes
    const checkBoxPositions = [
      { x: 540, y: 468 },
      { x: 540, y: 481 },
      { x: 540, y: 495 },
    ];

    checkBoxPositions.forEach((pos) => {
      doc.rect(pos.x, pos.y, 10, 10); // Draw checkbox
    });

    // Adding signature lines and labels
    const yPos = 580; // Position for the signature lines
    const yPos1 = 665; // Position for the signature lines
    doc.setLineWidth(0.5);
    doc.line(400, yPos, 560, yPos); // Line for the "Signature du directeur"
    doc.line(400, yPos1, 560, yPos1); // Line for the "Signature du Censeur"

    doc.setFontSize(10);
    doc.setFont("times", "normal");
    doc.text("Signature du Directeur", 438, yPos + 15); // Label for the director's signature
    doc.text("Signature du Censeur", 440, yPos1 + 15); // Label for the censor's signature

    // const pdfBlob = doc.output('blob');
    // const formData = new FormData();
    // formData.append('pdf', pdfBlob, `${studentData.nom}_${studentData.prenom}_badge.pdf`);
    // formData.append('classe_nom', studentData.classe_nom);

    // try {
    //   const response = await axios.post(`${baseUrl}save-bulletins-pdf/`, formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     }
    //   });
    //   if (response.status === 200) {
    //     // Ouvrir le PDF dans une nouvelle fenêtre sans le télécharger à nouveau
    //     const url = URL.createObjectURL(pdfBlob);
    //     window.open(url, '_blank');

    //     // Cleanup
    //     setTimeout(() => {
    //       URL.revokeObjectURL(url);
    //     }, 100);
    //   }
    //   console.log(response.data);
    // } catch (error) {
    //   console.error('Erreur lors de l\'envoi du PDF:', error);
    // }
    // Save PDF
    doc.save(`${studentData.nom}_${studentData.prenom}_bulletin.pdf`);
  };

  if (loading) {
    return <Loader />;
  }

  if (!studentData) {
    return <div>No data found</div>;
  }

  console.log("studentData:", studentData1);

  const notes = studentData.notes || [];
  const subjects = notes.map((note) => note.course_name);

  const data = subjects.map((subject) => {
    const subjectNotes =
      notes.find((note) => note.course_name === subject) || {};
    const getNote = (trimestre) =>
      subjectNotes.notes && subjectNotes.notes[trimestre] !== null
        ? subjectNotes.notes[trimestre]
        : "N/A";
    const isBelowHalfPonderation = (trimestre) => {
      const note =
        subjectNotes.notes && subjectNotes.notes[trimestre] !== null
          ? subjectNotes.notes[trimestre]
          : 0;
      const ponderation = subjectNotes.ponderation || 0;
      return ponderation > 0 && note < ponderation / 2;
    };

    return {
      subject,
      "1er trimestre": {
        note: getNote("1er trimestre"),
        isBelowHalf: isBelowHalfPonderation("1er trimestre"),
      },
      "2e trimestre": {
        note: getNote("2e trimestre"),
        isBelowHalf: isBelowHalfPonderation("2e trimestre"),
      },
      "3e trimestre": {
        note: getNote("3e trimestre"),
        isBelowHalf: isBelowHalfPonderation("3e trimestre"),
      },
    };
  });

  const totalRow = {
    subject: "Total",
    "1er trimestre": {
      note: studentData.total_notes_1er_trimestre,
      isBelowHalf: false,
    },
    "2e trimestre": {
      note: studentData.total_notes_2e_trimestre || "N/A",
      isBelowHalf: false,
    },
    "3e trimestre": {
      note: studentData.total_notes_3e_trimestre || "N/A",
      isBelowHalf: false,
    },
  };

  data.push(totalRow);

  const columnsCourses = [
    {
      name: "Matières",
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: "1er trimestre",
      selector: (row) => row["1er trimestre"].note,
      sortable: true,
      cell: (row) => (
        <div
          className={
            row["1er trimestre"].isBelowHalf ? "text-danger fw-bold" : "fw-bold"
          }
        >
          {row["1er trimestre"].note}
        </div>
      ),
    },
    {
      name: "2e trimestre",
      selector: (row) => row["2e trimestre"].note,
      sortable: true,
      cell: (row) => (
        <div
          className={
            row["2e trimestre"].isBelowHalf ? "text-danger fw-bold" : "fw-bold"
          }
        >
          {row["2e trimestre"].note}
        </div>
      ),
    },
    {
      name: "3e trimestre",
      selector: (row) => row["3e trimestre"].note,
      sortable: true,
      cell: (row) => (
        <div
          className={
            row["3e trimestre"].isBelowHalf ? "text-danger fw-bold" : "fw-bold"
          }
        >
          {row["3e trimestre"].note}
        </div>
      ),
    },
  ];

  // const generalAverage = studentData.moyenne_generale;
  // const isBelowAverage = generalAverage && parseFloat(generalAverage.replace(',', '.')) < 5;

  return (
    <div className="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2 mt-3">
                <div className="col-sm-6 d-flex align-items-center">
                  <div className="bg-blue rounded-lg p-2 text-center">
                    <i className="fas fa-users-cog"></i>
                  </div>
                  <div className="ml-2">
                    <h3 className="display fw-12">Gestion des Élèves</h3>
                  </div>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-end ">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/courses">Gestion de Matières</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/notes">Notes</Link>
                    </li>
                    <li className="breadcrumb-item active">Details</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <section className="content wow fadeIn" data-wow-delay="0.05s">
            <div className="container-fluid">
              <div className="row pt-2">
                <div className="col-md-3">
                  <div className="card card-primary card-outline">
                    <div className="card-header p-1 text-center fw-bold bg-primary">
                      Profil de l'élève
                    </div>
                    <div className="card-body box-profile">
                      <div className="text-center">
                        <p className="m-0">Nom Complet</p>
                      </div>
                      <h3 className="profile-username text-center">
                        {studentData.nom + " " + studentData.prenom || ""}
                      </h3>
                      <p className="text-center mb-0 p-0">Matricule</p>
                      <p className="text-muted text-center m-0 p-0">
                        {studentData.matricule || ""}
                      </p>
                      <p className="text-center mb-0">Classe</p>
                      <p className="text-muted text-center">
                        {studentData.niveau + " " + studentData.classe_nom ||
                          ""}
                      </p>
                      <ul className="list-group list-group-unbordered mb-3">
                        Moyenne
                        <li className="list-group-item">
                          <b>1er Trimestre</b>{" "}
                          <a className="float-right">
                            {studentData.average_1er_trimestre || ""}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>2e Trimestre</b>{" "}
                          <a className="float-right">
                            {studentData.average_2e_trimestre || ""}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>3e Trimestre</b>{" "}
                          <a className="float-right">
                            {studentData.average_3e_trimestre || ""}
                          </a>
                        </li>
                      </ul>
                      <a className="btn btn-primary btn-block">
                        <b>Moyenne général:</b>{" "}
                        <a className="float-right">
                          {studentData.moyenne_generale || ""}
                        </a>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="card">
                    <div className="card-header p-1 text-center fw-bold bg-primary mb-0">
                      <div className="row">
                        <div className="col-md-6 text-start">
                          Palmares # : {studentData.code || ""}
                        </div>
                        <div className="col-md-6 text-end">
                          Année Académique : {year || ""}
                        </div>
                      </div>
                    </div>
                    <div className="card-body table-responsive p-0">
                      <DataTable
                        columns={columnsCourses}
                        data={data}
                        pagination
                        highlightOnHover
                        pointerOnHover
                      />
                      <button
                        className="btn btn-primary mt-3"
                        onClick={() => generateStudentPDF(studentData, year)}
                      >
                        Générer le Bulletin
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default DetailsNoteArchived;
