import { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../Includes/Footer";
import Sidebar from "../../Includes/Sidebar";
import AuthContext from "../../context/AuthContext";
import { PaiementContext } from "../../context/PaiementContext";
import Loader from "../../Includes/loader/loader";

function PaiementInscDetails() {
  const { getPaiementInscById,updatePaiements } = useContext(PaiementContext);
  const {user} = useContext(AuthContext);
  const [records, setRecords] = useState();
  const location = useLocation();
  const { paie_id } = location.state || {};
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      const fetchClasses = async () => {
          try {
            getPaiementInscById(paie_id).then((data) => {
              setRecords(data); // Mettez à jour records avec les données récupérées
              setLoading(false); 
            });
          } catch (error) {
              console.error("Error fetching classes:", error);
          }
      };
      fetchClasses();
  }, [paie_id,getPaiementInscById]);



  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("created_by", user.user_id);
    updatePaiements(records.id, formData);
  };

  return (

    <div className="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2 mt-3">
                <div className="col-sm-6 d-flex align-items-center">
                  <div className="bg-blue rounded-lg p-2 text-center">
                    <i className="fas fa-user"></i>
                  </div>
                  <div className="ml-2">
                    <h3 className="display fw-12">Detail Paiement Inscription</h3>
                  </div>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      <Link to="/paiements-eleves">Gestion Paiement</Link>
                    </li>
                    <li className="breadcrumb-item active">Detail</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="clearfix hidden-md-up"></div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Informations sur le paiement</h3>

                      <div className="card-tools">
                        <Link
                          className="btn btn-success btn-sm"
                          to="/paiements-eleves"
                        >
                          <i className="fas fa-list"></i> Liste des paiements
                        </Link>
                      </div>
                    </div>

                    <div className="card-body p-2 m-0">
                      {loading ? (
                        <Loader />
                      ) : (

                        <form
                        onSubmit={handleSubmit}
                        className="forms-sample"
                        encType="multipart/form-data"
                      >
                                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Matricule</label>
                        <input
                        readOnly
                                type="text" value={records?.student_matricule || ""}
                                onChange={(e) =>
                                  setRecords({ ...records, student_matricule: e.target.value })
                                } className="form-control" id="name" 
                        placeholder="Matière"required name='nom'/>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Nom</label>
                        <input
                        readOnly
                                type="text" value={records?.student_name || ""}
                                onChange={(e) =>
                                  setRecords({ ...records, student_name: e.target.value })
                                } className="form-control" id="name" 
                        placeholder="Matière"required name='nom'/>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Montant Payé</label>
                        <input
                        readOnly
                                type="text" value={records?.montant || ""}
                                onChange={(e) =>
                                  setRecords({ ...records, montant: e.target.value })
                                } className="form-control" id="name" 
                        placeholder="Matière"required name='description'/>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Date paiement</label>
                        <input
                        readOnly
                                type="text" value={records?.date || ""}
                                onChange={(e) =>
                                  setRecords({ ...records, date: e.target.value })
                                } className="form-control" id="name" 
                        placeholder="Matière"required name='description'/>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Effectuer par</label>
                        <input
                        readOnly
                                type="text" value={records?.created_by || ""}
                                onChange={(e) =>
                                  setRecords({ ...records, created_by: e.target.value })
                                } className="form-control" id="name" 
                        placeholder="Matière"required name='description'/>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Année Academique</label>
                        <input
                        readOnly
                                type="text" value={records?.annee_academique || ""}
                                onChange={(e) =>
                                  setRecords({ ...records, annee_academique: e.target.value })
                                } className="form-control" id="name" 
                        placeholder="Matière"required name='description'/>
                      </div>
                    </div>
                {/* <div className="col-md-12 mt-4 text-center">
                  <div className="form-group pt-1">
                    <Button type="submit" variant="btn btn-primary  "><i className='fas fa-save'></i> Enregistrer</Button>
                  </div>
                  </div> */}
                                   </div>
                      
                      </form>
                      )}
                    </div>

                    <div className="card-footer text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </div>
    // <>
    //   <Sidebar />
    //   <div className="content-wrapper wow fadeIn " data-wow-delay="0.05s ">
    // <section className="content-header mt-3">
    //       <div className="container-fluid">
    //         <div className="row mb-2 mt-3">
    //           <div className="col-sm-6 d-flex align-items-center">
    //             <div className="bg-blue rounded-lg p-2 text-center">
    //               <i className="fas fa-users-cog"></i>
    //             </div>
    //             <div className="ml-2">
    //               <h3 className="display fw-12">Detail du cours</h3>
    //             </div>
    //           </div>
    //           <div className="col-sm-6">
    //             <ol className="breadcrumb float-sm-right">
    //               <li className="breadcrumb-item">
    //                 <Link to="/dashboard">Tableau de bord</Link>
    //               </li>
    //               <li className="breadcrumb-item active">
    //                 <Link to="/Paiements">Cours</Link>
    //               </li>
    //               <li className="breadcrumb-item active">Detail</li>
    //             </ol>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //     <div className="container-fluid">
    //       <div className="card p-3">
    //         <div className="col-md-12 text-end">
    //           <Link to="/Paiements" className="btn btn-primary rounded-lg">
    //             <i className="fa fa-arrow-left"></i>
    //           </Link>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <Footer />
    // </>
  );
}

export default PaiementInscDetails;
